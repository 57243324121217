//分配角色，这里的角色可以用来创建账户，
import { UserGrantType } from "./UserType";
import { Modal } from '../../components'
import { useEffect, useState } from "react";
import {Alert, Tag,message, Select
} from 'antd';
import { useTranslation } from "../../hooks/useTranslation";
import { useSelector } from "../../hooks/useSelector";
import { useRequest } from "../../hooks/useRequest";
import { api } from "../../config/api";

export const GrantUseRole: React.FC<UserGrantType> = ({id, onClose}) => {
    // const [parentId1, setParentId1] = useState(parentId || 0);
    const [enable, setEnable] = useState(true);
    const t = useTranslation();
    const setting = useSelector(state => state.setting);
    const {json} = useRequest();

    const [roles, setRoles] = useState<any[]>([]);
    const [toggleRoles, setToggleRoles] = useState<any[]>([]);

    const getRoles = async (id, type) => {
        let result = await json.get(api.role.roles+"?id="+id);
        if(result.status){
            if(type==1){
                setRoles(result.data);
            }else{
                setToggleRoles(result.data);
            }
        } 
    }

    useEffect(()=>{
        getRoles(0, 1);
        getRoles(id, 2);
    }, 
    // eslint-disable-next-line
    []);
    

    const handleCancel = () => {
        setEnable(false);
        if(onClose){
            setTimeout(() => {
                onClose(false);
            }, 500);
        }
    }

    const removeUser = (e, key) => {
        e.preventDefault();
        setToggleRoles(toggleRoles.filter(u => u.id != key));
    };

    const addUser = (key) => {
        let idx = toggleRoles.findIndex(u => u.id == key);
        // console.log(key, idx);
        if(idx==-1){
            let temp = roles.find(u => u.id == key);
            if(temp){
                setToggleRoles(us => [...us, temp]);
            }
        }
    }

    const submit = () => {
        let param = {
            id: id,
            roles: toggleRoles.map(u => u.id).join(",")
        }
        
        let update = async() => {
            let result = await json.post(api.user.update, param);
            message.success(t(result.msg));
            if(result.status){
                handleCancel();
            }
        }

        update();
    }

    const formHtml = (
        <div className="edit-form-wrap">
            <Alert message={t("分配后，这里的角色可以用来创建账户，")} type="warning" showIcon closable />
            <div className="owner-users">
                {toggleRoles.map(u => {return (
                        <Tag key={u.id} closable onClose={(e) => removeUser(e, u.id)}>{"("+u.id+")"+u.label}</Tag>
                    )})
                }
            </div>
            <Select allowClear size={setting.size} style={{minWidth: "400px"}} onChange={addUser}>
                {roles.map(i => (
                    <Select.Option value={i.id} key={i.id} >
                        {i.id+"-"+i.label}
                    </Select.Option>
                ))}
            </Select>
        </div>
    );

    let title =t('分配角色')
        
    return (
        <Modal visible={enable}
            title={title}
            width={500}
            onOk={()=>{submit()}}
            onCancel={()=>handleCancel()}
            afterClose={()=>handleCancel()}
        >
            {formHtml}
        </Modal>
    )
}