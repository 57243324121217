import React, { useState } from "react";
import {Form, message, Button, Input, Space} from 'antd';
import { useDispatch } from "react-redux";
import { signIn } from "../../redux/global";
import {api} from '../../config/api';
import { useTranslation } from "../../hooks/useTranslation";


export const LoginForm : React.FC = () => {
    const dispatch = useDispatch();
    const t = useTranslation();

    const [codeImg, setCodeImg] = useState<string>(api.common.code);

    const onFinish = (values: any) => {
        dispatch(signIn({
          username: values.username,
          password: values.password, 
          code: values.code,
        }))
    };

    const changeCode = ()=>{
        setCodeImg(api.common.code+"?_t="+new Date().getTime());
    }

    const onFinishFailed = (errorInfo: any) => {
        message.warning({
            content: t('请确认数据正确后在提交'),
        });
    };

    const [form] = Form.useForm();

    return ( 
        <>
        <Form form={form}  labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}
            name="basic"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            >
            <Form.Item
                label={t('用户名')}
                name="username"
                rules={[{ required: true, message:  t("请输入用户名") as string}]}
            >
                <Input size="large"/>
            </Form.Item>

            <Form.Item
                label="密码"
                name="password"
                rules={[{ required: true, message: t("请输入密码") as string }]}
            >
                <Input.Password size="large"/>
            </Form.Item>

            <Form.Item
                label="验证码"
                name="code"
                rules={[{ required: true, message: t("请输入验证码") as string }]}
            >
                <Space>
                    <Input size="large"/>
                    <img style={{height: "30px"}} alt="code" onClick={()=>{changeCode()}} src={codeImg}></img>
                </Space>
            </Form.Item>
        </Form>
        <Button type="primary" size="large" onClick={()=>{form.submit()}}  block>{t('登录')}</Button>
        </>
    )
}