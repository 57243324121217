
import {Self, User, Role,
    Auth, Menu,
    History, Setting, Attachment,
    Resource, Express, ExpressBack, 
    Code, Address,Upload711,UploadQj,UploadOK,UploadLrf,UploadUpper,UploadHm,CodeHist,
    Doc
} from '../pages'

export default  [
    {
        path: '/admin',
        component: Self,
        exact: true, 
        name: '个人信息',
    },
    {
        path: '/admin/userinfo/:id',
        component: Self,
        exact: true, 
        name: '个人信息',
        key: '/admin/userinfo'
    },
    {
        path: '/admin/user',
        component: User, 
        name: '用户管理'
    },
    {
        path: '/admin/role',
        component: Role, 
        name: '角色管理'
    },
    {
        path: '/admin/auth',
        component: Auth,
        name: '权限管理'
    },
    {
        path: '/admin/menu',
        component: Menu,
        name: '菜单管理'
    },
    {
        path: '/admin/setting',
        component: Setting,
        name: '系统设置'
    },
    {
        path: '/admin/history',
        component: History,
        name: '日志管理'
    },
    {
        path: '/admin/file',
        component: Attachment,
        name: '文件管理'
    },
    {
        path: '/admin/i18n',
        component: Resource,
        name: '语言管理'
    },
    {
        path: '/admin/exp',
        component:  Express,
        name: '物流管理'
    },
    {
        path: '/admin/code',
        component: Code,
        name: '单号管理'
    },
    {
        path: '/admin/code-hist',
        component: CodeHist,
        name: '单号分配记录'
    },
    {
        path: '/admin/exp-back',
        component: ExpressBack,
        name: '退件管理'
    },
    {
        path: '/admin/address',
        component: Address,
        name: '门市查询'
    },
    {
        path: '/admin/upload-711',
        component: Upload711,
        name: '711建单导入'
    },
    {
        path: '/admin/upload-qj',
        component: UploadQj,
        name: '全家建单导入'
    },
    {
        path: '/admin/upload-OK',
        component: UploadOK,
        name: 'OK建单导入'
    },
    {
        path: '/admin/upload-LRF',
        component: UploadLrf,
        name: '莱尔富建单导入'
    },
    {
        path: '/admin/upload-hm',
        component: UploadHm,
        name: '黑猫建单导入'
    },
    {
        path: '/admin/upload-upper',
        component: UploadUpper,
        name: '上架号导入'
    },
    {
        path: '/admin/doc',
        component: Doc,
        name: '系统文档'
    },
]