import {Input, Button, Space, Tooltip, } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import { ReactNode, useState } from 'react';
import { useTranslation } from './useTranslation';

export const useTableSearchFilter = () => {
    
    const t = useTranslation();

    const [searchObj, setSearchObj] = useState({
        searchText: '',
        searchedColumn: -1
    });

    let searchInput: any = null;
    const getColumnSearchProps = (dataIndex, renderCell: ((row, record, index) => ReactNode) | null, tip?: boolean) => {
        
        return {
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                    <Input
                        ref={node => {
                            searchInput = node;
                        }}
                        placeholder={`Search ${dataIndex}`}
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        style={{ marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            {t('搜索')}
                        </Button>
                        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                            {t('重置')}
                        </Button>
                        <Button
                            type="link"
                            size="small"
                            onClick={() => {
                                confirm({ closeDropdown: false });
                                setSearchObj({
                                    searchText: selectedKeys[0],
                                    searchedColumn: dataIndex,
                                });
                            }}
                        >
                            {t('确定')}
                        </Button>
                    </Space>
                </div>
            ),
            // @ts-ignore
            filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : 'inherit' }} />,
            onFilter: (value, record) =>
                record[dataIndex]
                    ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                    : '',
            onFilterDropdownVisibleChange: visible => {
                if (visible) {
                    setTimeout(() => searchInput.select(), 100);
                }
            },

            render: (text, record, row) => {
                // console.log(text, dataIndex, renderCell);
                if(renderCell) {
                    return renderCell(text, record, row)
                }else{
                    if(searchObj.searchedColumn === dataIndex){
                        return <Highlighter
                            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                            searchWords={[searchObj.searchText]}
                            autoEscape
                            textToHighlight={text ? text.toString() : ''}
                        />
                    }else{
                        return tip?(<Tooltip placement="topLeft" title={text}>
                                {text}
                            </Tooltip>
                        ): <>{text}</>
                    }
                }
            }
        }
    };
    
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchObj({
            searchText: selectedKeys[0], 
            searchedColumn: dataIndex,
        });
    };
    
    const handleReset = clearFilters => {
        clearFilters();
        setSearchObj({...searchObj, searchText: '' });
    };

    return getColumnSearchProps;
}