//门市地址查询

import './Address.less'
import { api } from "../../config/api";
import { Space, Row, Col,Menu,TablePaginationConfig,Modal} from 'antd';
import { useSelector,useTranslation, 
    TableType, useTable,useExport,CellFormatter
 } from '../../hooks';
import { useState } from 'react';
import {FileExcelOutlined } from '@ant-design/icons';
import { SearchItemProp,SearchForm, companies,AuthDropdown as Dropdown } from '../../components';

export const Address: React.FC = () => {
    const t = useTranslation();
    const setting = useSelector(state => state.setting);
    const layoutInfo = useSelector(state => state.layoutInfo);
    const exportKit = useExport();
    const [query, setQuery] = useState<any | null>();

    const tableProp: TableType = {
        rowKey: "id",
        pagenation: true,
        url: api.address.list,
        // scroll: {x: 840},
        columns: [
            {title: t("ID"), key: "id", width:120, sorter: true, type: 'radio', fixed: 'left'},
            {title: t("厂商"), width:120, key: "type", sorter: true, filter: 'select'},
            {title: t("门市编号"),  width:120, key: "code", sorter: true, filter: 'search'},
            {title: t("店名"),  width:150, key: "name", sorter: true, filter: 'search'},
            {title: t("店址"),  width:180, key: "address", sorter: true, filter: 'search', tip: true},
            {title: t("关店日期"),  width:150, key: "closeDate", sorter: true, filter: 'search'},
            {title: t("门市电话"),  width:150, key: "tel", sorter: true, filter: 'search'},
            {title: t("门市编号(旧)"),  width:150, key: "oldCode", sorter: true, filter: 'search'},
            {title: t("理货路线"),  width:150, key: "route", sorter: true, filter: 'search'},
            {title: t("理货路顺"),  width:150, key: "step", sorter: true, filter: 'search'},
            {title: t("区域"),  width:150, key: "area", sorter: true, filter: 'search'},
            {title: t("设备代码"),  width:150, key: "equipmentId", sorter: true, filter: 'search'},
            {title: t("最后更新时间"),  width:200, key: "updated", sorter: true, filter: 'search'},
        ] 
    }

    const table = useTable(tableProp);

    const tableHtml = table.TableNode();

    const onFinish = (values) => {
        setQuery(values);
        table.remoteSearch(values);
    }

    let titles =  [
        "ID", t("厂商"), t('门市编号'),  t("店名"),
        t('店址'),t('关店日期'),t('门市电话'),
        t('门市编号(旧)'),t('理货路线'),t('理货路顺'),
        t('区域'),t('设备代码'),t('最后更新时间'),
    ];

    const formats: Map<number, CellFormatter> = new Map();

    const exportCurrPage = () => {
        
        let pageInfo = table.pageInfo as TablePaginationConfig;
        exportKit.byAjax({
            url: api.address.export,
            query: {
                //@ts-ignore
                start: (pageInfo.current-1)*pageInfo.pageSize,
                length: pageInfo.pageSize,
                ...query
            },
            extra: (result) => result.data,
            dataType: {
                filename: t("门市导出")+new Date().getTime()+".xlsx",
                titles: titles,
                formats: formats
            }
        });
    }

    const exportAll = () => {

        if(query==null || Object.keys(query).length == 0){
            Modal.confirm({
                content: t("没有任何查询条件，可能导致系统卡死，是否继续操作"),
                okText: t('确定'),
                okType: 'danger',
                cancelText: t('取消'),
                onOk: ()=>{
                    doExportAll();
                }
            });
        }else if(table.pageInfo['total'] > 40000){
            Modal.confirm({
                content: t("数据量过大，可能导致浏览器卡死，是否继续操作"),
                okText: t('确定'),
                okType: 'danger',
                cancelText: t('取消'),
                onOk: ()=>{
                    doExportAll();
                }
            });
        }else{
            doExportAll();
        }
    }

    const doExportAll = () => {
        let pageInfo = table.pageInfo as TablePaginationConfig;
        exportKit.byAjax({
            url: api.address.export,
            query: {
                start:  0,
                //@ts-ignore
                length: pageInfo.total,
                ...query
            },
            extra: (result) => result.data,
            dataType: {
                filename: t("门市导出")+new Date().getTime()+".xlsx",
                titles: titles,
                formats: formats
            }
        });
    }

    const dropdownMenu = (
        <Menu>
            <Menu.Item key="1" onClick={exportCurrPage} icon={<FileExcelOutlined />}>
                {t("导出当前页")}
            </Menu.Item>
            <Menu.Item key="2" onClick={exportAll} icon={<FileExcelOutlined />}>
                {t("导出所有")}
            </Menu.Item>
        </Menu>
    );

    const searchItems: SearchItemProp[] = [
        {label: '厂商',  name: "company", type: {
            options: companies
        }},
        {label: '门市编号', name:'code', type: 'input'},
        {label: '门市名', name:'name', type: 'input'},
    ]

    return (
        <div>
            <SearchForm searchItems={searchItems} onSearch={onFinish}/>
            <div className={`content-wrap ${layoutInfo.isMin?'content-wrap-min': ''}`} style={{marginTop: '10px'}}>
                <Row className="ctrl-wrap">
                    <Col span={24} >
                        <Space size={setting.size} wrap>
                            <Dropdown permission="expBack:export" btnTxt={t('导出')} btnIcon={<FileExcelOutlined />} overlay={dropdownMenu}></Dropdown>
                        </Space>
                    </Col>
                </Row>
                {tableHtml}
            </div>
        </div>    
    )
}