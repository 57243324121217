import { Menu, MenuTheme, message } from 'antd';
import { useEffect } from "react"
import {useTranslation} from "../../hooks/useTranslation";
import {Icon} from "../index";
import {useHistory} from 'react-router-dom';
import { useRequest } from '../../hooks/useRequest';
import { activeMenuSlice, menusSlice} from '../../redux/global'
import {api} from '../../config/api'
import { useDispatch } from 'react-redux';
import { useSelector } from '../../hooks';

interface MenuType{
    menuNode?: JSX.Element,
    mode: 'horizontal' | 'vertical' | 'inline',
    theme: MenuTheme,
}

export const SideMenu: React.FC<MenuType> = ({menuNode, mode, theme}) => {
    
    //获取菜单
    const t = useTranslation();
    const menus = useSelector(state => state.menus);
    const activeMenu = useSelector(state => state.activeMenu);
    const {request} = useRequest();
    const dispath = useDispatch();

    useEffect(()=>{
        const fetch = async () => {
            let data = await request.get(api.menu.toggle+"?resultType=1");
            if(data.status) {
                dispath(menusSlice.actions.setMenus(data.data));
                // setMenus(data.data);
            }else{
                message.warning({content: t(data.msg)});
            }
        }
        fetch();
    }, 
    /* eslint-disable-next-line */ 
    []);

    const history = useHistory();

    let menuObjs = menus as any[];

    const openPage = (menu) =>{
        // console.log(menu);
        dispath(activeMenuSlice.actions.change(menu));
        history.push(menu.url);
    }

    return (
        <Menu mode={mode} theme={theme} defaultSelectedKeys={[activeMenu["id"]]}>
            {menuObjs.map((menu)=>(
                menu.children && menu.children.length>0?(
                    <Menu.SubMenu key={menu.id} title={t(menu.label)} icon={<Icon icon={menu.icon}></Icon>}>
                        {
                            menu.children.map(sub => (
                                <Menu.Item key={sub.id} onClick={()=>openPage(sub)} icon={<Icon icon={sub.icon} />}>
                                    {t(sub.label)}
                                </Menu.Item>
                            ))
                        }
                    </Menu.SubMenu>
                ):(
                    <Menu.Item key={menu.id} onClick={()=>openPage(menu)} icon={<Icon icon={menu.icon} />}>
                        {t(menu.label)}
                    </Menu.Item>
                )
            ))}
            {
                menuNode?menuNode:''
            }
        </Menu>
    )
}
