
import React,{useEffect}  from 'react';
import {Logo} from "../Logo";
import {Dropdown, Button, Menu} from 'antd'
import { useSelector } from "../../hooks/useSelector"
import { useDispatch } from "react-redux";
import { langSlice, LangType } from "../../redux/langSlice";
import i18n from "i18next";
import { useTranslation } from 'react-i18next';
import "./Header.less";
import { useHistory } from "react-router-dom";
import { HomeOutlined, SearchOutlined, FormOutlined,WindowsFilled 
} from '@ant-design/icons';
import {
    LoginOutlined, UserOutlined 
  } from '@ant-design/icons';

export const Header: React.FC = () => {
    const language = useSelector((state) => state.lang.language);
    const languageList = useSelector((state) => state.lang.languageList);
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const history = useHistory();
    const user = useSelector(state => state.user);
    // console.log(history);
    //处理语言显示的文字
    let langName = "";
    if(language){
        langName = language.name || "zh-cn";
    }else{
        langName = "zh-cn";
    }

    if(langName.indexOf("zh")>-1){
        langName = langName.indexOf("cn")>-1?"简":"繁";
    }else{
        langName = langName.substring(0,1).toUpperCase()+langName.substring(1,2).toLowerCase();
    }

    //处理语言切换
    const changeLang = (key: string) => {
        let lang = languageList.find(l => l.name == key) as LangType;
        // console.log("clicked changeLang");
        if(lang){
            dispatch(langSlice.actions.changeLang(lang))
        }
    }
    
     //切换语言时，刷新页面
    useEffect(() => {
        // console.log(language);
        if(language){
            i18n.changeLanguage(language.name);
        }
    }, [language]);
    
    //生成语言菜单
    const langMenu = (
        <Menu onClick={(val) => changeLang(val.key)}>
            {languageList.map(lang => {
                return <Menu.Item key={lang.name || ''}>{lang.label}</Menu.Item>
            })}
        </Menu>
    );

    return (
        <div className="h-header-wrap">
            <header className="h-header">
                <div className="h-left">
                    <div className="h-logo">
                        <Logo />
                    </div>
                    <h1>{t('昶禹國際物流')}</h1>
                    {/* <Menu mode="horizontal" id="x-menu" style={{width: 'calc(100% - 150px)'}} overflowedIndicator={<WindowsFilled />}>
                        <Menu.Item key="home" style={{marginBottom: '-2px'}} onClick={()=>{history.push("/")}} icon={<HomeOutlined />}>{t('首页')}</Menu.Item>
                        <Menu.Item key="refund" style={{marginBottom: '-2px'}} onClick={()=>{history.push("/refund")}} icon={<FormOutlined />}>{t('退货申请')}</Menu.Item>
                        <Menu.Item key="exchange" style={{marginBottom: '-2px'}} onClick={()=>{history.push("/exchange")}} icon={<FormOutlined />}>{t('换货申请')}</Menu.Item>
                        <Menu.Item key="search" style={{marginBottom: '-2px'}} onClick={()=>{history.push("/refund/search")}} icon={<SearchOutlined />}>{t('查询申请')}</Menu.Item>
                    </Menu> */}
                </div>
                <div className="h-right">
                    {user.id?
                        (
                            <Button type="dashed" danger size='small'  icon={<UserOutlined />}
                                onClick={() => history.push("/admin")}
                            >{user.fullname}</Button>
                        )
                        : history.location.pathname !== '/login'?(
                            <Button type="primary" size='small'  icon={<LoginOutlined />}
                                onClick={() => history.push("/login")}
                            >{t('登录')}</Button>
                        
                       ):''
                    }
                    <Dropdown overlay={langMenu}>
                        <Button type="link" >
                            <span className="h-lang-txt">{langName}</span>
                        </Button>
                    </Dropdown>
                </div>
            </header>

        </div>
    )
}
