import { UserType, UserEditType, Disables, UserTypes } from "./UserType";
import { Modal } from '../../components'
import { useEffect, useState } from "react";
import {Form, Input,message, Row, Col,
    Divider,Select
} from 'antd';
import { useTranslation } from "../../hooks/useTranslation";
import { useSelector } from "../../hooks/useSelector";
import { useRequest } from "../../hooks/useRequest";
import { api } from "../../config/api";

export const UserEdit: React.FC<UserEditType> = ({type, user, onClose}) =>{
    // const [parentId1, setParentId1] = useState(parentId || 0);
    const [enable, setEnable] = useState(true);
    const t = useTranslation();
    const [form] = Form.useForm();
    const setting = useSelector(state => state.setting);
    const {json} = useRequest();
    const loginUser = useSelector(state => state.user);

    const [roles, setRoles] = useState<any[]>([]);
    let filteredTypes: {label: string, value: number}[] = UserTypes;
    if(loginUser.type==1){
        filteredTypes = UserTypes.filter(t => t.value!=0);
    }else if(loginUser.type==2){
        filteredTypes = UserTypes.filter(t => t.value == 2);
    }

    useEffect(()=>{
        let getRoles = async() => {
            let result = await json.get(api.role.roles);
            if(result.status){
                setRoles(result.data);
            }
        }
        getRoles();

      
    }, 
    //eslint-disable-next-line
    []);
    
    const handleClose = () => {
        setEnable(false);
        setTimeout(() => {
            onClose(false);
        }, 500);
    }

    const onFinishFailed = () => {
        message.warning({
            content: t('请确认数据正确后在提交'),
        });
    };

    const onFinish = (values: UserType) => {
        // console.log(values);
        let data: UserType = {...user, ...values}
        // console.log(data);
        let url = data.id?api.user.update:api.user.add;
        
        let update = async() => {
            let result = await json.post(url, data);
            message.success(t(result.msg));
            if(result.status){
                setEnable(false);
                onClose(true);
            }
        }

        update();
    };
    
    const formHtml = (
        <div className="edit-form-wrap">
            <Form<UserType> form={form} labelCol={{span: 8}} wrapperCol={{span:16}} 
                size={setting.size}
                onFinishFailed={onFinishFailed}
                onFinish={onFinish}
                labelAlign="right"
            >   
                <Divider plain>{t('基本信息')}</Divider>
                <Row>
                    <Col sm={24} md={12}  lg={8}>
                        <Form.Item
                            label={t('账号')}
                            name="username"
                            initialValue={user.username}
                            rules={[{ required: true, message:  t("用户名不能为空")}]}
                        >
                            <Input allowClear/>
                        </Form.Item>
                    </Col>
                    <Col sm={24} md={12}  lg={8}>
                        <Form.Item
                            label={t('姓名')}
                            name="fullname"
                            initialValue={user.fullname}
                            rules={[{ required: true, message:  t("姓名不能为空")}]}
                        >
                            <Input allowClear/>
                        </Form.Item>
                    </Col>
                    <Col sm={24} md={12}  lg={8}>
                        <Form.Item
                            label={t('密码')}
                            name="password"
                        >
                            <Input.Password />
                        </Form.Item>
                    </Col>
                    <Col sm={24} md={12}  lg={8}>
                        <Form.Item
                            label={t('确认密码')}
                            name="repassword"
                            rules={[
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error(t('两次密码输入不一致')));
                                    },
                                }),
                            ]}
                        >
                            <Input.Password allowClear/>
                        </Form.Item>
                    </Col>
                </Row>
                <Divider plain>{t('账户信息')}</Divider>
                <Row>
                    <Col sm={24} md={12}  lg={8}>
                        <Form.Item
                            label={t('角色')}
                            name="roleId"
                            initialValue={user.roleId}
                            rules={[{ required: true, message:  t("角色不能为空")}]}
                        >
                            <Select allowClear>
                                {roles.map(i => (
                                    <Select.Option value={i.id} key={i.id}>
                                        {i.id+"-"+i.label+"("+i.name+")"}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col sm={24} md={12}  lg={8}>
                        <Form.Item
                            label={t('状态')}
                            name="disabled"
                            initialValue={user.disabled}
                            rules={[{ required: true, message:  t("状态不能为空")}]}
                        >
                            <Select allowClear>
                                {Disables.map(i => (
                                    <Select.Option value={i.value} key={i.value}>
                                        {i.label}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col sm={24} md={12}  lg={8}>
                        <Form.Item
                            label={t('类型')}
                            name="type"
                            initialValue={user.type}
                            rules={[{ required: true, message:  t("类型不能为空")}]}
                        >
                            <Select allowClear>
                                {filteredTypes.map(i => (
                                    <Select.Option value={i.value} key={i.value}>
                                        {i.label}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Divider plain>{t('个人设置')}</Divider>
                <Row>
                    <Col sm={24} md={12}  lg={8}>
                        <Form.Item
                            label={t('电话')}
                            name="tel"
                            initialValue={user.tel}
                        >
                            <Input allowClear/>
                        </Form.Item>
                    </Col>
                    <Col sm={24} md={12}  lg={8}>
                        <Form.Item
                            label={t('黑猫客代号')}
                            name="hmNo"
                            initialValue={user.hmNo}
                        >
                            <Input allowClear/>
                        </Form.Item>
                    </Col>
                    <Col sm={24} md={12}  lg={8}>
                        <Form.Item
                            label={t('寄件人')}
                            name="senderName"
                            initialValue={user.senderName}
                        >
                            <Input allowClear/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </div>
    );

    let title = type==1?t('新增用户') : t('编辑用户')
        
    return (
        <Modal visible={enable}
            title={title}
            width={900}
            onOk={()=>{form.submit()}}
            onCancel={()=>handleClose()}
            afterClose={()=>handleClose()}
        >
            {formHtml}
        </Modal>
    )
}