import { Modal } from '../../components';
import { useState,useEffect } from "react";
import { useSelector } from "../../hooks/useSelector";
import {Form, Input,message, Row, Col} from 'antd';
import { useTranslation } from "../../hooks/useTranslation";
import { api } from "../../config/api";
import { useRequest } from "../../hooks/useRequest";

export interface PwdType {
    oldPassword?: string | null; 
    password?: string | null; 
    repassword?: string | null;
}

export const ChangePassword : React.FC = () => {

    const [pwd, setPwd] = useState<PwdType>({
        oldPassword: null,
        password: null,
        repassword: null
    });
    const [enable, setEnable] = useState(true);
    const t = useTranslation();
    const [form] = Form.useForm();
    const {json} = useRequest();
    const setting = useSelector(state => state.setting);

    const onFinishFailed = () => {
        message.warning({
            content: t('请确认数据正确后在提交'),
        });
    };

    const handleClose = () => {
        setEnable(false);
    }

    const onFinish = (values: PwdType) => {

        let url = api.user.pwd;
        let update = async() => {
            let result = await json.post(url, values);
            if(result.status){
                message.success(t(result.msg));
                setEnable(false);
            }else{
                message.warning(t(result.msg));
            }
        }
        update();
    }

    const formHtml = (
        <div className="edit-form-wrap">
            <Form<PwdType> form={form} labelCol={{span: 8}} wrapperCol={{span:16}} 
                size={setting.size}
                onFinishFailed={onFinishFailed}
                onFinish={onFinish}
                labelAlign="right"
            >   
                <Row>
                    <Col sm={24}>
                        <Form.Item
                            label={t('旧密码')}
                            name="oldPassword"
                            initialValue={pwd.oldPassword}
                            rules={[{ required: true, message:  t("旧密码不能为空")}]}
                        >
                            <Input.Password allowClear/>
                        </Form.Item>
                    </Col>
                    <Col sm={24}>
                        <Form.Item
                            label={t('新密码')}
                            name="password"
                            initialValue={pwd.password}
                            rules={[{ required: true, message:  t("新密码不能为空")}]}
                        >
                            <Input.Password allowClear/>
                        </Form.Item>
                    </Col>
                    <Col sm={24}>
                        <Form.Item
                            label={t('确认密码')}
                            name="repassword"
                            initialValue={pwd.repassword}
                            rules={[{ required: true, message:  t("确认密码不能为空")}]}
                        >
                            <Input.Password allowClear/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </div>
    );

    let title = t('修改密码')

    return (
        <div>
            <Modal visible={enable}
                title={title}
                width={500}
                onOk={()=>{form.submit()}}
                onCancel={()=>handleClose()}
                afterClose={()=>handleClose()}
            >
                {formHtml}
            </Modal>
        </div>
    )
}