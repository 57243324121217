
export const ExpressBackStatuses = [
    {label: '待处理', value: '0', color: '#108ee9'},
    {label: '处理中', value: 1, color: '#2db7f5'},
    {label: '已完成', value: 2, color: '#87d068'},
    {label: '银行账号不存在', value: 3, color: '#f50'},
    {label: '超过7天', value: 4, color: '#f50'},
    {label: '取件未成功', value: 5, color: '#f50'},
]

export const ExpressBackStatuses1 = [
    {label: '等待處理中', value: 0, color: '#108ee9'},
    {label: '已提交給廠商處理中請耐心等待', value: 1, color: '#2db7f5'},
    {label: '款項已退款 請刷本子確認', value: 2, color: '#87d068'},
    {label: '銀行賬號不存在', value: 3, color: '#f50'},
    {label: '超过7天', value: 4, color: '#f50'},
    {label: '取件未成功', value: 5, color: '#f50'},
]

export const SendMailStatus = [
    {label: '未发送', value: 0, color: '#f50'},
    {label: '已发送', value: 1, color: '#87d068'},
]

export const ExpressBackTypes = [
    {label: '换货', value: 1, color: '#108ee9'},
    {label: '退款', value: 2, color: '#2db7f5'},
]

export const ExpressSubmit = [
    {label: '未提交过', value: 0, color: '#108ee9'},
    {label: '已提交过', value: 1, color: '#2db7f5'},
]

export const DeliverType = [
    {label: '纯配送', value: 3, color: '#108ee9'},
    {label: '货到付款', value: 1, color: '#2db7f5'},
]

export interface ExpBackEditType{
    setVisible:  React.Dispatch<React.SetStateAction<boolean>>,
    expBack: any;
}

export interface ExpBackStatusUpdateType{
    setVisible: React.Dispatch<React.SetStateAction<boolean>>,
    expBacks: any[];
}

export interface ExpBackStatusesUpdateType{
    onClose: (updateOk?: boolean)=>void;
}

export interface BankType {
    id?: number | null;
    code?: string | null; 
    name?: string | null; 
}

export const banks = [
    {label: '臺灣銀行', value: '臺灣銀行', code:'004'},
    {label: '土地銀行', value: '土地銀行', code:'005'},
    {label: '合庫商銀', value: '合庫商銀', code:'006'},
    {label: '第一銀行', value: '第一銀行', code:'007'},
    {label: '華南銀行', value: '華南銀行', code:'008'},
    {label: '彰化銀行', value: '彰化銀行', code:'009'},
    {label: '上海銀行', value: '上海銀行', code:'011'},
    {label: '台北富邦', value: '台北富邦', code:'012'},
    {label: '國泰世華', value: '國泰世華', code:'013'},
    {label: '高雄銀行', value: '高雄銀行', code:'016'},
    {label: '兆豐商銀', value: '兆豐商銀', code:'017'},
    {label: '花旗(台灣)銀行', value: '花旗(台灣)銀行', code:'021'},
    {label: '澳盛銀行', value: '澳盛銀行', code:'039'},
    {label: '臺灣企銀', value: '臺灣企銀', code:'050'},
    {label: '渣打銀行', value: '渣打銀行', code:'052'},
    {label: '台中商銀', value: '台中商銀', code:'053'},
    {label: '京城商銀', value: '京城商銀', code:'054'},
    {label: '匯豐銀行', value: '匯豐銀行', code:'081'},
    {label: '瑞興銀行', value: '瑞興銀行', code:'101'},
    {label: '華泰銀行', value: '華泰銀行', code:'102'},
    {label: '臺灣新光商銀', value: '臺灣新光商銀', code:'103'},
    {label: '陽信銀行', value: '陽信銀行', code:'108'},
    {label: '三信銀行', value: '三信銀行', code:'147'},
    {label: '中華郵政', value: '中華郵政', code:'700'},
    {label: '聯邦銀行', value: '聯邦銀行', code:'803'},
    {label: '遠東銀行', value: '遠東銀行', code:'805'},
    {label: '元大銀行', value: '元大銀行', code:'806'},
    {label: '永豐銀行', value: '永豐銀行', code:'807'},
    {label: '玉山銀行', value: '玉山銀行', code:'808'},
    {label: '凱基銀行', value: '凱基銀行', code:'809'},
    {label: '星展銀行', value: '星展銀行', code:'810'},
    {label: '台新銀行', value: '台新銀行', code:'812'},
    {label: '日盛銀行', value: '日盛銀行', code:'815'},
    {label: '安泰銀行', value: '安泰銀行', code:'816'},
    {label: '中國信託', value: '中國信託', code:'822'},
]