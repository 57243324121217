import './Express.less';

import {Checkbox, Space, Row, Col,  Popover,Tag,message, Modal,Menu,TablePaginationConfig} from 'antd';
import { PlusOutlined, DeleteOutlined,EditOutlined, FileExcelOutlined, EyeOutlined, MenuOutlined, ExclamationCircleOutlined,CheckOutlined,RedoOutlined,ArrowUpOutlined,CopyOutlined} from '@ant-design/icons';
import { useSelector, useTranslation, useTable, TableType, useRequest, useExport, CellFormatter} from '../../hooks';
import { SearchForm, SearchItemProp, AuthDropdown as Dropdown,AuthButton as Button,DeliverTypes,companies} from '../../components';
import { ExpStatuses, PrintStatuses,UpperStatuses,ErrMsgs} from '../';
import { api } from '../../config/api';
import { useEffect, useState } from 'react';
import {  ExpressDetail,ExpressEdit,ExpressPrint,ExpressResend,ExpressUpper,ExpressStatusDetail } from '../';
import {  ExpressEditType,defaultExpress,ExpressPrintType,ExpressResendType,ExpressUpperType } from './ExpressType';

export const Express: React.FC = () => {

    const user = useSelector(state => state.user);

    const t = useTranslation();
    const setting = useSelector(state => state.setting);
    const layoutInfo = useSelector(state => state.layoutInfo);
    const {request} = useRequest();
    const exportKit = useExport();

    const [expDetailVisible, setExpDetailVisible] = useState(false);
    const [expStatusDetailVisible, setExpStatusDetailVisible] = useState(false);
    
    const [expCode, setExpCode] = useState<string>('');

    const [expId, setExpId] = useState<number>(0);

    const [colFilterVisible, setColFilterVisible] = useState(false);
    const [checkedCols, setCheckCols] = useState<any[]>();

    const [query, setQuery] = useState<any | null>();

    let defaultColumns = [
        {title: t("ID"), key: "id", width:80, sorter: true, type: 'checkbox', fixed: 'left'},
        {title: t("配送编号"),  width:150, key: "consignCode", sorter: true, filter: 'search'},
        {title: t("订单编号"),  width:180, key: "orderCode", sorter: true, filter: 'search'},
        {title: t("物流厂商"),  width:130, key: "company", sorter: true, 
            filter: 'select', filterOptions: companies
        },
        {title: t("配送方式"),  width:130, key: "deliverType", sorter: true, filter: 'search',
            render: (text, row, index) => {
                let option = DeliverTypes.find(s => s.value == text);
                return option?(<Tag color={option.color}>{option.label}</Tag>):({text});
        }},
        {title: t("状态"),  width:130, key: "status", sorter: true, filter: 'select',
            filterOptions: ExpStatuses,
            render: (text, row, index) => {
                let option = ExpStatuses.find(s => s.value == text);
                return option?(<Tag color={option.color}>{option.label}</Tag>):({text});
        }},
        {title: t("实时货态"),  width:160, key: "track", sorter: true, filter: 'search'},
        {title: t("状态同步时间"),  width:150, key: "syncStatusDate", sorter: true, filter: 'search'},
        {title: t("商品名称"),  width:150, key: "expressName", sorter: true, filter: 'search'},
        {title: t("打印状态"),  width:120, key: "printStatus", sorter: true, filter: 'select', 
            filterOptions: PrintStatuses,
            render: (text, row, index) => {
                let option = PrintStatuses.find(s => s.value == text);
                return option?(<Tag color={option.color}>{option.label}</Tag>):({text});
            },
        },
        {title: t("面单打印时间"),  width:150, key: "printTime", sorter: true, filter: 'search'},
        {title: t("订单金额"),  width:120, key: "orderAmount", sorter: true, filter: 'search'},
        {title: t("面单寄件人"),  width:120, key: "orderSender", sorter: true, filter: 'search'},
        {title: t("厂商名称"),  width:120, key: "factoryName", sorter: true, filter: 'search'},
        {title: t("客服电话"),  width:150, key: "serviceTel", sorter: true, filter: 'search', tip: true},
        {title: t("收件人姓名"),  width:130, key: "receiverName", sorter: true, filter: 'search'},
        {title: t("收件人电话"),  width:130, key: "receiverTel", sorter: true, filter: 'search'},
        {title: t("门市编号"),  width:130, key: "receiverShopId", sorter: true, filter: 'search'},
        {title: t("门市名"),  width:130, key: "receiverShopName", sorter: true, filter: 'search'},
        {title: t("备注"),  width:130, key: "remark", sorter: true, filter: 'search'},
        {title: t("收件地址"),  width:150, key: "receiverShopAddress", sorter: true, filter: 'search', tip: true},
        {title: t("信息"),  width:150, key: "errmsg", sorter: true, filter: 'search',
            render: (text, row, index) => {
                let errcode = row.errcode;
                if(errcode && text){
                    return <>{errcode +'/'+ text}</>
                }else if(errcode){
                    return <>{errcode}</>
                }else if(text){
                    return <>{text}</>
                }else{
                    return <></>
                }
            },
        },
        {title: t("创建人"),  width:150, key: "createrName", sorter: true, filter: 'search'},
        {title: t("创建时间"),  width:150, key: "created", sorter: true, filter: 'search'},
        {title: t("SIN文件名"),  width:150, key: "sinFilename", sorter: true, filter: 'search'},
        {title: t("SUP文件名"),  width:150, key: "sup", sorter: true, filter: 'search'},
        {title: t("出货时间"),  width:150, key: "shipdate", sorter: true, filter: 'search'},
        {title: t("物流费"),  width:150, key: "expFee", sorter: true, filter: 'search'},
        {title: t("物流费1"),  width:150, key: "expFeeMy", sorter: true, filter: 'search'},
        {title: t("物流费扣款日期"),  width:150, key: "expFeeDate", sorter: true, filter: 'search'},
        {title: t("物流代收款项"),  width:150, key: "expProxyFee", sorter: true, filter: 'search'},
        {title: t("物流代收款项拨款金额"),  width:150, key: "expProxyActualFee", sorter: true, filter: 'search'},
        {title: t("物流代收款项拨款日期"),  width:150, key: "expProxyDate", sorter: true, filter: 'search'},
        {title: t("遗失赔偿日期"),  width:150, key: "payReparationsDate", sorter: true, filter: 'search'},
        {title: t("遗失赔应偿金额"),  width:150, key: "payReparationsAmount", sorter: true, filter: 'search'},
        {title: t("遗失应赔税额"),  width:150, key: "payReparationsRate", sorter: true, filter: 'search'},
        {title: t("遗失赔未偿金额"),  width:150, key: "payReparationsUnamount", sorter: true, filter: 'search'},
        {title: t("上架状态"),  width:150, key: "upperStatus", sorter: true, 
            filter: 'select', 
            filterOptions: UpperStatuses,
            render: (text, row, index) => {
                let option = UpperStatuses.find(s => s.value == text);
                return option?(<Tag color={option.color}>{option.label}</Tag>):({text});
            },
        },
        {title: t("上架时间"),  width:150, key: "upperDate", sorter: true, filter: 'search'},
        {title: t("上架号"),  width:150, key: "upperCode", sorter: true, filter: 'search'},
        {title: t("重出编号"),  width:150, key: "reupper", sorter: true, filter: 'search'},
        {title: t("全家取貨條碼一"),  width:150, key: "ecBarCode9", sorter: true, filter: 'search'},
        {title: t("全家取貨條碼二"),  width:150, key: "ecBarCode16", sorter: true, filter: 'search'},
        {title: t("全家物流編號"),  width:150, key: "ecBarCode17", sorter: true, filter: 'search'},
        {title: t("全家物流檢核碼"),  width:150, key: "ecBarCode1Chk", sorter: true, filter: 'search'},
    ]

    let arr = ['expFeeDate','expProxyActualFee','expFee','expFeeMy','expProxyFee','expProxyDate'];
    if(user.roleId == 3){
        defaultColumns = defaultColumns.filter(s => arr.indexOf(s.key)<0 );
    }

    const [columns, setColumns] = useState<any[]>(defaultColumns);

    useEffect(() => {
        let expCols = localStorage.getItem("exp-cols");
        let cols: any[] = [];
        if(expCols && expCols != 'undefined') {
            let temps = JSON.parse(expCols);
            setQuery({
                cols: temps,
            });
            cols = defaultColumns.filter(c => temps.indexOf(c.key)>-1);
        }else{
            cols = defaultColumns.slice(0,20);
            let colKeys = cols.map(c => c.key);
            setQuery({
                cols: colKeys,
            });
            localStorage.setItem("exp-cols", JSON.stringify(colKeys));
        }

        setColumns(cols);
        setCheckCols(cols.map(e => e.key));
        // setColumns(defaultColumns);
    }, 
    // eslint-disable-next-line
    []);

    const searchItems: SearchItemProp[] = [
        // {label: "配送编码", name: 'consignCode', type: 'input'},
        {label: "配送编码", name: 'consignCodes', type: 'textArea'},
        // {label: "订单编号", name: 'orderCode', type: 'input'},
        {label: "订单编号", name: 'orderCodes', type: 'textArea'},
        {label: "厂商类型", name: 'company', type: {options: companies}},
        {label: "状态", name: 'status', type: {options:ExpStatuses}},
        {label: "打印状态", name: 'printStatus', type: {options:PrintStatuses}},
        {label: "创建人", name: 'createrName', type: 'input'},
        {label: '创建日期', name: "Date", type: "datepicker", format:"YYYY-MM-DD"},
        // {label: "收件人", name: 'receiverName', type: 'input'},
        {label: "上架状态", name: 'upperStatus', type: {
            options: UpperStatuses
        }},
        {label: "信息", name: 'errmsg', type: 'input'},
        // {label: "更换门市", name: 'changeState', type: {options: ErrMsgs}},
        // {label: '错误状态', name: "errStatus",type: {options: ErrMsgs}},
        // {label: '重出', name: 'reupper', type: "input"},
        // {label: "上架号", name: 'upperCode', type: "input"},
        // {label: "收件人电话", name: 'receiverTel', type: "input"},
        // {label: "商品名称", name: 'expressName', type: "input"},
        {label: '拨款日期', name: "ExpProxyDate", type: "datepicker", format:"YYYY-MM-DD"},
    ]

    // const findCell = (tag: HTMLElement | null ) : (HTMLElement | null)=> {
    //     if(tag == null) {
    //         return tag;
    //     }else if(tag.tagName.toLocaleLowerCase() == 'td'){
    //         return tag;
    //     }else{
    //         return findCell(tag.parentElement);
    //     }
    // }

    const [editVisible, setEditVisible] = useState(false);
    const [editObj, setEditObj] = useState<ExpressEditType>({
        type: 1,
        express: {},
        onClose: (type)=>{
            if(type){
                table.remoteSearch(query);
            }
            setEditVisible(false);
        }
    });
    //处理新增，
    const handleAdd = () =>{
        setEditObj({...editObj,
            type: 1,
            express: {...defaultExpress},
        });
        setEditVisible(true);
    }

    const [printVisible, setPrintVisible] = useState(false);
    const [printObj, setPrintObj] = useState<ExpressPrintType>({
        id:null,
        onClose: (type)=>{
            if(type){
                table.remoteSearch(query);
            }
            setPrintVisible(false);
        }
    });

    const [resendVisible, setResendVisible] = useState(false);
    const [resendObj, setResendObj] = useState<ExpressResendType>({
        code:null,
        onClose: (type)=>{
            setResendVisible(false);
        }
    });

    const [upperVisible, setUpperVisible] = useState(false);
    const [upperObj, setUpperObj] = useState<ExpressUpperType>({
        code:null,
        onClose: (type)=>{
            setUpperVisible(false);
        }
    });

    const handlePrint = () =>{
        let selecteds = table.getSelectDatas();
        if(selecteds.length===0){
            message.warning(t("请选中一条数据后再进行操作"));
            return;
        }
        let temp = selecteds[0];
        setPrintObj({...printObj,
            id:temp.id
        });
        setPrintVisible(true);
    }

    const handleEdit = () => {
        let selecteds = table.getSelectDatas();
        if(selecteds.length===0){
            message.warning(t("请选中一条数据后再进行操作"));
            return;
        }
        let temp = selecteds[0];
        setEditObj({type: 2, onClose: editObj.onClose, express: temp});
        setEditVisible(true);
    }

    //重出
    const handleResend = () => {
        let selecteds = table.getSelectDatas();
        if(selecteds.length===0){
            message.warning(t("请选中一条数据后再进行操作"));
            return;
        }
        let temp = selecteds[0];
        setResendObj({code: temp.id, onClose: resendObj.onClose});
        setResendVisible(true);
    }

    //上架
    const handleUpper = () => {
        let selecteds = table.getSelectDatas();
        if(selecteds.length===0){
            message.warning(t("请选中一条数据后再进行操作"));
            return;
        }
        let temp = selecteds[0];
        setUpperObj({code: temp.id, onClose: upperObj.onClose});
        setUpperVisible(true);
    }

    //删除
    let doDelete = async (selecteds: any[]) => {
        let data = await request.post(api.express.deletes, "type=0&codes="+selecteds.map(s => s.id).join(","));
        if(data.status){
            message.success(t(data.msg));
            table.remoteSearch(query);
        }else{
            message.warning(t(data.msg));
        }
    }

    //删除
    const handleDelete = () => {
        let selecteds = table.getSelectDatas();
        if(selecteds.length===0){
            message.warning(t("请选中一条数据后再进行操作"));
            return;
        }

        Modal.confirm({
            content: t("确定要删除["+selecteds.map(s => s.id).join(",")+"]吗？"),
            okText: t('确定'),
            okType: 'danger',
            cancelText: t('取消'),
            onOk: ()=>{
                doDelete(selecteds);
            }
        });
    }

    let titles = columns.map(c => c.title);

    const exportCurrPage = () => {
        
        //  exportKit
        // let table = document.querySelector(".xm-table-exp");
        // if(table){
        //     exportKit.byTable(table, t("物流导出")+new Date().getTime()+".xlsx");
        // }

        const formats: Map<number, CellFormatter> = new Map();
        
        columns.forEach((row, index) => {
            let options = row['filterOptions'];
            if(options){
                formats.set(index, (row, cell) => {
                    let type = options.find(s => s.value == cell);
                    return type? type.label: cell
                });      
            }
        });
      
        let pageInfo = table.pageInfo as TablePaginationConfig;
        exportKit.byAjax({
            url: api.express.export,
            query: {
                //@ts-ignore
                start: (pageInfo.current-1)*pageInfo.pageSize,
                length: pageInfo.pageSize,
                ...query,
                cols: columns.map(c => c.key)
            },
            extra: (result) => result.data,
            dataType: {
                filename: t("物流导出")+new Date().getTime()+".xlsx",
                titles: titles,
                formats: formats
            }
        });
    }

    const exportAll = () => {

        if(query==null || Object.keys(query).length == 0){
            Modal.confirm({
                content: t("没有任何查询条件，可能导致系统卡死，是否继续操作"),
                okText: t('确定'),
                okType: 'danger',
                cancelText: t('取消'),
                onOk: ()=>{
                    doExportAll();
                }
            });
        }else if(table.pageInfo['total'] > 40000){
            Modal.confirm({
                content: t("数据量过大，可能导致浏览器卡死，是否继续操作"),
                okText: t('确定'),
                okType: 'danger',
                cancelText: t('取消'),
                onOk: ()=>{
                    doExportAll();
                }
            });
        }else{
            doExportAll();
        }
    }

    const doExportAll = () => {

        const formats: Map<number, CellFormatter> = new Map();
        columns.forEach((row, index) => {
            let options = row['filterOptions'];
            if(options){
                formats.set(index, (row, cell) => {
                    let type = options.find(s => s.value == cell);
                    return type? type.label: cell
                });      
            }
        });

        let pageInfo = table.pageInfo as TablePaginationConfig;
        
        exportKit.byAjax({
            url: api.express.export,
            query: {
                start:  0,
                //@ts-ignore
                length: pageInfo.total,
                ...query,
                cols: columns.map(c => c.key)
            },
            extra: (result) => result.data,
            dataType: {
                filename: t("物流导出")+new Date().getTime()+".xlsx",
                titles: titles,
                formats: formats
            }
        });
    }

    //查看物流
    const viewExpress = () => {
        let selecteds = table.getSelectDatas();
        if(selecteds.length===0){
            message.warning(t("请选中一条数据后再进行操作"));
            return;
        }

        if(!selecteds[0].id){
            message.warning(t("该退件无物流信息"));
            return;
        }
        setExpCode(selecteds[0].id);
        setExpDetailVisible(true);

    }

    //查看状态
    const viewExpressStatus = () => {
        let selecteds = table.getSelectDatas();
        if(selecteds.length===0){
            message.warning(t("请选中一条数据后再进行操作"));
            return;
        }

        if(selecteds.length>1){
            message.warning(t("只能选择一条数据"));
            return;
        }

        setExpId(selecteds[0].id);
        setExpStatusDetailVisible(true);

    }

    const showTotal = () => {
        let selecteds = table.getSelectDatas();
        let text = "您当前选中："+selecteds.length+" 条";
        Modal.confirm({
            title: t('提示：'),
            icon: <ExclamationCircleOutlined />,
            content: text,
            okText: '确认',
            cancelText: '取消',
          });
    }

    const dropdownMenu = (
        <Menu>
            <Menu.Item key="1" onClick={exportCurrPage} icon={<FileExcelOutlined />}>
                {t("导出当前页")}
            </Menu.Item>
            <Menu.Item key="2" onClick={exportAll} icon={<FileExcelOutlined />}>
                {t("导出所有")}
            </Menu.Item>
        </Menu>
    );

    let y = layoutInfo.isMin? layoutInfo.content-355 : layoutInfo.content-290;
    if(y<250){
        y = 250;
    }

    const tableProp: TableType = {
        rowKey: "id",
        pagenation: true,
        url: api.express.list,
        scroll: {y:y},
        query: {cols: columns.map(c => c.key)},
        tableClassName: "xm-table-exp",
        columns: columns
    }

    const table = useTable(tableProp);

    const tableHtml = table.TableNode();

    const onSearch = (values) => {

        if(values && values['consignCodes']){
            let cs = values['consignCodes'].split(/\s+/);
            let css:any[]  = [];
            for(let i=0; i<cs.length; i++){
                if(cs[i]) css.push(cs[i]);
            }
            values['consignCodes'] = css.join(",");
        }
        if(values && values['orderCodes']){
            let cs = values['orderCodes'].split(/\s+/);
            let css:any[]  = [];
            for(let i=0; i<cs.length; i++){
                if(cs[i]) css.push(cs[i]);
            }
            values['orderCodes'] = css.join(",");
        }
        let q = {...values, cols: query.cols};
        setQuery(q);
        table.remoteSearch(q);
    }

    const columnsChanage = (value) => {
        setCheckCols(value);
    }

    //筛选
    const doFilterCols = () => {
        let cols = defaultColumns.filter(col => checkedCols && checkedCols.indexOf(col.key)>-1);
        if(cols){
            let q = {...query, cols: checkedCols};
            setColumns(cols);
            setQuery(q)
            localStorage.setItem("exp-cols", JSON.stringify(checkedCols));
            table.remoteSearch(q);
        }
        setColFilterVisible(false);
    }

    const filterNodeHtml = (<Popover content={
        <div>
            <Checkbox.Group style={{ width: '400px', maxHeight:"400px", overflow: 'auto' }} 
                    defaultValue={columns.map(m => m.key)}
                    onChange={columnsChanage}>
                <Row>
                    {defaultColumns.map(m => (
                        <Col key={m.key} span={12}>
                            <Checkbox value={m.key} >
                                    {m.title}
                            </Checkbox>
                        </Col>
                    ))} 
                </Row>
            </Checkbox.Group>
            <div style={{width: '400px', textAlign: 'center'}}>
                <Button onClick={() => setColFilterVisible(false)} type="link">{t('取消')}</Button>
                <Button icon={<CheckOutlined />} 
                    onClick={doFilterCols} type="primary">{t('确定筛选')}</Button>
            </div>
        </div>
    } title="筛选字段"
        trigger="click" visible={colFilterVisible}
        onVisibleChange={(visible) => setColFilterVisible(visible)}
        arrowPointAtCenter={true}
        placement='bottom'
    >
        <Button type="primary" icon={<MenuOutlined />}>{t("筛选")}</Button>
    </Popover>);

    return (
        <div>
            <SearchForm searchItems={searchItems} onSearch={onSearch}></SearchForm>
            <div className={`content-wrap ${layoutInfo.isMin?'content-wrap-min': ''}`} style={{marginTop: '10px'}}>
                <Row className="ctrl-wrap">
                    <Col span={24} >
                        <Space size={setting.size} wrap>
                            {/* <Button icon={<EyeOutlined />} onClick={showTotal} type="primary">{t('总数')}</Button> */}
                            <Button permission="express:detail" icon={<EyeOutlined />} onClick={viewExpress} type="primary">{t('查看')}</Button>
                            <Button permission="express:create" onClick={handleAdd} icon={<PlusOutlined />} type="primary">{t('新增')}</Button>
                            <Button permission="express:update" onClick={handleEdit} icon={<EditOutlined />} type="primary">{t('编辑')}</Button>
                            <Button permission="express:print" onClick={handlePrint} icon={<CopyOutlined />} type="primary">{t('打印')}</Button>
                            <Button permission="express:detail" onClick={viewExpressStatus} icon={<EyeOutlined />} type="primary">{t('状态')}</Button>
                            {/* <Button permission="express:resend" onClick={handleResend} icon={<RedoOutlined />} type="primary">{t('重出')}</Button> */}
                            {/* <Button permission="express:upper" onClick={handleUpper} icon={<ArrowUpOutlined />} type="primary">{t('上架')}</Button> */}
                            <Dropdown permission="express:export" btnTxt={t('导出')} btnIcon={<FileExcelOutlined />} overlay={dropdownMenu}></Dropdown>
                            <Button permission="express:deletes" icon={<DeleteOutlined />} onClick={handleDelete} type="dashed" danger>{t('删除')}</Button>
                            {filterNodeHtml}
                        </Space>
                    </Col>
                </Row>
                {tableHtml}
            </div>
            {upperVisible && <ExpressUpper {...upperObj} />}
            {resendVisible && <ExpressResend {...resendObj} />}
            {printVisible && <ExpressPrint {...printObj} />}
            {editVisible && <ExpressEdit {...editObj} />}
            {expDetailVisible && <ExpressDetail setVisible={setExpDetailVisible} codeType={0} code={expCode}/>}
            {expStatusDetailVisible && <ExpressStatusDetail setVisible={setExpStatusDetailVisible} expId={expId}/>}
        </div>
    )
}