import { Layout as AntdLayout, Menu, Row, Col,
    Dropdown ,Button, MenuTheme
} from 'antd';
import React, {useEffect,useState} from 'react';
import i18n from "i18next";
import {
  UserOutlined,
  SkinOutlined,
  SkinFilled,
  PicRightOutlined,
  PicCenterOutlined,
  LogoutOutlined,
  SettingOutlined,
  SecurityScanOutlined,
  InfoCircleOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined
} from '@ant-design/icons';
import { useSelector } from "../../hooks/useSelector"
import { useRequest } from '../../hooks';
import {api} from '../../config/api';
import { useDispatch } from "react-redux";
import { langSlice, LangType } from "../../redux/langSlice"
import { settingSlice, userSlice, layoutInfoSlice} from '../../redux/global';
import {Logo} from "../Logo";
import {useTranslation} from '../../hooks/useTranslation';
import "./Layout.less";
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { useHistory } from "react-router-dom";
import { SideMenu, Footer, Content as MyContent } from '../';
import { ChangePassword } from './ChangePassword';

const { Header, Content, Sider } = AntdLayout;
const { SubMenu } = Menu;

export const Layout: React.FC = () => {
    const t = useTranslation();
    const collapsed = useSelector((state) => state.setting.collapsed);
    const theme = useSelector(state => state.setting.theme)  as MenuTheme;;
    const size = useSelector(state => state.setting.size) as SizeType;
    const mode = useSelector(state => state.setting.menuMode);
    const hidden = useSelector(state => state.setting.hidenMenu);
    const user = useSelector(state => state.user);
    const history = useHistory();

    const dispatch = useDispatch();
    const {request} = useRequest();

    const language = useSelector((state) => state.lang.language);
    const languageList = useSelector((state) => state.lang.languageList);
    const [pwdVisible, setPwdVisible] = useState(false); //是否显示修改密码弹窗

    //如果是小屏幕窗口，modal显示全屏
    useEffect(()=>{
        //获取窗口大小
        const updateWindowSize = (e) => {
            // console.log("resize");
            let width = document.body.clientWidth;
            let height = document.body.clientHeight;

            if(width<500){
                if(!collapsed){
                    dispatch(settingSlice.actions.switchCollapsed());
                }
            }

            let sideWidth = hidden?0: (collapsed?80:200);
            let topHeight = mode=='inline'? 95: 72;
            let footerHeight = 36;
            let contentHeight = height - topHeight-footerHeight;

            dispatch(layoutInfoSlice.actions.setInfo({
                width: width,
                height: height,
                sideWidth: sideWidth,
                topHeight: topHeight,
                footerHeight: footerHeight,
                content: contentHeight,
                isMin: width<=500,
            }));
        }

        updateWindowSize(null);

        window.addEventListener("resize", updateWindowSize);
        return () => {
            window.removeEventListener("resize", updateWindowSize);
        }
    }, 
    // eslint-disable-next-line
    []);

    //处理语言显示的文字
    let langName = language?.name || "zh-cn";
    if(langName.indexOf("zh")>-1){
        langName = langName.indexOf("cn")>-1?"简":"繁";
    }else{
        langName = langName.substring(0,1).toUpperCase()+langName.substring(1,2).toLowerCase();
    }

    //处理切换侧边栏
    const changeCollapse = (collapsed, type) => {
        dispatch(settingSlice.actions.switchCollapsed());
    }

    //处理语言切换
    const changeLang = (key: string) => {
        let lang = languageList.find(l => l.name == key) as LangType;
        // console.log("clicked changeLang");
        if(lang){
            localStorage.setItem("lng", lang.name || "zh-cn");
            dispatch(langSlice.actions.changeLang(lang));
        }
    }

    //处理主题切换
    const changeTheme = (key: string) => {
        dispatch(settingSlice.actions.switchTheme(key))
    }

    //查看个人信息
    const viewUserInfo = () => {
        history.push(`/admin/userinfo/${user.id}`);
    }

     //修改密码
     const cnagePwd = () => {
        setPwdVisible(true);
    }

    //登出
    const logout = () => {
        dispatch(userSlice.actions.logOut());
        quitLogin();
    }

    let quitLogin = () =>{
        request.post(api.auth.quitLogin);
    }
    
    //切换语言时，刷新页面
    useEffect(() => {
        // console.log(language);
        if(language){
            i18n.changeLanguage(language.name);
        }
    }, [language]);

     //如果用户不存在则退出
    useEffect(()=>{
        if(!user.id){
            history.push("/");
            window.location.reload();
        }
    }, [user,history]);

    //动态生成语言菜单
    const langMenu = (
        <Menu theme={theme} onClick={(val) => changeLang(val.key)}>
            {languageList.map(lang => {
                return <Menu.Item key={lang.name || ''}>{lang.label}</Menu.Item>
            })}
        </Menu>
    );

    //界面设置菜单
    const themeMenu = (
        <>
            {/* <Menu.Item key="dark" onClick={(val) => changeTheme(val.key)}><span className="x-theme-style x-dark-color"></span>{t('暗色主题')}</Menu.Item>
            <Menu.Item key="light" onClick={(val) => changeTheme(val.key)}><span className="x-theme-style x-light-color"></span>{t('亮色主题')}</Menu.Item> */}
            <Menu.Item key="small" onClick={(val) => changeTheme(val.key)}><span className="x-theme-style x-size-s">M</span>{t('尺寸：小')}</Menu.Item>
            <Menu.Item key="middle" onClick={(val) => changeTheme(val.key)} ><span className="x-theme-style x-size-m">M</span>{t('尺寸：中')}</Menu.Item>
            <Menu.Item key="large" onClick={(val) => changeTheme(val.key)} ><span className="x-theme-style x-size-l">M</span>{t('尺寸：大')}</Menu.Item>
            {/* <Menu.Item key="horizontal" onClick={(val) => changeTheme(val.key)} icon={<PicCenterOutlined />} >{t('上下布局')}</Menu.Item>
            <Menu.Item key="inline" onClick={(val) => changeTheme(val.key)} icon={<PicRightOutlined />}>{t('左右布局')}</Menu.Item> */}
            {
                <Menu.Item key="hidden" onClick={(val) => changeTheme(val.key)} icon={hidden?<MenuUnfoldOutlined /> : <MenuFoldOutlined/>}>{hidden?'显示菜单':'隐藏菜单'}</Menu.Item>
            }
        </>
    );
    
    //个人信息菜单
    const userMenu = (
        <>
            <Menu.Item key="show-user-label" icon={<UserOutlined />}>{user.fullname}({user.roleLable})</Menu.Item>
            <Menu.Item key="myinfo" onClick={viewUserInfo} icon={<InfoCircleOutlined />}>{t('个人信息')}</Menu.Item>
            <Menu.Item key="password" onClick={cnagePwd} icon={<SecurityScanOutlined />} >{t('修改密码')}</Menu.Item>
            <Menu.Item key="mysetting" icon={<SettingOutlined />}>{t('设置')}</Menu.Item>
            <Menu.Item key="logout" onClick={() => logout()} icon={<LogoutOutlined />}>{t('退出登录')}</Menu.Item>
        </>
    )

    const navMenu = mode=='inline'?(
        <>
            <div className={["logo", collapsed?'logo-collapse':''].join(" ")}>
                <Logo />{t('昶禹國際物流')}
            </div>
            <SideMenu theme={theme} mode="inline"></SideMenu>
        </>
    )
    :(
        <SideMenu theme={theme} mode="horizontal" menuNode={
            <>
                <SubMenu key="web-setting" icon={theme=='dark'? (<SkinFilled />): (<SkinOutlined />)} title="设置">
                    {themeMenu}
                </SubMenu>
                <SubMenu key="lang-item" title={<span><span className="x-lang-txt lang-h">{langName}</span>{mode=='horizontal'?t('语言'):''}</span>}>
                    {languageList.map(lang => {
                        return <Menu.Item key={lang.name || ''} onClick={(val) => changeLang(val.key)}>{lang.label}</Menu.Item>
                    })}
                </SubMenu>
                <SubMenu key="userinfo" icon={<UserOutlined />} title={user.fullname}>
                    {userMenu}
                </SubMenu>
            </>
        }></SideMenu>
    );

    return (
        <AntdLayout style={{ minHeight: '100vh',overflow: 'auto' }}>
            {mode=='inline' && <Sider collapsible collapsed={collapsed} theme={theme}
                                        onCollapse={(collapsed, type)=>changeCollapse(collapsed, type)} 
                                        style={{boxShadow: "5px 0px 5px rgba(110,110,110,0.1)", display: hidden?'none':'block'}}
                                        >
                                    {navMenu}
                                </Sider>
            }
            <AntdLayout className="site-layout" >
                <Header className={`x-header x-header-${theme} x-header-${mode}`} >
                    {
                        mode == 'inline'?(
                            <Row className="x-header-row">
                                <Col span={12}></Col>
                                <Col span={12} >
                                    <div className="x-header-right">
                                        <Dropdown overlay={
                                                <Menu key="userinfo" theme={theme}>
                                                    {userMenu}
                                                </Menu>
                                            }>
                                            <Button type="dashed" danger size='small' icon={<UserOutlined />} ghost>{user.fullname}</Button>
                                        </Dropdown>
                                        <Dropdown overlay={langMenu}>
                                            <Button type="link"  size={size}>
                                                <span className="x-lang-txt">{langName}</span>
                                            </Button>
                                        </Dropdown>
                                        <Dropdown overlay={
                                            <Menu theme={theme}>
                                                {themeMenu}
                                            </Menu>
                                        }>
                                            <Button  type="link" size={size} icon={theme=='dark'? (<SkinFilled />): (<SkinOutlined />)}>
                                            </Button>
                                        </Dropdown>
                                    </div>
                                </Col>
                            </Row>
                        ):(navMenu)
                    }
                </Header>
                <Content style={{width:'100%', overflow: 'auto'}}>
                    {/* <Breadcrumb style={{ margin: '16px 0' }}>
                        <Breadcrumb.Item> {t('User')}</Breadcrumb.Item>
                    </Breadcrumb> */}
                    <div className="site-layout-background" style={{minWidth: '300px', minHeight: 360 }}>
                        {<MyContent />}
                    </div>
                </Content>
                <Footer inline="inline"></Footer>
                {pwdVisible && <ChangePassword />}
            </AntdLayout>
        </AntdLayout>
    );

};