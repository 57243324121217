export interface UserType {
    id?: number | null;
    username?: string | null; 
    fullname?: string | null;
    password?: string | null;
    repassword?: string | null;
    gender?: string | null;
    roleName?: string | null;
    roleId?: number | null;
    disabled?: number | null;
    type?: number | null;
    tel?:string| null;
    hmNo?: string | null;
    senderName?: string | null;
}

export const defaultUser : UserType = {
    id: null,
    username: null,
    password: null,
    repassword: null,
    fullname: null,
    gender: '保密',
    roleName: null,
    roleId: null,
    disabled: 0,
    type: null,
    tel: null,
    hmNo: null,
    senderName: null
}

export const Disables = [
    {label: "正常", value: 0, color: 'green'},
    {label: '禁用', value: 1, color: 'magenta'},
]

export const UserTypes = [
    {label: "管理员", value: 0, color: '#f50'},
    {label: '公司', value: 1, color: '#2db7f5'},
    {label: '个人', value: 2, color: '#87d068'},
]

export interface UserEditType{
      /**
     * 1: 是否需要更新
     */
       onClose: (updateOk?: boolean)=>void;
       user: UserType;
       /**
        * 1: 新增，
        * 2: 编辑
        */
       type: 1 | 2;
    
}

export interface UserGrantType{
    /**
     * 1: 是否需要更新
     */
    onClose?: (updateOk?: boolean)=>void;
    id?: number;  
}

export const types = [
    {label: '7-11', value: '7-11', color: '#108ee9'},
    {label: '全家', value: '全家', color: '#2db7f5'},
    {label: 'OK', value: 'OK', color: '#2db7f5'},
    {label: '莱尔富', value: '莱尔富', color: '#2db7f5'},
]

export interface CodeType {
    id?:number | null,
    type?: string | null, 
    startCode?: number | null,
    endCode?: number | null,
}