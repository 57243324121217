import { api } from "../../config/api";
import { useState } from "react";
import { useTranslation, TableType, useTable} from '../../hooks';
import {message} from 'antd';
import { SearchItemProp,SearchForm, companies,Modal } from '../../components';
import { ShopType } from "./ExpressType";

export const ChoseShop: React.FC<ShopType> = ({onClose, onOk}) => {
    const t = useTranslation();
    const [enable, setEnable] = useState(true);

    const tableProp: TableType = {
        rowKey: "id",
        pagenation: true,
        url: api.address.list,
        scroll: {y:500},
        columns: [
            {title: t("ID"), key: "id", width:120, sorter: true, type: 'radio', fixed: 'left'},
            {title: t("厂商"), width:120, key: "type", sorter: true, filter: 'select'},
            {title: t("门市编号"),  width:120, key: "code", sorter: true, filter: 'search'},
            {title: t("店名"),  width:150, key: "name", sorter: true, filter: 'search'},
            {title: t("店址"),  width:180, key: "address", sorter: true, filter: 'search', tip: true},
        ] 
    }

    const handleClose = () => {
        setEnable(false);
        setTimeout(() => {
            onClose(false);
        }, 500);
    }

    const handleChose = () =>{
        let selecteds = table.getSelectDatas();
        if(selecteds.length===0){
            message.warning(t("请选中一条数据后再进行操作"));
            return;
        }
        setEnable(false);
        setTimeout(() => {
            onOk(selecteds[0]);
        }, 500);
    }

    const table = useTable(tableProp);

    const tableHtml = table.TableNode();

    const onFinish = (values) => {
        table.remoteSearch(values);
    }

    const searchItems: SearchItemProp[] = [
        {label: '厂商',  name: "company", type: {
            options: companies
        }},
        {label: '门市编号', name:'code', type: 'input'},
        {label: '门市名', name:'name', type: 'input'},
    ]

    return (
        <div>
            <Modal visible={enable}
                title={'选择店铺'}
                width={900}
                onOk={()=>{handleChose()}}
                onCancel={()=>handleClose()}
                afterClose={()=>handleClose()}
            >
            <SearchForm searchItems={searchItems} onSearch={onFinish}/>
            {tableHtml}
            </Modal>
        </div>    
    )
}