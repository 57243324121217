import { useSelector, useTranslation } from "../../hooks";

import {Space, Input,InputNumber, Row, Col, Form, DatePicker,
    Select
} from 'antd';
import moment from "moment";
import { useEffect, useState } from 'react';
import { AuthButton as Button } from '../../components';

import { SearchOutlined, ReloadOutlined,
    DoubleLeftOutlined
} from '@ant-design/icons';

export interface SelectItem {
    options: {label: string, value: any}[]
}

export interface SearchItemProp {
    label: string,  //显示名 
    name: string,   //对象的key
    defultValue?: any, //对象的默认值
    type: 'input' | 'number' | SelectItem | 'datepicker' | 'datetimepicker' | 'textArea',
    format?: string,  //YYYY-MM-DD, YYYY-MM-DD HH:mm:ss 如果是datepicker|datetimepicker这个值是必须的。
}
const { TextArea } = Input;
export interface SearchFormProp {
    searchItems: SearchItemProp[];
    onSearch: (values: Object) => void;
}

const buildFormItem = (searchItem: SearchItemProp, t:(key: string)=>string) => {

    if(searchItem.type == 'input'){
        return <Col key={searchItem.name} className="query-col" sm={24} md={12} lg={8} xl={6}>
            <Form.Item 
                label={t(searchItem.label)}
                name={searchItem.name}
                initialValue={searchItem.defultValue}
            >
                <Input allowClear/>
            </Form.Item>
        </Col>
    }else if(searchItem.type == 'textArea'){
        return <Col key={searchItem.name} className="query-col" sm={24} md={12} lg={8} xl={6}>
            <Form.Item 
                label={t(searchItem.label)}
                name={searchItem.name}
                initialValue={searchItem.defultValue}
            >
                <TextArea rows={2} allowClear/>
            </Form.Item>
        </Col>
    }else if(searchItem.type == 'number'){
        return <Col key={searchItem.name} className="query-col" sm={24} md={12} lg={8} xl={6}>
            <Form.Item 
                label={t(searchItem.label)}
                name={searchItem.name}
                initialValue={searchItem.defultValue}
            >
                <InputNumber />
            </Form.Item>
        </Col>
    }else if(searchItem.type == 'datepicker' || searchItem.type == 'datetimepicker'){
        if(searchItem.defultValue && searchItem.defultValue instanceof Array && searchItem.defultValue.length>1){
            return (
                <Col key={searchItem.name} className="query-col" sm={24} md={24} lg={16} xl={12}>
                    <Form.Item labelCol={{span: 4}}
                        label={t(searchItem.label)}
                        name={searchItem.name}
                        initialValue={[moment(searchItem.defultValue[0], searchItem.format),
                                    moment(searchItem.defultValue[1], searchItem.format),
                                ]}
                    >
                        {
                            searchItem.type == 'datepicker'?
                            (<DatePicker.RangePicker format={searchItem.format}/>)
                            :(<DatePicker.RangePicker showTime={{ format: 'HH:mm' }} format={searchItem.format}/>)
                        }
                    </Form.Item>
                </Col>
            )
        }else{
            return (
                <Col key={searchItem.name} className="query-col" sm={24} md={24} lg={16} xl={12}>
                    <Form.Item labelCol={{span: 4}}
                        label={t(searchItem.label)}
                        name={searchItem.name}
                    >
                        {
                            searchItem.type == 'datepicker'?
                            (<DatePicker.RangePicker format={searchItem.format} />)
                            :(<DatePicker.RangePicker showTime={{ format: 'HH:mm' }} format={searchItem.format}/>)
                        }
                    </Form.Item>
                </Col>
            )
        }
    }else {
        let item = searchItem.type as SelectItem;
        return (
            <Col key={searchItem.name} className="query-col" sm={24} md={12} lg={8} xl={6}>
                <Form.Item 
                    label={t(searchItem.label)}
                    name={searchItem.name}
                >
                    <Select allowClear style={{textAlign: 'left'}}
                    >
                        {item.options.map(s => (
                            <Select.Option value={s.value} key={s.value}>{t(s.label)}</Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            </Col>
        )
    }
}

export const SearchForm: React.FC<SearchFormProp> = ({searchItems, onSearch}) =>{

    const t = useTranslation();
    const setting = useSelector(state => state.setting);
    const [form] = Form.useForm();
    const [moreQuery, setMoreQuery] = useState(false);
    const layoutInfo = useSelector(state => state.layoutInfo);
    const [rowLength, setRowLength] = useState(4);
    const [style, setStyle] = useState<any>({
        textAlign: "center"
    });

    useEffect(()=>{

        if(layoutInfo.width<=768){
            setRowLength(1);
        }else if(layoutInfo.width<=992){
            setRowLength(2);
        }else if(layoutInfo.width<=1200){
            setRowLength(3)
        }else{
            setRowLength(4);
        }

    }, [layoutInfo.width]);

    useEffect(()=>{
        let style={
            textAlign: "right"
        }
        if(moreQuery){
            style['flex'] = '0 0 100%';
            style['maxWidth'] = '100%';
        }

        setStyle(style);

    }, 
    // eslint-disable-next-line 
    [moreQuery])

    const onFinish = (values) => {
        let q: any = null;
        let keys = Object.keys(values).filter(k => values[k]?true: false);
        if(keys && keys.length>0){
            q = {};
            keys.forEach(k => {
                let value = values[k];
                if(value instanceof Array && value.length>1){
                    let temp = value[0];
                    if(temp instanceof moment){
                        let item = searchItems.find(i => i.name == k);
                        let pattern = "YYYY-MM-DD";
                        if(item && item.format){
                            pattern = item.format;
                        }
                        q['start'+k] = value[0].format(pattern)
                        q['end'+k] = value[1].format(pattern)
                    }else{
                        q[k] = value
                    }
                }else{
                    q[k] = value;
                }
            });
        }
        
        if(onSearch){
            onSearch(q);
        }
    }

    let formItems: any = [];
    let currLength = 0;
    for(let i=0; i<searchItems.length; i++){
        let searchItem = searchItems[i];

        if(searchItem.type == 'datepicker' || searchItem.type == 'datetimepicker'){
            currLength += 2
        }else{
            currLength+=1;
        }

        if(moreQuery){
            let item = buildFormItem(searchItem, t);
            formItems.push(item);       
        }else if(i==0 || currLength<rowLength){
            let item = buildFormItem(searchItem, t);
            formItems.push(item); 
        }
    }

   
    const formHtml = (
        <div className='search-form-wrap' >
            <Form form={form} labelCol={{span: 8}} wrapperCol={{span: 16}} 
                size={setting.size}
                onFinish={onFinish}
                labelAlign="right"
            >   
                <Row >
                        {formItems}
                        <Col className="query-col" sm={24} md={12} lg={8} xl={6} style={style}>
                            <Space>
                                {searchItems.length !=rowLength-1 && 
                                    <Button icon={<DoubleLeftOutlined type="primary" 
                                    style={{transform: moreQuery? 'rotate(90deg)':'rotate(-90deg)'}}/>} 
                                    onClick={()=> setMoreQuery(!moreQuery)}
                                    >
                                        {t('高级')}
                                    </Button>
                                }
                                <Button htmlType="submit" icon={<SearchOutlined />} type="primary">{t('搜索')}</Button>
                                {/* <Button onClick={() => {form.resetFields();}} icon={<ReloadOutlined />} type="primary">{t('重置')}</Button> */}
                            </Space>
                        </Col>
                </Row>
            </Form>
        </div>
    )

    return formHtml
}

