import axios, {AxiosInstance,AxiosRequestConfig,AxiosResponse} from "axios";
import { message, Modal } from 'antd';
import { startLoading, endLoading } from "../components/loading";
import { useTranslation } from "./useTranslation";
import { useDispatch } from "react-redux";
import { userSlice } from "../redux/global";

// axios.defaults.headers.post['Content-Type'] = "application/json; charset=utf-8";
// axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

export interface XReqponse<T> extends AxiosResponse<T>{
    msg?: string,
}

export interface RequestInstance extends AxiosInstance{
    get<T = any, R = XReqponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R>;
    post<T = any, R = XReqponse<T>>(url: string, data?: any, config?: AxiosRequestConfig): Promise<R>;
}

// export interface RequestType extends RequestInstance{
//     json: RequestInstance,
// }

const jsonHeaders = {
    "Content-Type" : "application/json; charset=utf-8",
    "X-Requested-With": "XMLHttpRequest",
}

const wwwHeaders = {
    "Content-Type" : "application/x-www-form-urlencoded",
    "X-Requested-With": "XMLHttpRequest",
};

const jsonConfig:AxiosRequestConfig = {
    // baseURL: process.env.baseURL || process.env.apiUrl || ""
    timeout: 180 * 1000, // Timeout
    withCredentials: true, // Check cross-site Access-Control
    headers: jsonHeaders
};

const wwwConfig:AxiosRequestConfig = {
    timeout: 180 * 1000, // Timeout
    withCredentials: true, 
    headers: wwwHeaders
}

export const useRequest = () => {
    
    const t = useTranslation();
    const dispatch = useDispatch();
    
    //json请求的拦截
    const jsonRequestInterceptor = (config) => {
        // console.log("使用json请求拦截器", config);
        startLoading();
        return config;
    }
    
    //json普通请求拦截
    const wwwRequestInterceptor = (config) => {
        // console.log("使用普通请求拦截器", config);
        startLoading();
        return config;
    }  
    
    //请求错误的拦截
    const requestErrHandler = (err) => {
        endLoading();
        message.error(err);
        return Promise.reject(err);
    }
    
    //json请求错误的拦截
    const jsonRequestErrHandler = (err) => {
        endLoading();
        message.error(err);
        return Promise.reject(err);
    }
    
    //未正确响应的处理方法
    const responseErrHandler = (error) =>{
        endLoading();
        try{
            const { status } = error.response;
            if(status!=200){
                message.error(t("网络请求错误，请检查网络连接"));
            }else{
                if(error.response){
                    message.error(error.response.data);
                }else{
                    message.error(t("未知错误"));
                }
            }
            
        }catch(err){
            message.error(error);
        }
        return Promise.reject(error);
    }
    
    //json未正确响应的处理方法
    const jsonResponseErrHandler = (error) =>{
        endLoading();
        try{
            const { status } = error.response;
            if(status!=200){
                message.error(t("网络请求错误，请检查网络连接"));
            }else{
                if(error.response){
                    message.error(error.response.data);
                }else{
                    message.error(t("未知错误"));
                }
            }
            
        }catch(err){
            message.error(error);
        }
        return Promise.reject(error);
    }
    
    //返回结果请求拦截
    const responseInterceptor = (response) => {
        endLoading();
        
        if(!response.data.status){
            if(response.data.code == '1'){
                Modal.error({
                    content: t(response.data.msg),
                    okText: t("去登录"),
                    onOk: ()=>{
                        // let state = store.store.getState();
                        // user.id = null;
                        // user.username = null;
                        // user.roleId = null;
                        window.localStorage.removeItem("persist:root");
                        
                        window.location.href="/login"; 
                    }
                    // ,
                    // afterClose: ()=>{
                        //     window.location.href="/";
                        // }
                });
            }
        }
        return response.data;
    }
        
    //拦截器执行了两次，怀疑是注册了同一个，这里把重复的代码写两次
    const responseJsonInterceptor = (response) => {
        endLoading();
        
        if(!response.data.status){
            if(response.data.code == '1'){
                Modal.error({
                    content: t(response.data.msg),
                    okText: t("去登录"),
                    onOk: ()=>{
                        dispatch(userSlice.actions.logOut());
                        // window.localStorage.removeItem("persist:root");
                        setTimeout(()=>{
                            window.location.href="/login";
                        }, 1000)
                    }
                    // ,
                    // afterClose: ()=>{
                        //     window.location.href="/";
                        // }
                });
            }
        }
            
        return response.data;
            
    } 
    const wwwRequest: RequestInstance = axios.create(wwwConfig);
    const jsonRequest: RequestInstance = axios.create(jsonConfig);
    
    jsonRequest.interceptors.request.use(jsonRequestInterceptor, jsonRequestErrHandler);
    jsonRequest.interceptors.response.use(responseJsonInterceptor, jsonResponseErrHandler);
    wwwRequest.interceptors.request.use(wwwRequestInterceptor, requestErrHandler);
    wwwRequest.interceptors.response.use(responseInterceptor, responseErrHandler);
    
    // const request: RequestType = {...wwwRequest, json: jsonRequest} as RequestType;
    return {request: wwwRequest, json: jsonRequest};
}



