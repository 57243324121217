import { MenuEditType, MenuType } from "./MenuType";
import { Modal } from '../../components'
import { useEffect, useState } from "react";
import {Form, Input,Select, message,TreeSelect } from 'antd';
import { useTranslation } from "../../hooks/useTranslation";
import { useSelector } from "../../hooks/useSelector";
import { useRequest } from "../../hooks/useRequest";
import { Icon } from "../../components";
import icons from "../../components/iconfont/iconfont.json";
import { api } from "../../config/api";

interface MenuTreeType{
    value: any,
    title: any,
    pId: any,
    children: MenuTreeType[],
}

export const MenuEdit : React.FC<MenuEditType> = ({type, menu, onClose}) =>{

    // const [parentId1, setParentId1] = useState(parentId || 0);
    const [enable, setEnable] = useState(true);
    const t = useTranslation();
    const [form] = Form.useForm();
    const setting = useSelector(state => state.setting);
    const {json} = useRequest();
    const [menus, setMenus] = useState<MenuTreeType[]>([]);

    const handleClose = () => {
        setEnable(false);
        setTimeout(() => {
            onClose();
        }, 500);
    }

    const onFinishFailed = () => {
        message.warning({
            content: t('请确认数据正确后在提交'),
        });
    };

    useEffect(()=>{
        let getAll = async () => { 
            let result = await json.post(api.menu.toggle+"?resultType=1");
            if(result.status){
                const rebuild = (menus): MenuTreeType[] => {

                    let datas: MenuTreeType[] = [];
                    menus.forEach(m => {

                        //过滤掉自己，
                        if(menu.id && menu.id !== m.id){
                            let treeNode: MenuTreeType = {
                                value: m.id,
                                title: m.label,
                                pId: m.parentId,
                                children: [],
                            };
                            datas.push(treeNode);
                            if(m.children && m.children.length>0){
                                treeNode.children.push(...rebuild(m.children));
                            }
                        }
                    });
                    return datas;
                }

                setMenus([{value: 0, pId: 0, title: t( "根菜单"), children: rebuild(result.data)}]);
            }
        }
        getAll();
    }, 
    // eslint-disable-next-line
    []);

    const onFinish = (values: MenuType) => {
        // console.log(values);
        let data: MenuType = {...menu, ...values}
        // console.log(data);
        let url = data.id?api.menu.update:api.menu.add;
        
        let update = async() => {
            let result = await json.post(url, data);
            message.success(t(result.msg));
            if(result.status){
                setEnable(false);
                onClose(type==1?3:(menu.parentId == values.parentId?(menu.parentId==0?1:2):3));
            }
        }

        update();
    };
    
    const formHtml = (
        <div className="edit-form-wrap">
            <Form<MenuType> form={form} labelCol={{span: 6}} wrapperCol={{span:18}} 
                size={setting.size}
                onFinishFailed={onFinishFailed}
                onFinish={onFinish}
            >   
                <Form.Item
                    label={t('上级菜单')}
                    name="parentId"
                    initialValue={menu.parentId}
                    rules={[{ required: true, message:  t("上级菜单不能为空")}]}
                > 
                    <TreeSelect
                        allowClear size={setting.size}
                        style={{ width: '100%' }}
                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                        treeData={menus}
                        placeholder={t('请选择上级菜单')}
                        treeDefaultExpandAll
                    />
                </Form.Item>
                <Form.Item
                    label={t('菜单名')}
                    name="label"
                    initialValue={menu.label}
                    rules={[{ required: true, message:  t("菜单名不能为空")}]}
                >
                    <Input allowClear />
                </Form.Item>
                <Form.Item
                    label={t('菜单图标')}
                    name="icon"
                    initialValue={menu.icon}
                    rules={[{ required: true, message:  t("菜单图标不能为空")}]}
                > 
                    <Select allowClear>
                        {icons.glyphs.map(i => (
                            <Select.Option value={"icon-"+i.font_class} key={i.font_class}>
                                <Icon icon={"icon-"+i.font_class}></Icon>
                                 - {i.font_class}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    label={t('URL')}
                    name="url"
                    initialValue={menu.url}
                    rules={[{ required: true, message:  t("URL不能为空")}]}
                >
                    <Input allowClear />
                </Form.Item>
                <Form.Item
                    label={t('显示顺序')}
                    name="seq"
                    initialValue={menu.seq || 0}
                >
                    <Input placeholder={t("默认0，按从小到大的顺序排列")} allowClear />
                </Form.Item>
            </Form>
        </div>
    );
                            
    let title = type == 1 ? t('新增菜单'):t('编辑菜单')
    return (
        <Modal visible={enable}
            title={title}
            onOk={()=>{form.submit()}}
            onCancel={()=>handleClose()}
            afterClose={()=>handleClose()}
        >
            {formHtml}
        </Modal>
    )
} 