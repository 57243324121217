
import {Button, ButtonProps} from 'antd';
import {useSelector, useAuth} from '../../hooks'

interface ButtonType extends ButtonProps{
    permission?: string | string[]
}

export const AuthButton: React.FC<ButtonType> = (props) => {
    const setting = useSelector(state => state.setting);
    const {has} = useAuth();

    let auth = props.permission? has(props.permission) : true ;

    let ps = {...props};
    if(!ps?.size){
        ps.size = setting.size;
    }

    return auth?(
            <Button  {...ps}>{props.children}</Button>
        ):(<></>)
}
