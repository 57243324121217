import './Upload.less';
import { useState } from "react";
import {Space, message, Row, Col,Upload,Table} from 'antd';
import { UploadOutlined,VerticalAlignBottomOutlined } from '@ant-design/icons';
import { api } from "../../config/api";
import { useSelector,useTranslation,useExport} from '../../hooks';
import { useRequest } from "../../hooks/useRequest";
import { AuthButton as Button } from '../../components';
import XLSX from 'xlsx';

export const UploadHm: React.FC = () => {
    const t = useTranslation();
    const layoutInfo = useSelector(state => state.layoutInfo);
    const setting = useSelector(state => state.setting);
    const [exps,setExps] = useState<any[]>([]);
    const {json} = useRequest();
    const exportKit = useExport();

    const columns = [
        {
            title: '廠商訂單編號',
            dataIndex: 'consignCode',
            key: 'consignCode',
        },
        {
            title: '订单编号',
            dataIndex: 'orderCode',
            key: 'orderCode',
        },
        {
            title: '物流厂商',
            dataIndex: 'company',
            key: 'company',
        },
        {
            title: '商品名稱',
            dataIndex: 'expressName',
            key: 'expressName',
        },
        {
            title: '訂單金額',
            dataIndex: 'orderAmount',
            key: 'orderAmount',
        },
        {
            title: '純配送 / 貨到付款',
            dataIndex: 'deliverType',
            key: 'deliverType',
            render: text => text==3?'纯配送': text==1?'货到付款' : '未识别',
        },
        {
            title: '收件人姓名',
            dataIndex: 'receiverName',
            key: 'receiverName',
        },
        {
            title: '收件人手機',
            dataIndex: 'receiverTel',
            key: 'receiverTel',
        },
        {
            title: '收件門市地址',
            dataIndex: 'receiverShopAddress',
            key: 'receiverShopAddress',
        },
        {
            title: '备注',
            dataIndex: 'remark',
            key: 'remark',
        },
        {
            title: '上传结果',
            dataIndex: 'errcode',
            key: 'errcode',
        },
        {
            title: '原因',
            dataIndex: 'errmsg',
            key: 'errmsg',
        },
    ];

    const getUploadFile = (info) =>{

        // 获取上传的文件对象
        const file = info.file;
        // 通过FileReader对象读取文件
        const fileReader = new FileReader();
        // 以二进制方式打开文件
        fileReader.readAsBinaryString(file);

        fileReader.onload = event => {
            try {
                const result = event.target;
                // 以二进制流方式读取得到整份excel表格对象
                const workbook = XLSX.read(result?.result, { type: 'binary' });
                    // 存储获取到的数据
                let data = [];
                // 遍历每张工作表进行读取（这里默认只读取第一张表）
                for (const sheet in workbook.Sheets) {
                    // esline-disable-next-line
                    if (workbook.Sheets.hasOwnProperty(sheet)) {
                        // 利用 sheet_to_json 方法将 excel 转成 json 数据
                        data = data.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]));
                        break; // 如果只取第一张表，就取消注释这行
                    }
                }
                // 最终获取到并且格式化后的 json 数据
                if(!data || data.length == 0){
                    message.success(t('无数据！'))
                    return;
                }
                message.success(t('上传成功!'))

                // 整理数据，提交到后台
                let params:any[] = [];
                data.forEach(e => {
                    let exp = {
                        consignCode: e['廠商訂單編號'],
                        expressName: e['商品名稱'],
                        orderAmount: e['訂單金額'],
                        deliverType: ('純配送'.indexOf(e['純配送 / 貨到付款'])>-1)?3: ('貨到付款'.indexOf(e['純配送 / 貨到付款'])>-1) ?1 : null,
                        receiverName: e['收件人姓名'],
                        receiverTel: e['收件人手機'],
                        receiverShopAddress: e['收件門市地址'],
                        remark:e['备注'],
                    }
                    params.push(exp);
                })
                doUpload(params);

            } catch (e) {
                // 这里可以抛出文件类型错误不正确的相关提示
                message.error('文件类型不正确！');
            }
        };
    }

    const doUpload = (params) => {
        let url = api.express.creates;
        let update = async() => {
            let result = await json.post(url+'?type=2', params);
            message.success(t(result.msg));
            setExps(result.data);
        }
        update();
    }

    const handleDownload = () =>{
        window.open(api.upload.downloadHm,"_blank")
    }

    const exportCurrPage = () => {
        
        //  exportKit
        let table = document.querySelector(".xm-table-exp");
        if(table){
            exportKit.byTable(table, t("黑猫导出")+new Date().getTime()+".xlsx");
        }

    }

    return (
        <div>
            <div className={`content-wrap ${layoutInfo.isMin?'content-wrap-min': ''}`} style={{marginTop: '10px'}}>
                <Row className="ctrl-wrap">
                    <Col span={24}>
                        <Space size={setting.size} wrap>
                            <Upload
                                customRequest={getUploadFile}
                                withCredentials={true}
                                fileList={[]}
                            >
                                <Button icon={<UploadOutlined />} type="primary">上传文件</Button>
                            </Upload>
                            <Button onClick={handleDownload} icon={<VerticalAlignBottomOutlined />} type="primary">{t('下载黑猫模板')}</Button>
                            <Button onClick={exportCurrPage} icon={<VerticalAlignBottomOutlined />} type="primary">{t('导出当前页')}</Button>
                        </Space>
                    </Col>
                </Row>
                <Table  scroll={{x: '1200px'}}  columns={columns} dataSource={exps} />
            </div>
        </div>    
    )
}