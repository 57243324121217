import { ExpBackStatusesUpdateType, ExpressBackStatuses } from "./ExpressBackType";
import { Modal } from '../../components';
import { api } from "../../config/api";
import { useTranslation,useSelector,useRequest } from "../../hooks/";
import {Form,message, Input,Row ,Col,Select} from 'antd';
import { useState } from "react";

export const UpdateStatuses: React.FC<ExpBackStatusesUpdateType> = ({onClose}) => {

    const [enable, setEnable] = useState(true);
    const t = useTranslation();
    const [form] = Form.useForm();
    const setting = useSelector(state => state.setting);
    const {request} = useRequest();
    const { TextArea } = Input;

    const handleClose = () => {
        setEnable(false);
        setTimeout(() => {
            onClose(false);
        }, 500);
    }

    const onFinishFailed = () => {
        message.warning({
            content: t('请确认数据正确后在提交'),
        });
    };

    const onFinish = (values : any) => {

        let data = values.codes;
        let status = values.status;

        let cs = data.split(/\s+/);
        
        let css : any  = [];
        for(let i=0; i<cs.length; i++){
            if(cs[i]) css.push(cs[i]);
        }

        let codes = css.join(",");

        let url = api.expBack.status;
        let update = async() => {
            let result = await request.post(url, "codes="+codes+"&status="+status+"&type=1");
            if(result.status){
                message.success(t(result.msg));
                handleClose();
            }else{
                message.warning(t(result.msg));
            }
        }
        update();
    };

    const formHtml = (
        <div className="edit-form-wrap">
            <Form<any> form={form} labelCol={{span: 6}} wrapperCol={{span:18}} 
                size={setting.size}
                onFinishFailed={onFinishFailed}
                onFinish={onFinish}
                labelAlign="right"
            >   
                <Row>
                    <Col sm={24} md={12}  lg={20}>
                        <Form.Item
                            label={t('单号')}
                            name="codes"
                            rules={[{ required: true, message:  t("请输入单号")}]}
                        >
                            <TextArea allowClear rows={10} placeholder={t('请输入单号')}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col sm={24} md={12}  lg={20}>
                        <Form.Item
                            label={t('状态')}
                            name="status"
                            rules={[{ required: true, message:  t("状态不能为空")}]}
                            >
                                <Select allowClear>
                                    {ExpressBackStatuses.map(s => 
                                        (<Select.Option key={s.value} value={s.value}>{t(s.label)}</Select.Option>)
                                    )}
                                </Select>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </div>
    );

    let title =t('更新退件状态')
        
    return (
        <Modal visible={enable}
            title={title}
            onOk={()=>{form.submit()}}
            onCancel={()=>handleClose()}
            afterClose={()=>handleClose()}
        >
            {formHtml}
        </Modal>
    )

}