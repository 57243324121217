import {Button, DropDownProps, Dropdown} from 'antd';
import {useSelector, useAuth} from '../../hooks'
import { DownOutlined } from '@ant-design/icons';

interface DropdownType extends DropDownProps{
    permission?: string | string[],
    btnTxt: string,
    btnIcon?: JSX.Element
}

export const AuthDropdown: React.FC<DropdownType> = (props) => {
    const setting = useSelector(state => state.setting);
    const {has} = useAuth();

    let auth = props.permission? has(props.permission) : true ;

    return auth?(
            <Dropdown overlay={props.overlay}>
                <Button size={setting.size} icon={props.btnIcon}>
                    {props.btnTxt} <DownOutlined />
                </Button>
            </Dropdown>
        ):(<></>);
}
