import { useTranslation as useI18nTranslation} from "react-i18next";

export const useTranslation = () => {
    let {t} = useI18nTranslation();
    const useTrans = (key: string | null | undefined) : string => {
        if(!key) return "";
        
        //如果key有换行，说明是多个
        if(key.indexOf("\n")>-1){
            let keys = key.split(/\n+/);
            let vals:string[] = [];
            keys.forEach(k => {
                let val = doTrans(k);
                if(val){
                    vals.push(val);
                }
            });
            if(vals.length>0){
                return vals.join("\n");
            }
        }
        return doTrans(key);

        
    }

    const doTrans = (key) => {
        if(!key) return "";
        
        let reg = /\[(.*?)\]/g;
        if(reg.test(key)){
            let arr:string[] = [];
            reg =  /\[(.*?)\]/g;
            let matched = reg.exec(key);
            while ( matched ) {
                let val = matched[0]+'';
                arr.push(val);
                matched = reg.exec(key);
            }

            let k = key.replace(reg, '%s');
            
            let msg = t(k);
            arr.forEach(a => {
                msg = msg.replace('%s', a);
            });
            return msg;
        }else{
            return  t(key) as  string;
        }
    }
    return  useTrans;
}