import { useEffect, useState } from "react";

import {Input, Button, message} from 'antd';
import { CheckOutlined, EditOutlined
} from '@ant-design/icons';
import { useSelector, useRequest, useTranslation } from "../../hooks";
import { api } from "../../config/api";


interface ItemEditProp {
    text: string,
    id: number,
    propKey: string
}

export const ItemEdit: React.FC<ItemEditProp> = ({propKey, text, id}) => {
    const setting = useSelector(state => state.setting);
    const [edit, setEdit] = useState(false);
    const [iptValue, setIptValue] = useState(text);
    const {json} = useRequest();
    const t = useTranslation();

    useEffect(()=>{
        setIptValue(text);
    }, [text]);

    const iptChange = (event) => {
        // console.log(event.target.value);
        // console.log(event);
        if(event.keyCode==13){  
            doEnter();
        }else{
            setIptValue(event.target.value);
        }
    }

    const doEnter = () => {
        
        let doUpdate = async () => {

            let p = {id: id};
            // @ts-ignore
            p[propKey] = iptValue;
            console.log(propKey);
            let data = await json.post(api.user.update, p);
            message.warning(t(data.msg))
            if(data.status){
                setEdit(false);
            }
        }

        doUpdate();
    }

    return edit?(
        <Input addonAfter={<span onClick={doEnter} style={{cursor: 'pointer'}}><CheckOutlined /></span>} onKeyUp={iptChange} size={setting.size} defaultValue={iptValue} />
    ):(
        <>
            {iptValue} <Button type="link" icon={<EditOutlined />} onClick={() => setEdit(true)} size={setting.size}></Button>
        </>
    );

}
