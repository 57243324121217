import './Resource.less';

import { Breadcrumb,  Row, Col, Modal, message,Input
} from 'antd';
import { HomeOutlined ,EditOutlined, DeleteOutlined, PlusCircleOutlined,
    SearchOutlined,
} from '@ant-design/icons';

import { useSelector, useTranslation, useTable,TableType, useRequest } from '../../hooks';
import { api } from '../../config/api';
import { AuthButton as Button } from '../../components';
import { useState } from 'react';
import { defaultLang, LangEditType, LangEdit, ResEditType, defaultRes,ResType } from './';
import { ResourceEdit } from './ResourceEdit';

export const Resource: React.FC = () => {

    const t = useTranslation();
    const layoutInfo = useSelector(state => state.layoutInfo);
    const [langVisible, setLangVisible] = useState(false);
    const [langEditObj, setLangEditObj] = useState<LangEditType>();
    const [resVisible, setResVisible] = useState(false);
    const [resEditObj, setResEditObj] = useState<ResEditType>();
    const {json} = useRequest();
    const setting = useSelector(state => state.setting);
    const [query, setQuery] = useState<any>();

    const childrenTableProp: TableType = {
        rowKey: "id",
        pagenation: true,
        url: api.langRes.resources,
        columns: [
            {title: t("语言"), width: 130, key: "langLabel", sorter: true, filter: 'select'},
            {title: t("KEY"), width: 130, key: "name", sorter: true, filter: 'search'},
            {title: t("翻译"), width: 130, key: "msg", sorter: true, filter: 'search'},
            {width: 120, key: "id", fixed: 'right',  align: 'center', title: t('操作'),
                render: (text, row, index) => {
                    return (
                        <>
                            <Button permission="lang:resUpdate" type="link" onClick={(event) => editRes(event, row)} icon={<EditOutlined />}></Button>
                            <Button permission="lang:resDelete" type="link" onClick={(event) => deleteRes(event, row)} danger icon={<DeleteOutlined />}></Button>
                        </>
                    )
                }
            },
        ] 
    }

    const childTable = useTable(childrenTableProp);

    let height = layoutInfo.height - layoutInfo.topHeight - layoutInfo.footerHeight-100;

    const parentTableProp: TableType = {
        rowKey: "id",
        pagenation: false,
        scroll: {y: height},
        clickRow: (event, row) => {
            childTable.remoteSearch({id: row.id});
        },
        url: api.langRes.langs,
        extraData: (data) => {
            return data;
        },
        columns: [
            {title: t("名称"), width: 150, key: "label", render: (text, row, index) => {
                let txt = text+"("+row.name+")"
                return txt
            }},
            {width: 120, key: "id", align: 'center',
                render: (text, row, index) => {
                    return (
                        <>
                            <Button permission="lang:update" type="link" onClick={(event) => editLang(event, row, index)} icon={<EditOutlined />}></Button>
                            <Button permission="lang:delete" type="link" onClick={(event) => deleteLang(event, row)} danger icon={<DeleteOutlined />}></Button>
                        </>
                    )
                },
                title: ()=>(
                    <div style={{textAlign: 'center'}}><Button  type="primary" onClick={addLang} permission={"lang:create"} icon={<PlusCircleOutlined />}>{t('新增')}</Button></div>
                )
            },
        ] 
    }

    const parentTable = useTable(parentTableProp);

    const deleteLang = (event, record) => {
        event.stopPropagation();
        Modal.confirm({
            content: t("确定要删除["+record.label+"]吗？"),
            okText: t('确定'),
            okType: 'danger',
            cancelText: t('取消'),
            onOk: ()=>{
                const fireDelete = async () => {
                    let result = await json.get(api.langRes.deleteLang+"?id="+record.id);
                    if(result.status){
                        message.success(t(result.msg));  
                        parentTable.remoteSearch(null);
                    }else{
                        message.warning(t(result.msg))
                    }
                }
                fireDelete();
            }
        });
    }
    
    const addLang = () => {
        setLangEditObj({
            onClose: handleLangEditClose,
            type: 1,
            lang: {...defaultLang}
        });
        setLangVisible(true);
    }

    const editLang = (event, record, index) => {
        event.stopPropagation();
        setLangEditObj({
            onClose: handleLangEditClose,
            type: 1,
            lang: {...record}
        });
        setLangVisible(true);
    }

    const handleLangEditClose = (updateOk) => {
        if(updateOk){
            parentTable.remoteSearch(null);
        }
        setLangVisible(false);
    }

    const searchInputChange = (event) => {
        let val = event.target.value;
        if(val){
            setQuery({name: val});
        }else{
            setQuery(null);
        }
    }

    const doSerach = (val) => {

        let ps: any= null;
        if(val){
            ps = {name: val}
            setQuery({name: val});
        }else{
            setQuery(null);
        }

        childTable.remoteSearch(ps);
        
    }

    const addRes = (event) => {
        let langs = parentTable.datas;
        let resources : ResType[]  = [];

        langs.forEach(lang => {
            resources.push({
                ...defaultRes,
                languageId: lang.id,
                langLabel: lang.label,
                langName: lang.name,
            });
        });

        setResEditObj({
            onClose: handleResEditClose,
            type: 1,
            resources: resources
        });
        setResVisible(true);
    }

    const handleResEditClose = (updateOk) => {
        if(updateOk){
            childTable.remoteSearch(query);
        }
        setResVisible(false);
    }

    const deleteRes = (event, row) => {
        event.stopPropagation();
        Modal.confirm({
            content: t("确定要删除["+row.name+"]吗？"),
            okText: t('确定'),
            okType: 'danger',
            cancelText: t('取消'),
            onOk: ()=>{
                const fireDelete = async () => {
                    let result = await json.get(api.langRes.deleteResource+"?name="+encodeURIComponent(row.name));
                    if(result.status){
                        message.success(t(result.msg));  
                        childTable.remoteSearch(query);
                    }else{
                        message.warning(t(result.msg))
                    }
                }
                fireDelete();
            }
        });
    }

    const editRes = (event, row) => {
        event.stopPropagation();
        const getResources = async () => {
            let result = await json.get(api.langRes.resourcesByKey+"?key="+encodeURIComponent(row.name));
            if(result.status){
                setResEditObj({
                    onClose: handleResEditClose,
                    type: 1,
                    resources: result.data
                });
                setResVisible(true);
            }else{
                message.warning(t(result.msg))
            }
        }
        getResources();
    }

    return (
        <div>
            <Breadcrumb>
                <Breadcrumb.Item>
                    <HomeOutlined />
                </Breadcrumb.Item>
                <Breadcrumb.Item>{t('菜单管理')}</Breadcrumb.Item>
            </Breadcrumb>

            <Row className="setting-wrap">
                <Col sm={24} md={8} >
                    {parentTable.TableNode()}
                </Col>
                <Col sm={24} md={16} >
                    <div className='table-box' style={{textAlign: 'center'}}>
                        <Input.Search style={{width: `${layoutInfo.isMin?'calc(100% - 30px)': 'calc(100% - 150px)'}`}}
                            allowClear
                            placeholder={t("关键字搜索")}
                            enterButton={t('搜索')}
                            size={setting.size}
                            onChange={searchInputChange}
                            suffix={<SearchOutlined />}
                            onSearch={doSerach}
                        />
                        <Button permission="lang:resCreate" type="primary" 
                            style={{marginTop: `${layoutInfo.isMin? "15px": "0px"}`}}
                            onClick={(event) => addRes(event)} danger 
                            icon={<PlusCircleOutlined />}>{t('新增')}</Button>
                    </div>
                    <div>{childTable.TableNode()}</div>
                </Col>
            </Row>
            {resVisible && <ResourceEdit {...resEditObj}></ResourceEdit>}
            {langVisible && <LangEdit {...langEditObj}></LangEdit>}
        </div>
    )
}