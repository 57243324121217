import axios, {AxiosInstance,AxiosRequestConfig,AxiosResponse} from "axios";
import { message, Modal } from 'antd';
import { startLoading, endLoading } from "../components/loading";
import i18n from "./i18n";

// axios.defaults.headers.post['Content-Type'] = "application/json; charset=utf-8";
// axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
export interface XReqponse<T> extends AxiosResponse<T>{
    msg?: string,
}

export interface RequestInstance extends AxiosInstance{
    get<T = any, R = XReqponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R>;
    post<T = any, R = XReqponse<T>>(url: string, data?: any, config?: AxiosRequestConfig): Promise<R>;
}

let jsonHeaders = {
    "Content-Type" : "application/json; charset=utf-8",
    "X-Requested-With": "XMLHttpRequest",
}

let wwwHeaders = {
    "Content-Type" : "application/x-www-form-urlencoded",
    "X-Requested-With": "XMLHttpRequest",
};

let jsonConfig:AxiosRequestConfig = {
    // baseURL: process.env.baseURL || process.env.apiUrl || ""
    timeout: 180 * 1000, // Timeout
    withCredentials: true, // Check cross-site Access-Control
    headers: jsonHeaders
};

let wwwConfig:AxiosRequestConfig = {
    timeout: 180 * 1000, // Timeout
    withCredentials: true, 
    headers: wwwHeaders
}

const wwwRequest:RequestInstance = axios.create(wwwConfig);
const jsonRequest:RequestInstance = axios.create(jsonConfig);

//json请求的拦截
const jsonRequestInterceptor = (config) => {
    // console.log("使用json请求拦截器", config);
    startLoading();
    return config;
}  

//json普通请求拦截
const wwwRequestInterceptor = (config) => {
    // console.log("使用普通请求拦截器", config);
    startLoading();
    return config;
}  

//请求错误的拦截
const requestErrHandler = (err) => {
    endLoading();
    message.error(err);
    return Promise.reject(err);
}

//请求错误的拦截
const requestJsonErrHandler = (err) => {
    endLoading();
    message.error(err);
    return Promise.reject(err);
}

//未正确响应的处理方法
const responseErrHandler = (error) =>{
    endLoading();
    try{
        const { status } = error.response;
        if(status!=200){
            message.error(i18n.t("网络请求错误，请检查网络连接"));
        }else{
            if(error.response){
                message.error(error.response.data);
            }else{
                message.error(i18n.t("未知错误"));
            }
        }

    }catch(err){
        message.error(error);
    }
    return Promise.reject(error);
}

//未正确响应的处理方法
const responseJsonErrHandler = (error) =>{
    endLoading();
    try{
        const { status } = error.response;
        if(status!=200){
            message.error(i18n.t("网络请求错误，请检查网络连接"));
        }else{
            if(error.response){
                message.error(error.response.data);
            }else{
                message.error(i18n.t("未知错误"));
            }
        }

    }catch(err){
        message.error(error);
    }
    return Promise.reject(error);
}

//返回结果请求拦截
const responseInterceptor = (response) => {
    endLoading();

    if(!response.data.status){
        if(response.data.code == '1'){
            Modal.error({
                content: i18n.t(response.data.msg),
                okText: i18n.t("去登录"),
                onOk: ()=>{
                    // let state = store.store.getState();
                    // user.id = null;
                    // user.username = null;
                    // user.roleId = null;
                    window.localStorage.removeItem("persist:root");

                    window.location.href="/login"; 
                }
                // ,
                // afterClose: ()=>{
                //     window.location.href="/";
                // }
            });
        }
    }

  return response.data;

}

//拦截器执行了两次，怀疑是注册了同一个，这里把重复的代码写两次
const responseJsonInterceptor = (response) => {
    endLoading();

    if(!response.data.status){
        if(response.data.code == '1'){
            Modal.error({
                content: i18n.t(response.data.msg),
                okText: i18n.t("去登录"),
                onOk: ()=>{
                    // let state = store.store.getState();
                    // user.id = null;
                    // user.username = null;
                    // user.roleId = null;
                    window.localStorage.removeItem("persist:root");

                    window.location.href="/login";
                }
                // ,
                // afterClose: ()=>{
                //     window.location.href="/";
                // }
            });
        }
    }

  return response.data;

} 

jsonRequest.interceptors.request.use(jsonRequestInterceptor, requestJsonErrHandler);
jsonRequest.interceptors.response.use(responseJsonInterceptor, responseJsonErrHandler);
wwwRequest.interceptors.request.use(wwwRequestInterceptor, requestErrHandler);
wwwRequest.interceptors.response.use(responseInterceptor, responseErrHandler);

export default {
    request: wwwRequest,
    json: jsonRequest
};

