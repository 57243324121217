import './Upload.less';
import { useState } from "react";
import {Space, message, Row, Col,Upload,Table} from 'antd';
import { UploadOutlined,VerticalAlignBottomOutlined } from '@ant-design/icons';
import { api } from "../../config/api";
import { useSelector,useTranslation,useExport} from '../../hooks';
import { useRequest } from "../../hooks/useRequest";
import { AuthButton as Button } from '../../components';
import XLSX from 'xlsx';

export const UploadUpper: React.FC = () => {
    const t = useTranslation();
    const layoutInfo = useSelector(state => state.layoutInfo);
    const setting = useSelector(state => state.setting);
    const [exps,setExps] = useState<any[]>([]);
    const {json} = useRequest();
    const exportKit = useExport();

    const columns = [
        {
            title: '廠商訂單編號',
            dataIndex: 'code',
            key: 'code',
        },
        {
            title: '上架號',
            dataIndex: 'upperCode',
            key: 'upperCode',
        },
        {
            title: '重出',
            dataIndex: 'reupper',
            key: 'reupper',
        },
        {
            title: '备注',
            dataIndex: 'remark',
            key: 'remark',
        },
        {
            title: '執行結果',
            dataIndex: 'errcode',
            key: 'errcode',
        },
        {
            title: '原因',
            dataIndex: 'errmsg',
            key: 'errmsg',
        },
    ];

    const getUploadFile = (info) =>{

        // 获取上传的文件对象
        const file = info.file;
        // 通过FileReader对象读取文件
        const fileReader = new FileReader();
        // 以二进制方式打开文件
        fileReader.readAsBinaryString(file);

        fileReader.onload = event => {
            try {
                const result = event.target;
                // 以二进制流方式读取得到整份excel表格对象
                const workbook = XLSX.read(result?.result, { type: 'binary' });
                    // 存储获取到的数据
                let data = [];
                // 遍历每张工作表进行读取（这里默认只读取第一张表）
                for (const sheet in workbook.Sheets) {
                    // esline-disable-next-line
                    if (workbook.Sheets.hasOwnProperty(sheet)) {
                        // 利用 sheet_to_json 方法将 excel 转成 json 数据
                        data = data.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]));
                        break; // 如果只取第一张表，就取消注释这行
                    }
                }
                // 最终获取到并且格式化后的 json 数据
                if(!data || data.length == 0){
                    message.success(t('无数据！'))
                    return;
                }
                message.success(t('上传成功！'))

                // 整理数据，提交到后台
                let params:any[] = [];
                data.forEach(e => {
                    let exp = {
                        code: e['廠商訂單編號'],
                        upperCode: e['上架號'],
                        reupper: e['重出'],
                        remark:e['备注'],
                    }
                    params.push(exp);
                })
                doUpload(params);

            } catch (e) {
                // 这里可以抛出文件类型错误不正确的相关提示
                message.error('文件类型不正确！');
            }
        };
    }

    const doUpload = (params) => {
        let para = params[0];
        let url;
        if(para.upperCode){
            url = api.express.uppers;
        }else if(para.reupper){
            url = api.express.resends;
        }
        let update = async() => {
            let result = await json.post(url+'?type=2', params);
            message.success(t(result.msg));
            setExps(result.data);
        }
        update();
    }

    const handleDownload = () =>{
        window.open(api.upload.downloadUpper,"_blank")
    }

    const handleDownloadResend = () =>{
        window.open(api.upload.downloadResend,"_blank")
    }

    const exportCurrPage = () => {
        
        //  exportKit
        let table = document.querySelector(".xm-table-exp");
        if(table){
            exportKit.byTable(table, t("上架号导出")+new Date().getTime()+".xlsx");
        }

    }

    return (
        <div>
            <div className={`content-wrap ${layoutInfo.isMin?'content-wrap-min': ''}`} style={{marginTop: '10px'}}>
                <Row className="ctrl-wrap">
                    <Col span={24}>
                        <Space size={setting.size} wrap>
                            <Upload
                                customRequest={getUploadFile}
                                withCredentials={true}
                                fileList={[]}
                            >
                                <Button icon={<UploadOutlined />} type="primary">上传文件</Button>
                            </Upload>
                            <Button onClick={handleDownload} icon={<VerticalAlignBottomOutlined />} type="primary">{t('下载上架号模板')}</Button>
                            <Button onClick={handleDownloadResend} icon={<VerticalAlignBottomOutlined />} type="primary">{t('下载重出模板')}</Button>
                            <Button onClick={exportCurrPage} icon={<VerticalAlignBottomOutlined />} type="primary">{t('导出当前页')}</Button>
                        </Space>
                    </Col>
                </Row>
                <Table columns={columns} dataSource={exps} />
            </div>
        </div>    
    )
}