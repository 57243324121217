//编辑语言
import { LangType, LangEditType,LangDefaults } from "./";
import { Modal } from '../../components'
import { useState } from "react";
import {Form, Input,message, Select} from 'antd';
import { useTranslation } from "../../hooks/useTranslation";
import { useSelector } from "../../hooks/useSelector";
import { useRequest } from "../../hooks/useRequest";
import { api } from "../../config/api";

export const LangEdit : React.FC<LangEditType> = ({type, lang, onClose}) =>{

    // const [parentId1, setParentId1] = useState(parentId || 0);
    const [enable, setEnable] = useState(true);
    const t = useTranslation();
    const [form] = Form.useForm();
    const setting = useSelector(state => state.setting);
    const {json} = useRequest();

    const handleClose = () => {
        setEnable(false);
        setTimeout(() => {
            onClose && onClose(false);
        }, 500);
    }

    const onFinishFailed = () => {
        message.warning({
            content: t('请确认数据正确后在提交'),
        });
    };

    const onFinish = (values: LangType) => {
        // console.log(values);
        let data: LangType = {...lang, ...values}
        // console.log(data);
        let url = data.id?api.langRes.updateLang:api.langRes.createLang;
        
        let update = async() => {
            let result = await json.post(url, data);
            message.success(t(result.msg));
            if(result.status){
                setEnable(false);
                onClose && onClose(true);
            }
        }

        update();
    };
    
    const formHtml = (
        <div className="edit-form-wrap">
            <Form<LangType> form={form} labelCol={{span: 6}} wrapperCol={{span:18}} 
                size={setting.size}
                onFinishFailed={onFinishFailed}
                onFinish={onFinish}
            >   
                <Form.Item
                    label={t('语言名')}
                    name="label"
                    initialValue={lang.label}
                    rules={[{ required: true, message:  t("语言名不能为空")}]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label={t('语言代码')}
                    name="name"
                    initialValue={lang.name}
                    rules={[{ required: true, message:  t("语言代码不能为空")}]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label={t('是否默认')}
                    name="defaulted"
                    initialValue={lang.defaulted}
                >
                    <Select>
                        {LangDefaults.map(i => (
                            <Select.Option value={i.value} key={i.value}>
                                {i.label}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            </Form>
        </div>
    );

    let title = type==1?t('新增语言') : t('编辑语言')
        
    return (
        <Modal visible={enable}
            title={title}
            onOk={()=>{form.submit()}}
            onCancel={()=>handleClose()}
            afterClose={()=>handleClose()}
        >
            {formHtml}
        </Modal>
    )
} 