import { ReactNode, useEffect, useState } from "react";
import { Table,TablePaginationConfig
} from "antd";
import { useSelector, useRequest } from "./";
import { compareObj } from "../components";
import {useTableSearchFilter,useTableContentFilter} from '../hooks'
import { useTranslation } from "./useTranslation";

export interface TableColType{
    key: string, 
    title?: string | ReactNode,
    type?: 'checkbox' | 'radio',
    width?: number | string, 
    fixed?: 'right' | 'left' | false,
    filter?:  'search' | 'select',
    filterOptions?: {label: string, value: any}[];
    sorter?: boolean,
    render?: (text, row, index) => React.ReactNode,
    tip?: boolean,
    align?: 'left' | 'right' | 'center' | undefined,
    onCell?: (record, rowIndex) => void,
}

export interface TableType{
    rowKey: string, 
    query?: any,
    columns: TableColType[],
    clickRow?: (record, event, index) => void,
    scroll?: {x?: number | string, y?:number | string},
    pagenation?:  boolean,  //如果不启用则，设置为false
    url: string,
    extraData?: (data: any) => any[],
    tableClassName?: string,
}

export const useTable = (props: TableType) => {

    const {columns} = props;
    const t = useTranslation();
    const setting = useSelector(state => state.setting);  //全局设置
    const {json} = useRequest();    //axios封装
    const tableSearchFilter = useTableSearchFilter();  //表头搜索hooks
    const tableContentFilter = useTableContentFilter(); // 内容分组组件
    const [params, setParams] = useState<any>(props.query);  //请求后台的参数
    const layoutInfo = useSelector(state => state.layoutInfo);
    //分页设置
    const [pagenation, setPagenation] = useState<false | TablePaginationConfig>(props.pagenation==false?false: {
        current: 1,
        pageSize: 20, 
        total: 0,
        pageSizeOptions: ["20", "50", "100", "200", "1000", "2000"],
        showSizeChanger: true,
        // onChange: (current, size)=> {
        //     getDatas({current, pageSize: size});
        // }
    });

    //表格数据
    const [datas, setDatas] = useState<any[]>([]);
    //内容分组filter集合，以每一列的key作为key
    const [filters, setFilters] = useState({});

    //选中的数据
    const [selectKeys, setSelectKeys] = useState<any[] | any>([]);
    const [selectRecords, setSelectRecords] = useState<any[]>([]);
    //每行选择类型： radio, checkbox
    const [selectType, setSelectType] = useState<any>(null);
    const [currRow, setCurrRow] = useState<any>(null);

    //行选中回调
    const rowSelectionChange = (selectedRowKeys, selectedRows) => {
        // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        setSelectKeys(selectedRowKeys);
        setSelectRecords(selectedRows);
    }

    useEffect(()=>{
        let fs = filters;

        let selectType1 = selectType;

        //初始filter, 选择类型
        columns.forEach(col => {
            if(col.filter && col.filter == 'select' && !col.filterOptions){
                let filter = fs[col.key];
                if(!filter){
                    fs[col.key] = null;
                }
            }

            if(col.type && !selectType1){
                selectType1 = col.type;
            }
        });
        setFilters(fs);
        setSelectType(selectType1);
        getDatas(undefined);  //获取数据
    }, 
    // eslint-disable-next-line
    []);

    //从后台获取信息
    const getDatas = async (pageInfo, ps?) => {
        if(!pageInfo){
            pageInfo = pagenation;
        } 

        let query = {};
        if(pageInfo){
            query = {
                start: pageInfo?(pageInfo.current-1)*pageInfo.pageSize:0,
                length: pageInfo?pageInfo.pageSize: 10000,
            }
        }else{
            query = {
                start: 0,
                length: 10000
            }
        }
        if(ps != undefined){
            query = {...query, ...ps}
        }else{
            query = {...query, ...params}
        }

        let resp = await json.post(props.url, query);
        if(resp.status){
            let result = resp.data;
            let datas = result.datas;
            if(props.extraData){
                datas = props.extraData(result);
            }
            
            // setTableContentLableFilter(tableContentFilter(result.datas, 'label'));
            let keys = Object.keys(filters);
            if(keys.length > 0){
                let nfilters = {};
                keys.forEach(key => {
                    nfilters[key] = tableContentFilter(datas, key);
                });
                setFilters(nfilters);
            }

            setDatas(result => [...datas]);
            if(pagenation){
                let pinfo = {
                    ...pagenation,
                    total: result.total, 
                    pageSize: result.length, 
                    current: (result.start/result.length)+1
                };
                setPagenation(pinfo)
            }
            
        }
    }

    let tableCols: any[] = [];
    let rowSelectSetting = props.columns.find(c => c.type == 'radio' || c.type == 'checkbox');
    let isRowSelection = rowSelectSetting? true : false;

    //表格的列定义
    columns.forEach(col => {
        let ncol = {
            dataIndex: col.key,
            key: col.key,
            width: col.width,
            title: col.title,
            align: col.align,
            render: col.render,
            onCell: col.onCell
        }

        if(col.tip){
            ncol['ellipsis'] =  {
                showTitle: false,
            }
        }

        if(col.fixed && !layoutInfo.isMin){
            ncol['fixed'] = col.fixed;
        }

        if(col.sorter){
            ncol['sorter'] = (a, b) => compareObj(a, b, col.key);
        }

        if(col.filter){
            if(col.filter == 'search'){
                ncol = {...ncol, ...tableSearchFilter(col.key, col.render?col.render: null,  col.tip)}
            }else if(col.filterOptions){
                let filterOptions = {
                    filters: col.filterOptions.map(opt => {
                        return {text: t(opt.label), value: opt.value, key: opt.value} 
                    }),
                    onFilter: (value, record) => record[col.key] == value
                }
                ncol = {...ncol, ...filterOptions}
            } else{
                let filter = filters[col.key];
                if(filter){
                    ncol = {...ncol, ...filter}
                }
            }
        }
        tableCols.push(ncol);
    });
    
    //单击每一行
    const rowClick = (record, index) => {
        return {onClick: (event) => {
            if(isRowSelection){
                setCurrRow(record);
                if(selectType == 'radio'){
                    setSelectKeys([record.id])
                    setSelectRecords([record])
                }else{
                    if(selectKeys.indexOf(record.id)>-1){
                        setSelectKeys(keys => keys.filter(k => k != record.id));
                        setSelectRecords(records => records.filter(r => r.id != record.id));
                    }else{
                        setSelectKeys(keys => [...keys, record.id]);
                        setSelectRecords(records => [...records, record]);
                    }
                }
            }
            if(props.clickRow){
                props.clickRow(event, record, index);
            }
        }}
    }

    //行选中回调
    /**
     * 分页信息改变， 回调，这里只处理页面变化，
     */
    const handleTableChange = (p, filters, sorter) => {
        let p1 = pagenation as TablePaginationConfig;
        if(p1.current !==  p.current || p1.pageSize !== p.pageSize)
            getDatas(p);
    }

    let rowSelectConf =isRowSelection? {
        type: selectType,
        onChange: rowSelectionChange,
        selectedRowKeys: selectKeys
    } : undefined;

    //定义表格
    const tableNode = ()=>{
        let scroll = {}
        if(props.scroll){
            scroll = {...props.scroll}
        }

        if(!scroll['y']){
            let y = layoutInfo.isMin? layoutInfo.content-300 : layoutInfo.content-260;
            if(y<250){
                y = 250;
            }
            scroll['y'] = y;
        }

        return (
            <div className="table-box">
                <Table rowKey={props.rowKey} className={props.tableClassName}
                    rowSelection={rowSelectConf}
                    scroll={scroll}
                    bordered={true} size={setting.size}
                    pagination={pagenation}
                    dataSource={datas}
                    onChange={handleTableChange} 
                    onRow={rowClick}
                    columns={tableCols} />
            </div>
        )
    }

    //获取选中的记录
    const getSelectDatas = () => {
        return selectRecords;
    }

    //获取选中的key
    const getSelectKeys = () => {
        return selectKeys;
    }

    //远程搜索
    const remoteSearch = (paras: object | null) => {
        // console.log("do remote search");
        setParams(paras);
        if(pagenation){
            getDatas({pageSize: pagenation.pageSize, current: 1}, paras);
        }else{
            getDatas(null, paras);
        }
    }
    
    return {
        TableNode: tableNode,
        getSelectDatas,
        getSelectKeys,
        getCurrRow: currRow,
        remoteSearch,
        datas: datas,
        pageInfo: pagenation
    }
}