import { Modal,AuthButton } from '../../components'
import { useState,useEffect } from "react";
import { SearchOutlined } from '@ant-design/icons';
import {Form, Input,message, Row, Col,Select,InputNumber,Radio,Space, Typography} from 'antd';
import { useTranslation } from "../../hooks/useTranslation";
import { useSelector } from "../../hooks/useSelector";
import { useRequest } from "../../hooks/useRequest";
import { api } from "../../config/api";
import { ExpressType,ExpressEditType,companys,ShopType } from "./ExpressType";
import { ChoseShop } from '../';

export const ExpressEdit: React.FC<ExpressEditType> = ({type,express,onClose}) =>{

    const [enable, setEnable] = useState(true);
    const t = useTranslation();
    const [form] = Form.useForm();
    const setting = useSelector(state => state.setting);
    const {json} = useRequest();

    const [choseShopVisible, setChoseShopVisible] = useState(false);

    const [receiverShopInfoVisible, setReceiverShopInfoVisible] = useState(express.company=='黑猫'); 

    const [address, setAddress] = useState<any>({
        code: express.receiverShopId?express.receiverShopId:'',
        name: express.receiverShopName?express.receiverShopName:'',
    });

    const [edit, setEdit] = useState(false);
    useEffect(()=>{
        if(express.id){
            setEdit(true);
        }
    }, 
    // eslint-disable-next-line
    []);

    const handleClose = () => {
        setEnable(false);
        setTimeout(() => {
            onClose(false);
        }, 500);
    }

    const handleCompanyChange = (value) => {
        // console.log(value);
        setReceiverShopInfoVisible(value == '黑猫');
    }

    const [shopObj, setShopObj] = useState<ShopType>({
        onClose: (updateOk)=>{
            if(updateOk){
            }
            setChoseShopVisible(false);
        },
        onOk: (shop) => {
            setAddress(shop);
            setChoseShopVisible(false);
        }
    });
    const openMap = () =>{
        setShopObj({
            ...shopObj,
        });
        setChoseShopVisible(true);
    }

    const onFinishFailed = () => {
        message.warning({
            content: t('请确认数据正确后在提交'),
        });
    };

    const onFinish = (values: ExpressType) => {

        if(values.company!=='黑猫' && !address.code){
            message.warning(t("请选择门市"));
            return;
        }

        let isAdd = type==2? false: true;

        let data: any = null;
        //构建新对象
        if(!isAdd){
            data = {id: express.id};
            data["company"] = values.company;

            if(values.consignCode != express.consignCode){
                data["consignCode"] = values.consignCode
            }
            if(values.expressName != express.expressName){
                data["expressName"] = values.expressName
            }
            if(values.orderAmount != express.orderAmount){
                data["orderAmount"] = values.orderAmount
            }
            if(values.deliverType != express.deliverType){
                data["deliverType"] = values.deliverType
            }
            if(values.receiverName != express.receiverName){
                data["receiverName"] = values.receiverName
            }
            if(values.receiverTel != express.receiverTel){
                data["receiverTel"] = values.receiverTel
            }

            if(values.company=='黑猫'){
                if(values.receiverShopAddress != express.receiverShopAddress){
                    data["receiverShopAddress"] = values.receiverShopAddress
                }
            }else{
                if(address.code != express.receiverShopId){
                    data["receiverShopId"] = address.code
                    data["receiverShopName"] = address.name
                    data["receiverShopAddress"] = address.address
                }
            }
            
            data["updateOrderCode"] = values.updateOrderCode == 1? true : false;
        }else{
            if(values.company=='黑猫'){
                data = {...express, ...values}
            }else{
                data = {...express, ...values, receiverShopId: address.code , receiverShopName:address.name,receiverShopAddress:address.address }
            }
        }

        let companyType = 0;
        let updateUrl;
        if(data.company == '7-11'){
            companyType = 0;
            updateUrl = api.express.updateFor711;
        }else if(data.company == '全家'){
            companyType = 1;
            updateUrl = api.express.updateForQJ;
        }else if(data.company == '黑猫'){
            companyType = 2;
            updateUrl = api.express.updateForHM;
        }

        // console.log(data);
        let url = isAdd?api.express.creates: updateUrl;
        let update = async() => {
            let result = await json.post(url+'?type='+companyType, isAdd?[data]: data);
            if(result.status){
                if(isAdd){
                    let d = result.data[0];
                    if(d.errcode == '成功'){
                        message.success(t(result.msg));
                        setEnable(false);
                        onClose(true);
                    }else{
                        message.warning(t(d.errmsg));
                    }
                }else{
                    message.success(t(result.msg));
                    setEnable(false);
                    onClose(true);
                }
                
            }else{
                message.warning(t(result.msg));
            }
         
        }
        update();
    };

    const formHtml = (
        <div className="edit-form-wrap">
            <Form<ExpressType> form={form} labelCol={{span: 8}} wrapperCol={{span:16}} 
                size={setting.size}
                onFinishFailed={onFinishFailed}
                onFinish={onFinish}
                labelAlign="right"
            >   
                <Row>
                    <Col sm={24} md={12}  lg={10}>
                        <Form.Item
                            label={t('厂商')}
                            name="company"
                            initialValue={express.company}
                            rules={[{ required: true, message:  t("厂商不能为空")}]}
                        >
                            <Select allowClear onChange={handleCompanyChange}>
                                {companys.map(i => (
                                    <Select.Option value={i.value} key={i.value}>
                                        {i.label}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col sm={24} md={12}  lg={10}>
                        <Form.Item
                            label={t('商品名称')}
                            name="expressName"
                            initialValue={express.expressName}
                            rules={[{ required: true, message:  t("商品名不能为空")}]}
                        >
                            <Input allowClear/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col sm={24} md={12}  lg={10}>
                        <Form.Item
                            label={t('单号')}
                            name="consignCode"
                            initialValue={express.consignCode}
                            rules={[{ required: true, message:  t("单号不能为空")}]}
                        >
                            <Input allowClear/>
                        </Form.Item>
                    </Col>
                    {/* {edit ? 
                        <Col sm={24} md={12}  lg={10}>
                            <Form.Item
                                label={t('重新获取单号')}
                                name="updateOrderCode"
                                initialValue={express.updateOrderCode}
                            >
                                <Radio.Group name="radiogroup" defaultValue={0}>
                                    <Radio value={1}>是</Radio>
                                    <Radio value={0}>否</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        : ""
                    } */}
                    <Col sm={24} md={12}  lg={10}>
                        <Form.Item
                            label={t('订单金额')}
                            name="orderAmount"
                            initialValue={express.orderAmount}
                            rules={[{ required: true, message:  t("订单金额不能为空")}]}
                        >
                            <InputNumber min={1}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col sm={24} md={12}  lg={10}>
                        <Form.Item
                            label={t('收件人')}
                            name="receiverName"
                            initialValue={express.receiverName}
                            rules={[{ required: true, message:  t("收件人不能为空")}]}
                        >
                            <Input allowClear/>
                        </Form.Item>
                    </Col>
                    <Col sm={24} md={12}  lg={10}>
                        <Form.Item
                            label={t('配送方式')}
                            name="deliverType"
                            initialValue={express.deliverType}
                            rules={[{ required: true, message:  t("配送方式不能为空")}]}
                        >
                            <Radio.Group name="radiogroup" defaultValue={1}>
                                <Radio value={3}>纯配送</Radio>
                                <Radio value={1}>货到付款</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col sm={24} md={12}  lg={10}>
                        <Form.Item
                            label={t('收件人电话')}
                            name="receiverTel"
                            initialValue={express.receiverTel}
                            rules={[{ required: true, message:  t("收件人电话不能为空")}]}
                        >
                            <Input allowClear/>
                        </Form.Item>
                    </Col>
                  
                    {receiverShopInfoVisible?
                    (
                        <Col sm={24}>
                            <Form.Item
                                label={t('收件门市地址')} labelCol={{style: {width: '117px'}}}
                                name="receiverShopAddress"
                                initialValue={express.receiverShopAddress}
                            >
                                <Input.TextArea cols={2} allowClear placeholder={t('黑猫订单必填，其他厂商可不填')}/>
                            </Form.Item>
                        </Col>
                    ):(
                        <Col sm={24} md={12}  lg={10}>
                            <Form.Item
                                label={t('收件门市')}
                                name="receiverShopId"
                                initialValue={address.code}
                                shouldUpdate={(pre, cur) =>  {
                                    return pre!=cur;
                                }}
                            >
                                <Space>
                                    <Typography.Text>{address.name+"("+address.code+")"}</Typography.Text>
                                    <AuthButton type="primary" icon={<SearchOutlined />} onClick={openMap}>{t("选择门市")}</AuthButton>
                                </Space>
                            </Form.Item>
                        </Col>
                    )}
                </Row>
            </Form>
        </div>
    );

    let title = type==1?t('新增物流') : t('编辑物流')

    return (
        <div>
            <Modal visible={enable}
                title={title}
                width={900}
                onOk={()=>{form.submit()}}
                onCancel={()=>handleClose()}
                afterClose={()=>handleClose()}
            >
                {formHtml}
            {choseShopVisible && <ChoseShop {...shopObj} />}
            </Modal>
        </div>
        
    )
}