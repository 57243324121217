import { useEffect, useState } from "react"

import { EyeOutlined, EyeInvisibleOutlined,ReloadOutlined
} from '@ant-design/icons';

import {useTranslation, useRequest} from '../../hooks';
import {Modal, message} from 'antd';
import {api} from '../../config/api'

interface SecurityLabelProp{
    text: string,
    id: number,
}

export const SecurityLabel: React.FC<SecurityLabelProp> = ({text, id}) => {

    const [enable, setEnable] = useState(false);
    const [label, setLabel] = useState<string>("");
    const {json} = useRequest();

    const t = useTranslation();

    const confirmRefresh = () => {
        Modal.confirm({
            content: t("确定要重置ClientSecret吗？重置以后旧的将会失效！"),
            okText: t('确定'),
            okType: 'danger',
            cancelText: t('取消'),
            onOk: ()=>{
                
                const fireRefresh = async () => {
                    let result = await json.post(api.user.update, {refreshClientSecret: true, id: id});
                    if(result.status){
                        message.success(t(result.msg));  
                        setLabel(result.data.clientSecret);
                    }else{
                        message.warning(t(result.msg))
                    }
                }
                fireRefresh();
            }
        });
    }

    useEffect(()=>{
        if(text){
            if(enable){
                setLabel(text);
            }else{
                let temp = text.substring(0,1);
                let total =text.length - 2
                for(let i=0; i<total; i++){
                    temp+="*";
                }
                temp += text.substring(text.length - 1);
                setLabel(temp);
            }
        }
    }, 
    [enable, text]);

    return (
        <div style={{display: "flex", alignItems: "center"}}>
            {label} <span onClick={ ()=> setEnable(!enable)} 
                    style={{display: 'inline-block', marginLeft: '5px', cursor: "pointer"}}
                >
                    {enable?<EyeInvisibleOutlined />:<EyeOutlined />} 
                </span>
                <span  onClick={ ()=> confirmRefresh()} 
                    style={{display: 'inline-block', marginLeft: '5px', cursor: "pointer"}}>
                    <ReloadOutlined></ReloadOutlined>
                </span>
        </div>
    )
}