import {useState} from 'react';
import {useTranslation} from '../../hooks';
import { Modal } from '../../components'
import { Col, Descriptions, Row } from 'antd';

export interface DetailType {
    onClose: () => void,
    history: any
}


export const HistDetail : React.FC<DetailType> = ({onClose, history}) =>{

     // const [parentId1, setParentId1] = useState(parentId || 0);
    const [enable, setEnable] = useState(true);
    const t = useTranslation();

    const handleClose = () => {
        setEnable(false);
        setTimeout(() => {
            onClose();
        }, 500);
    }


    const formHtml = (
        <div className="edit-form-wrap">
            <Row>
                <Col>
                    <Descriptions>
                        <Descriptions.Item label={t('操作类型')}>{history.type}</Descriptions.Item>
                    </Descriptions>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Descriptions>
                        <Descriptions.Item label={t('ip地址')}>{history.ip}</Descriptions.Item>
                        <Descriptions.Item label={t('操作人')}>{history.optUser}</Descriptions.Item>
                    </Descriptions>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Descriptions>
                        <Descriptions.Item label={t('操作时间')}>{history.created}</Descriptions.Item>
                    </Descriptions>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Descriptions>
                        <Descriptions.Item label={t('操作内容')}>{history.remark}</Descriptions.Item>
                    </Descriptions>
                </Col>
            </Row>
        </div>
    );

    let title =t('查看详情');
        
    return (
        <Modal visible={enable}
            title={title}
            onOk={handleClose}
            onCancel={handleClose}
            afterClose={handleClose}
        >
            {formHtml}
        </Modal>
    )
}