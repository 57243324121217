import { SettingEditType, ValueTypes } from "./SettingType";
import {Form, Input,Select, message,
    DatePicker,Upload,InputNumber
 } from 'antd';
import {UploadOutlined} from '@ant-design/icons'
import { useSelector, useTranslation, useRequest } from "../../hooks";
import { Modal, AuthButton as Button } from '../../components'
import { useEffect, useState } from "react";
import { api } from "../../config/api";
import moment from 'moment'

export const SettingEdit : React.FC<SettingEditType> = ({type, setting, onClose}) =>{

    const [form] = Form.useForm();
    const webSetting = useSelector(state => state.setting);
    const t = useTranslation();
    const {json} = useRequest();
    const [enable, setEnable] = useState(true);
    const [fileList, setFileList] = useState<any[]>([]);
    const [deleteImgIds, setDeleteImgIds] = useState<string[]>([]);

    const [value, setValue] = useState(0);

    useEffect(()=> {
        if(setting.type==2){
            let getFileInfo = async () => {
                let result = await json.get(api.file.get+"?id="+setting.xval);
                if(result.status){
                    let url = api.file.view+"?id="+setting.xval;
                    let info = {
                        uid: result.data.id,
                        name: result.data.filename,
                        url: url, 
                        thumbUrl: url,
                    }

                    setFileList([info]);
                }
            }
            getFileInfo();
        }else{
            setFileList([]);
        }
    },
     // eslint-disable-next-line 
    []);

    const handleClose = () => {
        setEnable(false);
        if(onClose){
            setTimeout(() => {
                onClose(3);
            }, 500);
        }
    }

    const onFinishFailed = () => {
        message.warning({
            content: t('请确认数据正确后在提交'),
        });
    };

    const onFinish = (values) => {
        console.log(values);
        let data = {...setting, ...values}
        data['xval']= value;

        data["deleteIds"] = deleteImgIds;

        // console.log(data);
        let url = data.id?api.setting.update:api.setting.add;
        
        let update = async() => {
            let result = await json.post(url, data);
            message.success(t(result.msg));
            if(result.status){
                setEnable(false);
                if(onClose){
                    onClose(setting.parentId == 0?1:2);
                }
            }
        }

        update();
    };

    const uploadProp = {
        name: 'file',
        action: api.file.upload+"?type=common",
        withCredentials: true,
        // defaultFileList: fileList,
        fileList: fileList,
        maxCount: 1,
        onChange(info) {
            try{
                if(info.file && info.fileList.length ==0){
                    setDeleteImgIds([...deleteImgIds, info.file.uid+""]);
                    setFileList([]);
                    return;
                }
            }catch(err){
                console.error(err);
            }

            try{
                setFileList([...info.fileList]);
                let res = info.fileList[info.fileList.length-1]?.response;
                if(res){
                    if(res.status){
                        setValue(res.data);
                        info.fileList[0].uid = res.data;
                        message.success(t(res.msg));
                        setFileList([...info.fileList]);
                    }else{
                        message.warning(t(res.msg));
                    }
                }
                
            }catch(err){
                console.error(err);
            }
           
        },
        beforeUpload: (file) => {
            // if(fileList.length>1){
            //     let msg = t(`只能上传一张图片`);
            //     message.warning(msg);
            //     return false;
            // }

            let type = file.type;
            if (type.indexOf("png") >-1
                    || type.indexOf("jpg")>-1 
                    || type.indexOf("jpeg")>-1 
                    || type.indexOf("gif")>-1) {
                return true;
            }else{
                let msg = t(`[${file.name}]不是图片`);
                message.warning(msg);
                return false;
            }
        },
    }
    
    uploadProp['listType'] = 'picture';

    const handleValChange = (val, type) => {
        // console.log(val, type);
        if(type==0){
            setValue(val.target.value);
        }else if(type==1){
            setValue(val);
        }else if(type==3){
            setValue(val.format('YYYY-MM-DD'));
        }
    }

    const formHtml = (
        <div className="edit-form-wrap">
            <Form form={form} labelCol={{span: 6}} wrapperCol={{span:18}} 
                size={webSetting.size}
                onFinishFailed={onFinishFailed}
                onFinish={onFinish}
            >   
                <Form.Item
                    label={t('显示名')}
                    name="label"
                    initialValue={setting.label}
                    rules={[{ required: true, message:  t("显示名不能为空")}]}
                >
                    <Input allowClear />
                </Form.Item>
                <Form.Item
                    label={t('关键字')}
                    name="xkey"
                    initialValue={setting.xkey}
                    rules={[{ required: true, message:  t("关键字不能为空")}]}
                > 
                     <Input allowClear />
                </Form.Item>
                <Form.Item label={t('值')} style={{ marginBottom: 0 }}>
                    <Form.Item 
                        name="type"
                        initialValue={setting.type}
                        style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
                    >
                        <Select placeholder={t('请选择')}>
                            {ValueTypes.map(vt => (
                                <Select.Option value={vt.value} key={vt.value}>{t(vt.label)}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        style={{ display: 'inline-block', width: 'calc(50% - 8px)', marginRight: '8px' }}
                        shouldUpdate={(prevValues, curValues) => prevValues.type !== curValues.type}
                    >
                        {({ getFieldValue }) => {
                            const type: number = getFieldValue('type');
                            if(type==3){
                                return <DatePicker defaultValue={moment(setting.xval)} onChange={(val) => handleValChange(val, type)}/>
                            }else if(type==2){
                                return (<Upload {...uploadProp}>
                                    <Button type="primary" icon={<UploadOutlined />}>{t('上传图片')}</Button>
                                </Upload>)
                            }else if(type==1){
                                return <InputNumber defaultValue={setting.xval}  onChange={(val) => handleValChange(val, type)}/>
                            }else {
                                return <Input defaultValue={setting.xval}  onChange={(val) => handleValChange(val, type)}/>
                            }
                        }}
                    </Form.Item>
                </Form.Item>
                <Form.Item
                    label={t('说明')}
                    name="remark"
                    initialValue={setting.remark}
                > 
                     <Input allowClear />
                </Form.Item>
                <Form.Item
                    label={t('是否删除')}
                    name="deleted"
                    initialValue={setting.deleted}
                > 
                     <Input allowClear />
                </Form.Item>
            </Form>
        </div>
    );
                            
    let title = type == 1 ? t('新增设置'):t('编辑设置')
    return (
        <Modal visible={enable}
            title={title}
            onOk={()=>{form.submit()}}
            onCancel={()=>handleClose()}
            afterClose={()=>handleClose()}
        >
            {formHtml}
        </Modal>
    ) ;
}