import './Content.less';
import {  useHistory, matchPath } from 'react-router-dom';
import CacheRoute from 'react-router-cache-route'
import routers from '../../config/router';
import { Tabs } from 'antd';
import { useSelector } from '../../hooks/useSelector';
import { useEffect, useState } from 'react';

import NoFoundPage from '../../pages/404';
import { useTranslation } from '../../hooks/useTranslation';
import {Icon} from "../../components";
import { activeMenuSlice } from '../../redux/global';
import { useDispatch } from 'react-redux';

export const Content: React.FC = () => {
    const t=useTranslation();
    const dispatch = useDispatch();

    const menus = useSelector(state => state.menus);
    
    let defaultKey = "/admin";
    //直接进入指定路由的时候，
    //渲染tab
    const history = useHistory();
    const pathname = history.location.pathname;

    const setNotFoundPage = () => {

        let tab = tabDatas.find(r => r.key == 'pageNotFound' );

        if(!tab){
            //进入404
            setTabDatas([...tabDatas, {
                id:  Math.random()*1000,
                label: t('页面不存在'),
                exact: true,
                icon: '',
                component: NoFoundPage,
                path: pathname,
                url: pathname,
                closed: true,
                key: 'pageNotFound'
            }]);
        }
        setActiveTabKey("pageNotFound");
    }

    const [tabDatas, setTabDatas] = useState<any[]>([]);
    const [activeTabKey, setActiveTabKey] = useState(defaultKey);

    useEffect(()=> {
        //查看tabs里面是否存在，如果不存在，则在menus和routes里面找，
        
        let route = routers.find(r => r.key == pathname || matchPath(pathname, {
            path: r.path,
            exact: true,
            strict: false
        }));

        if(route){
            let key = route?.key || route?.path;
            let tab = tabDatas.find(r => r.key == key);
            if(tab){
                setActiveTabKey(tab.key);
                let menu = menus.find(m => m['url'] == tab?.key);
                if(menu){
                    dispatch(activeMenuSlice.actions.change(menu));
                }
            }else{
                setActiveTabKey(route.key || route.path);
                let menu = menus.find(m => m['url'] ==  key);
                if(menu){
                    dispatch(activeMenuSlice.actions.change(menu));   
                    
                    setTabDatas([...tabDatas, {
                        id: menu['id'],
                        label: menu['label'],
                        exact: route.exact || false,
                        icon: menu['icon'],
                        component: route.component,
                        path: route.path,
                        url: pathname,
                        closed: true,
                        key: route.key || route.path,
                    }]);
                    
                } else{
                    setTabDatas([...tabDatas, {
                        id:  Math.random()*1000,
                        label: t(route.name),
                        exact: route.exact || false,
                        icon: '',
                        component: route.component,
                        path: route.path,
                        url: pathname,
                        closed: true,
                        key: route.key || route.path
                    }]);
                }
            }
        }else{
            setNotFoundPage();
        }
    }, 
    // eslint-disable-next-line
    [pathname]);

    const changeTab = (key) => {
        let tab = tabDatas.find(r => r.key == key);
        if(tab){
            history.push(tab.url);
        }else{
            history.push(key);
        }
    }

    //点击关闭tab
    const editTab = (targetKey, action) => {
        if(action === 'remove'){
            let index = tabDatas.findIndex(t => t.key == targetKey);
            if(index>=0 && tabDatas.length>1){
                let tab: any = null;
                if(targetKey ===  activeTabKey){
                    let targetIndex = index == 0?index+1: index-1;
                    tab = tabDatas[targetIndex];
                    history.push(tab.key);
                    setActiveTabKey(tab.path);
                }
                // tabDatas.splice(index, 1);
                setTabDatas(tabDatas.filter(d => d.key !== targetKey));
            }
        }
    };

    return <>
         <Tabs defaultActiveKey={defaultKey} activeKey={activeTabKey} type="editable-card" hideAdd
                onChange={changeTab} onEdit={editTab}
                className="my-tabs"
                >
            {tabDatas.map(r => (
                <Tabs.TabPane key={r.key} 
                    tab={r.icon?(<><Icon icon={r.icon}></Icon>{r.label}</>):(r.label)} 
                    closable={r.closed} style={{padding: '0px 10px'}}
                >
                    <CacheRoute key={r.key} exact={r.exact||false} path={r.path} 
                        component={r.component}>
                    </CacheRoute>
                </Tabs.TabPane>
            ))}
        </Tabs>
    </>
}