export const codeStatus = [
    {label: '未使用', value: 0, color: '#108ee9'},
    {label: '已使用', value: 1, color: '#2db7f5'},
]

export const codeSearchStatus = [
    {label: '未使用', value: 2, color: '#108ee9'},
    {label: '已使用', value: 1, color: '#2db7f5'},
]

export const types = [
    {label: '7-11', value: '7-11', color: '#108ee9'},
    {label: '全家', value: '全家', color: '#2db7f5'},
    {label: 'OK', value: 'OK', color: '#2db7f5'},
    {label: '莱尔富', value: '莱尔富', color: '#2db7f5'},
]

export interface CodeType {
    type?: string | null, 
    startCode?: number | null,
    endCode?: number | null,
}

export interface OrderEditType{
    /**
    * 1: 是否需要更新
    */
    onClose: (updateOk?: boolean)=>void,
}