import './Self.less';

import { useParams } from "react-router-dom";
import { useSelector, useRequest, useTranslation } from '../../hooks';
import { useEffect, useState } from 'react';
import { api } from '../../config/api';
import { message } from 'antd';
import { Disables } from '..';
import { SecurityLabel } from '../../components';
import {ItemEdit} from "./ItemEdit";

import {
    Breadcrumb, Descriptions
} from 'antd'

import { HomeOutlined, UserOutlined
} from '@ant-design/icons';

interface MatchParams {
    id: string;
}

export const Self: React.FC = () => {
    let { id } = useParams<MatchParams>();
    const user = useSelector(state => state.user);
    const {json} = useRequest();
    const t = useTranslation();

    const [userInfo, setUserInfo] = useState<any>();
    if(!id){
        id = user?.id+"";
    }

    useEffect(()=>{
        let fetchData = async () => {
            let res = await json.get(api.user.get+"?id="+id);
            if(res.status){

                let status = res.data.disabled;
                if(status != undefined && status != null){
                    let disabled = Disables.find(d => d.value == status);
                    if(disabled){
                        res.data['disabledLabel'] = t(disabled.label);
                    }
                }
                setUserInfo(res.data);
            }else{
                message.warning(t(res.msg));
            }
        }
        fetchData();
    }, 
    // eslint-disable-next-line
    []);


    return (
        <div style={{padding: '10px'}}>
             <Breadcrumb>
                <Breadcrumb.Item href="">
                    <HomeOutlined />
                </Breadcrumb.Item>
                <Breadcrumb.Item href="">
                <UserOutlined />
                    <span>{userInfo?.fullname}</span>
                </Breadcrumb.Item>
            </Breadcrumb>

            <Descriptions style={{marginTop: '25px'}} title={t('基本信息')}>
                <Descriptions.Item label={t('账号')}>{userInfo?.username}</Descriptions.Item>
                <Descriptions.Item label={t('姓名')}>{userInfo?.fullname}</Descriptions.Item>
                <Descriptions.Item label={t('状态')}>{userInfo?.disabledLabel}</Descriptions.Item>
                <Descriptions.Item label={t('性别')}>{userInfo?.gender}</Descriptions.Item>
                <Descriptions.Item label={t('角色')}>{userInfo?.roleName}</Descriptions.Item>
            </Descriptions>

            <Descriptions style={{marginTop: '25px'}} title={t('账号设置')}>
                <Descriptions.Item style={{alignItems:"center"}} label={t('手机号码')} labelStyle={{alignItems: 'center'}}>
                    <ItemEdit text={userInfo?.tel} propKey={"tel"} id={userInfo?.id}></ItemEdit>
                </Descriptions.Item>
                <Descriptions.Item style={{alignItems:"center"}} label={t('黑猫客代号')} labelStyle={{alignItems: 'center'}}>
                    <ItemEdit text={userInfo?.hmNo} propKey="hmNo" id={userInfo?.id}></ItemEdit>
                </Descriptions.Item>
                <Descriptions.Item style={{alignItems:"center"}} label={t('寄件人')} labelStyle={{alignItems: 'center'}}>
                    <ItemEdit text={userInfo?.senderName} propKey="senderName" id={userInfo?.id}></ItemEdit>
                </Descriptions.Item>
                <Descriptions.Item style={{alignItems:"center"}} label={t('黑猫面单标识')} labelStyle={{alignItems: 'center'}}>
                    <ItemEdit text={userInfo?.senderType} propKey="senderType" id={userInfo?.id}></ItemEdit>
                </Descriptions.Item>
            </Descriptions>

            <Descriptions column={1} style={{marginTop: '25px'}} title={t('API授权')}>
                {userInfo?.token && <Descriptions.Item label={t('Token')}>{userInfo?.token}</Descriptions.Item>}
                <Descriptions.Item label={t('ClientID')}>{userInfo?.username}</Descriptions.Item>
                <Descriptions.Item label={t('ClientSecret')}>
                    <SecurityLabel text={userInfo?.clientSecret} id={userInfo?.id}></SecurityLabel>
                </Descriptions.Item>
            </Descriptions>
        </div>
    )
}