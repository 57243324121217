import { BrowserRouter, Route } from 'react-router-dom';
import CacheRoute, { CacheSwitch } from 'react-router-cache-route'
import './App.less';
import {Layout} from './components';
import {Home,Login, Refund, ExpBackSearch,Exchange} from './pages';
import NoFoundPage from './pages/404';
import { useSelector } from "./hooks/useSelector";
import { useDispatch } from "react-redux";
import { getLangs} from './redux/langSlice';
import { useTranslation } from "./hooks/useTranslation";
import { useEffect } from 'react';

function App() {
    //初始化国际化语言列表
    const theme = useSelector(state => state.setting.theme);
    const dispatch = useDispatch();
    const t = useTranslation();

    //设置网站标题
    useEffect(()=>{
        dispatch(getLangs()); 
        document.title = t('昶禹國際物流')
    },
        /* eslint-disable-next-line */ 
        []
    );


    return (
        <BrowserRouter>
            <CacheSwitch>
                <Route exact path="/" component={Login}></Route>
                <Route exact path="/refund" component={Refund}></Route>
                <Route exact path="/exchange" component={Exchange}></Route>
                <Route exact path="/refund/search" component={ExpBackSearch}></Route>
                <Route exact path="/refund.html" component={Refund}></Route>
                <Route exact path="/exchange.html" component={Refund}></Route>
                <Route exact path="/refund/search.html" component={Exchange}></Route>
                <Route exact path="/login" component={Login}></Route>
                <CacheRoute path="/admin" when="always" render={() => 
                    <div className={theme}>
                      <Layout></Layout>
                    </div>
                } />
                <Route component={NoFoundPage} />
            </CacheSwitch>
        </BrowserRouter>
    );
}

export default App;
