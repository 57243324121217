import './Setting.less';

import { Breadcrumb,  Row, Col, Modal, message,Image
} from 'antd';
import { HomeOutlined ,EditOutlined, DeleteOutlined, PlusCircleOutlined
} from '@ant-design/icons';

import { useSelector, useTranslation, useTable,TableType, useRequest } from '../../hooks';
import { api } from '../../config/api';
import { AuthButton as Button } from '../../components';
import { useState } from 'react';
import {SettingEdit, SettingEditType, defaultEditObj} from "./";

export const Setting: React.FC = () => {

    const t = useTranslation();
    const layoutInfo = useSelector(state => state.layoutInfo);
    const [visible, setVisible] = useState(false);
    const [editObj, setEditObj] = useState<SettingEditType>();
    const {json} = useRequest();

    const childrenTableProp: TableType = {
        rowKey: "id",
        pagenation: true,
        query: {type: 1, parentId:1},
        url: api.setting.list,
        columns: [
            {title: t("名称"), width: 130, key: "label", sorter: true, filter: 'select'},
            {title: t("KEY"), width: 130, key: "xkey", sorter: true, filter: 'select'},
            {title: t("值"), width: 130, key: "xval", render: (text, row, index)=> {
                let type = row.type;
                return type == 2 ? (
                    <Image
                        width={48}
                        src={api.file.view+"?id="+text}
                    />
                ) : (<span>{text}</span>)
            }},
            {title: t("说明"), width: 130, key: "remark", sorter: true, filter: 'select', },
            {width: 120, key: "id", fixed: 'right',  align: 'center',
                render: (text, row, index) => {
                    return (
                        <>
                            <Button permission="setting:update" type="link" onClick={(event) => handleEdit(event, row, index)} icon={<EditOutlined />}></Button>
                            <Button permission="setting:delete" type="link" onClick={(event) => handleDelete(event, row, 1)} danger icon={<DeleteOutlined />}></Button>
                        </>
                    )
                },
                title: ()=>(
                    <div style={{textAlign: 'center'}}><Button type="link" onClick={()=>handleAdd(1)} permission={"setting:create"} icon={<PlusCircleOutlined />}></Button></div>
                )
            },
        ] 
    }

    const childTable = useTable(childrenTableProp);

    let height = layoutInfo.height - layoutInfo.topHeight - layoutInfo.footerHeight-100;

    const parentTableProp: TableType = {
        rowKey: "id",
        query: {type: 0},
        pagenation: false,
        scroll: {y: height},
        clickRow: (event, row) => {
            childTable.remoteSearch({type:1, parentId: row.id});
        },
        url: api.setting.list,
        columns: [
            {title: t("名称"), width: 130, key: "label"},
            {width: 120, key: "id", align: 'center',
                render: (text, row, index) => {
                    return (
                        <>
                            <Button permission="setting:update" type="link" onClick={(event) => handleEdit(event, row, index)} icon={<EditOutlined />}></Button>
                            <Button permission="setting:delete" type="link" onClick={(event) => handleDelete(event, row, 0)} danger icon={<DeleteOutlined />}></Button>
                        </>
                    )
                },
                title: ()=>(
                    <div style={{textAlign: 'center'}}><Button type="link" onClick={() => handleAdd(0)} permission={"setting:create"} icon={<PlusCircleOutlined />}></Button></div>
                )
            },
        ] 
    }

    const parentTable = useTable(parentTableProp);

    const handleClose = (type: 1 | 2 | 3 | undefined) => {
        if(type == 1){
            parentTable.remoteSearch({type: 0});
        }else if(type==2){
            childTable.remoteSearch({type: 1, parentId: parentTable.getCurrRow?.id||1});
        }
        setVisible(false);
    }

    const handleEdit = (event, record, index) => {
        event.stopPropagation();
        setEditObj({
            onClose: handleClose,
            type: 2,
            setting: record
        });
        setVisible(true);
    }

    const handleDelete = (event, record, type) => {
        event.stopPropagation();
        Modal.confirm({
            content: t("确定要删除["+record.label+"]吗？"),
            okText: t('确定'),
            okType: 'danger',
            cancelText: t('取消'),
            onOk: ()=>{
                
                const fireDelete = async () => {
                    let result = await json.get(api.setting.deleted+"?id="+record.id);
                    if(result.status){
                        message.success(t(result.msg));  
                        if(type==0){
                            parentTable.remoteSearch({type: 0});
                        }else{
                            childTable.remoteSearch({ parentId: parentTable.getCurrRow?.id || 1, type: 1});
                        }
                    }else{
                        message.warning(t(result.msg))
                    }
                }
                fireDelete();
            }
        });
    }

    const handleAdd = (type) => {
        setEditObj({
            onClose: handleClose,
            type: 1,
            setting: {...defaultEditObj, parentId: type==0?0: parentTable.getCurrRow?.id||1}
        });
        setVisible(true);
    }

    return (
        <div>
            <Breadcrumb>
                <Breadcrumb.Item>
                    <HomeOutlined />
                </Breadcrumb.Item>
                <Breadcrumb.Item>{t('菜单管理')}</Breadcrumb.Item>
            </Breadcrumb>

            <Row className="setting-wrap">
                <Col sm={24} md={8} >
                    {parentTable.TableNode()}
                </Col>
                <Col sm={24} md={16} >
                    {childTable.TableNode()}
                </Col>
            </Row>
            {visible && <SettingEdit {...editObj}></SettingEdit>}
        </div>
    )
}