import { Modal } from '../../components'
import { useState } from "react";
import {Form, Input,message, Row, Col} from 'antd';
import { useTranslation } from "../../hooks/useTranslation";
import { useSelector } from "../../hooks/useSelector";
import { useRequest } from "../../hooks/useRequest";
import { api } from "../../config/api";
import { ExpressResendType } from "./ExpressType";

export const ExpressResend: React.FC<ExpressResendType> = ({code,onClose}) =>{

    const [enable, setEnable] = useState(true);
    const t = useTranslation();
    const [form] = Form.useForm();
    const setting = useSelector(state => state.setting);
    const {json} = useRequest();

    const handleClose = () => {
        setEnable(false);
        setTimeout(() => {
            onClose(false);
        }, 500);
    }

    const onFinishFailed = () => {
        message.warning({
            content: t('请确认数据正确后在提交'),
        });
    };

    const onFinish = (values : any) => {

        let data = [{ 
            code: code,
            ...values
         }]
         
        let url = api.express.resends;
        let update = async() => {
            let result = await json.post(url+'?type=0', data);
            message.success(t(result.msg));
            if(result.status){
                setEnable(false);
                onClose(true);
            }
        }
        update();
    };

    const formHtml = (
        <div className="edit-form-wrap">
            <Form<any> form={form} labelCol={{span: 8}} wrapperCol={{span:16}} 
                size={setting.size}
                onFinishFailed={onFinishFailed}
                onFinish={onFinish}
                labelAlign="right"
            >   
                <Row>
                    <Col sm={24} md={12}  lg={16}>
                        <Form.Item
                            label={t('重出单号')}
                            name="reupper"
                            rules={[{ required: true, message:  t("重出单号不能为空")}]}
                        >
                            <Input allowClear/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col sm={24} md={12}  lg={16}>
                        <Form.Item
                            label={t('备注')}
                            name="remark"
                        >
                            <Input allowClear/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </div>
    );

    let title = t('重出')

    return (
        <div>
            <Modal visible={enable}
                title={title}
                width={900}
                onOk={()=>{form.submit()}}
                onCancel={()=>handleClose()}
                afterClose={()=>handleClose()}
            >
                {formHtml}
            </Modal>
        </div>
        
    )
}