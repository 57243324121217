
const devServer = "http://localhost:8080";
const prodServer = "";

// console.log(process.env.NODE_ENV);

export const server =  process.env.NODE_ENV == 'development' ? devServer : prodServer;

const $api = {
    index: {
        expSearch: "/common/exp/search",
        expBackSearch: "/common/back/search",
        expBackRefund: "/common/back/refund",
    },
    i18n: {
        langs: "/common/i18n/langs",
        resources: "/common/i18n/resources",
        add: "/common/i18n/addRes",
        addAsKey: "/common/i18n/addResAsKey",
    },
    langRes: {
        langs: "/am/i18n/langs",
        createLang: "/am/i18n/createLang",
        updateLang: "/am/i18n/updateLang",
        deleteLang: "/am/i18n/delete",
        resourcesByKey: "/am/i18n/resourcesByKey",
        resources: "/am/i18n/resources",
        createResource: "/am/i18n/createResource",
        updateResource: "/am/i18n/updateResource",
        deleteResource: "/am/i18n/deleteResource",
    },
    common: {
        company: "/common/api/company",
        file: "/common/api/file",
        code: "/common/api/code"
    },
    auth: {
        signin: "/auth/login",
        quitLogin: "/auth/quitLogin"
    },
    user: {
        list: '/am/user/list',
        get: '/am/user/get',
        update: "/am/user/update",
        add: "/am/user/create",
        deleted: "/am/user/deleted",
        toggle: "/am/user/toggle",
        grantCode: "/am/user/grantCode",
        pwd: "/am/user/updatePwd",
    },
    menu: {
        toggle: "/am/menu/toggle",
        list: "/am/menu/list",
        update: "/am/menu/update",
        add: "/am/menu/create",
        deleted: "/am/menu/delete",
    },
    permission: {
        list: "/am/permission/list",
        add: "/am/permission/create",
        update: "/am/permission/update",
        deleted: "/am/permission/delete",
        toggle: "/am/permission/toggle",
    },
    role: {
        get: "/am/role/get",
        list: "/am/role/list",
        roles: "/am/role/roles",  //获取当前登录账户所拥有的角色
        add: "/am/role/create",
        update: "/am/role/update",
        deleted: "/am/role/delete",
        grantPermission: "/am/role/grantPermission",
        grantMenu: "/am/role/grantMenu",
    },
    history: {
        get: "/am/hist/get",
        list: "/am/hist/list",
        listCode: "/am/hist/listCode",
    },
    file: {
        get: "/am/file/get",
        list: "/am/file/list",
        upload: "/am/file/upload",
        deleted: "/am/file/delete",
        view: "/common/api/file"
    },
    setting: {
        list: "/am/setting/list",
        add: "/am/setting/create",
        update: "/am/setting/update",
        deleted: "/am/setting/delete"
    },
    address: {
        list: "/am/address/list",
        export: "/am/address/export",
    },
    expBack: {
        list: "/am/exp-back/list",
        status: "/am/exp-back/status",
        updateApplicantInfo: "/am/exp-back/updateApplicantInfo",
        updateAmount: "/am/exp-back/updateAmount",
        updateRemark: "/am/exp-back/updateRemark",
        updateSellers: "/am/exp-back/sellers",
        sendMail: "/am/exp-back/sendMail",
        deletes: "/am/exp-back/delete",
        export: "/am/exp-back/export",
    },
	orderCode: {
        list: "/am/order-code/list",
        summary: "/am/order-code/summary",
        create: "/am/order-code/create",
        export: "/am/order-code/export",
        deletes: "/am/order-code/deletes",
        getQjCode: "/am/order-code/getQjCode",
        doOpenCode: "/am/order-code/doOpenCode",
        getCodeSpace: "/am/order-code/getCodeSpace",
    },
	express: {
        detail: "/am/express/detail", 
        statusDetail: "/am/express/statusDetail", 
        list: "/am/express/list",
        export: "/am/express/export",
        deletes: "/am/express/deletes",
        creates: "/am/express/creates",
        create: "/am/express/create",
        updateFor711: "/am/express/updateFor711",
        updateForQJ: "/am/express/updateForQJ",
        updateForHM: "/am/express/updateForHM",
        printPage: "/am/express/printPage",
        resends: "/am/express/resends",
        uppers: "/am/express/uppers",
	},
    upload: {
        download711: "/static/default-711.xlsx",
        downloadQj: "/static/default-qj.xlsx",
        downloadOK: "/static/default-OK.xlsx",
        downloadLrf: "/static/default-lrf.xlsx",
        downloadHm: "/static/default-hm.xlsx",
        downloadUpper: "/static/default-upper.xlsx",
        downloadResend: "/static/default-upper-resend.xlsx",
    },
    doc: {
        doNet: "https://download.microsoft.com/download/1/B/E/1BE39E79-7E39-46A3-96FF-047F95396215/dotNetFx40_Full_setup.exe",
        printer: "/static/cs-printer.zip",
        printerInstaller: "/static/cs-printer-installer.zip",
        apiDoc: "/static/api.docx"
    }
}

const buildApi = (obj) => {
    Object.keys(obj).forEach(key => {
        let val = obj[key];
        if(typeof val === 'object') {
            buildApi(val);
        }else if(val.indexOf("http")!==0){
            obj[key] = server+val;
        }
    });
}

buildApi($api);

export const api = $api;