//分配用户， 这里用来管理用户给自己下级分配字账户
import { Modal } from '../../components'
import { useEffect, useState } from "react";
import { useTranslation } from "../../hooks/useTranslation";
import { useRequest } from "../../hooks/useRequest";
import { api } from "../../config/api";
import {ExpressDetailType} from "./"
import {message,Descriptions} from 'antd';

export const ExpressDetail: React.FC<ExpressDetailType> = ({code, codeType, setVisible}) => {
    // const [parentId1, setParentId1] = useState(parentId || 0);
    const [enable, setEnable] = useState(true);
    const t = useTranslation();
    const {json} = useRequest();

    const [express, setExpress] = useState<any>();

    const getExpress = async () => {
        let result = await json.get(api.express.detail+"?code="+code+"&type="+codeType);
        if(result.status){
            setExpress(result.data);
        }else{
            message.warning(t(result.msg));
        }
    }

    useEffect(()=>{
        getExpress();
    }, 
    // eslint-disable-next-line
    []);
    

    const handleCancel = () => {
        setEnable(false);
        setTimeout(() => {
            setVisible(false);
        }, 500);
    }

    const formHtml = (
        // <div className="edit-form-wrap">
        //     {JSON.stringify(express)}
        // </div>
        <div style={{padding: '10px'}}>
            <Descriptions style={{marginTop: '25px'}} title={t('基本信息')}>
                <Descriptions.Item label={t('订单编号')}>{express?.orderCode}</Descriptions.Item>
                <Descriptions.Item label={t('物流单号')}>{express?.consignCode}</Descriptions.Item>
                <Descriptions.Item label={t('状态')}>{express?.status}</Descriptions.Item>
                <Descriptions.Item label={t('物流厂商')}>{express?.company}</Descriptions.Item>
                <Descriptions.Item label={t('配送方式')}>{express?.deliverType==3?'纯配送':'货到付款'}</Descriptions.Item>
                <Descriptions.Item label={t('实时货态')}>{express?.track}</Descriptions.Item>
                <Descriptions.Item label={t('状态同步时间')}>{express?.syncStatusDate}</Descriptions.Item>
                <Descriptions.Item label={t('商品名称')}>{express?.expressName}</Descriptions.Item>
                <Descriptions.Item label={t('打印状态')}>{express?.printStatus}</Descriptions.Item>
                <Descriptions.Item label={t('订单金额')}>{express?.orderAmount}</Descriptions.Item>
                <Descriptions.Item label={t('收件人姓名')}>{express?.receiverName}</Descriptions.Item>
                <Descriptions.Item label={t('收件人电话')}>{express?.receiverTel}</Descriptions.Item>
                <Descriptions.Item label={t('收件门市编号')}>{express?.receiverShopId}</Descriptions.Item>
                <Descriptions.Item label={t('收件门市名')}>{express?.receiverShopName}</Descriptions.Item>
                <Descriptions.Item label={t('收件门市地址')}>{express?.receiverShopAddress}</Descriptions.Item>
                <Descriptions.Item label={t('收件人邮件')}>{express?.receiverEmail}</Descriptions.Item>
                <Descriptions.Item label={t('创建人')}>{express?.fullname}</Descriptions.Item>
                <Descriptions.Item label={t('创建时间')}>{express?.created}</Descriptions.Item>
                <Descriptions.Item label={t('出货时间')}>{express?.shipdate}</Descriptions.Item>
                <Descriptions.Item label={t('退货时间')}>{express?.returndate}</Descriptions.Item>
                <Descriptions.Item label={t('上架状态')}>{express?.upperStatus}</Descriptions.Item>
                <Descriptions.Item label={t('上架时间')}>{express?.upperDate}</Descriptions.Item>
                <Descriptions.Item label={t('上架号')}>{express?.upperCode}</Descriptions.Item>
                <Descriptions.Item label={t('重出')}>{express?.reupper}</Descriptions.Item>
                <Descriptions.Item label={t('备注')}>{express?.remark}</Descriptions.Item>
            </Descriptions>

        </div>
    );

    let title =t('查看物流详情')
        
    return (
        <Modal visible={enable}
            title={title}
            width={800}
            onOk={handleCancel}
            onCancel={handleCancel}
            afterClose={handleCancel}
        >
            {formHtml}
        </Modal>
    )
}