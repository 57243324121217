import './Auth.less';
import { Input,  Space, 
    Col, Row,
    message,Modal } from 'antd';
import { SearchOutlined,
    DeleteOutlined, PlusOutlined, EditOutlined
} from '@ant-design/icons';
import { useState } from 'react';
import { useSelector, useRequest,useTranslation, 
    TableType, useTable
 } from '../../hooks';
import { api } from '../../config/api';
import { AuthEditType } from './AuthType';
import { AuthEdit } from './AuthEdit';
import { AuthButton as Button } from '../../components';

export const Auth: React.FC = () => {

    const t = useTranslation();
    const setting = useSelector(state => state.setting);
    const {json} = useRequest();
    const [name, setName] = useState<string | null>(null);

    const [editVisible, setEditVisible] = useState(false);
   
    const tableProp: TableType = {
        rowKey: "id",
        pagenation: true,
        url: api.permission.list,
        columns: [
            {title: t("ID"), key: "id", width:100, sorter: true, type: 'radio'},
            {title: t("权限名"), key: "label", sorter: true, filter: 'select'},
            {title: t("权限"), key: "pattern", sorter: true, filter: 'search'},
        ] 
    }

    const table = useTable(tableProp);

    const tableHtml = table.TableNode();

    //点击搜索回调
    const onSearch = () => {
        table.remoteSearch({name: name});
    }

    //搜索框输入改变
    const onChange =(event) => {
        // console.log(event.target.value);
        setName(event.target.value);
    }

    const [editObj, setEditObj] = useState<AuthEditType>({
        type: 1,
        auth: {},
        onClose: (type)=>{
            if(type){
                table.remoteSearch({name: name});
            }
            setEditVisible(false);
        }
    });

    //处理新增，
    const handleAdd = () =>{
        setEditObj({...editObj,
            type: 1,
            auth: {
                id: null,
                label: '',
                pattern: ''
            },
        });
        setEditVisible(true);
    }

    const handleEdit = () => {
        let selecteds = table.getSelectDatas();
        if(selecteds.length===0){
            message.warning(t("请选中一条数据后再进行操作"));
            return;
        }
        let temp = selecteds[0];
        setEditObj({type: 2, onClose: editObj.onClose, auth: temp});
        setEditVisible(true);
    }

    const handleDelete = () => {
        let selecteds = table.getSelectDatas();
        if(selecteds.length===0){
            message.warning(t("请选中一条数据后再进行操作"));
            return;
        }
        Modal.confirm({
            content: t("确定要删除["+selecteds[0].label+"]吗？"),
            okText: t('确定'),
            okType: 'danger',
            cancelText: t('取消'),
            onOk: ()=>{
                
                const fireDelete = async () => {
                    let result = await json.get(api.permission.deleted+"?id="+selecteds[0].id);
                    if(result.status){
                        message.success(t(result.msg));  
                        table.remoteSearch({name: name}); 
                    }else{
                        message.warning(t(result.msg))
                    }
                }
                fireDelete();
            }
        });
    }

    return (
        <div>
            <div className='search-wrap'>
                <Input.Search style={{maxWidth: '500px'}}
                    allowClear
                    placeholder={t('权限/权限名')}
                    enterButton={t('搜索')}
                    size={setting.size}
                    onChange={onChange}
                    suffix={<SearchOutlined />}
                    onSearch={onSearch}
                />
            </div>
            <div className="content-wrap">
                <Row className="ctrl-wrap">
                    <Col span={24}>
                        <Space>
                            <Button permission={"permission:create"} onClick={handleAdd} icon={<PlusOutlined />} type="primary">{t('新增')}</Button>
                            <Button permission={"permission:update"}  onClick={handleEdit} icon={<EditOutlined />} type="primary">{t('编辑')}</Button>
                            <Button permission={"permission:delete"}  onClick={handleDelete} icon={<DeleteOutlined />} danger>{t('删除')}</Button>
                        </Space>
                    </Col>
                </Row>
                {tableHtml}
            </div>
            {editVisible && <AuthEdit {...editObj} />}
        </div>    
    )
}