// import { createStore, applyMiddleware,Middleware } from 'redux';
// import thunk from "redux-thunk";
import { actionLog } from './reducerMiddleware';
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { userSlice, settingSlice,companySlice, activeMenuSlice,layoutInfoSlice,
menusSlice
} from "./global";

import {langSlice} from "./langSlice";

//中间实例
// const middleWare: Middleware = (store) => (next) => (action) => {
//   // console.log("state 当前", store.getState());
//   // console.log("fire action ", action);
//   next(action);
//   // console.log("state 更新", store.getState());
// };

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["user", "setting"]
}

const rootReducer = combineReducers({
    lang: langSlice.reducer,
    user: userSlice.reducer,
    setting: settingSlice.reducer,
    company: companySlice.reducer,
    activeMenu: activeMenuSlice.reducer,
    layoutInfo: layoutInfoSlice.reducer,
    menus: menusSlice.reducer
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

// const store = createStore(rootReducer, applyMiddleware(thunk, actionLog));
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => [...getDefaultMiddleware({serializableCheck: false}), 
    actionLog
  ],
  devTools: true,
});

const persistor = persistStore(store)

export type RootState = ReturnType<typeof store.getState>

export default { store, persistor };