import './History.less';
import { useState } from "react";
import {Space, message, Row, Col,Tooltip,  
} from 'antd';
import { EyeOutlined, 
} from '@ant-design/icons';
import { api } from "../../config/api";
import { useSelector,useTranslation, 
    TableType, useTable
 } from '../../hooks';
import { HistDetail, DetailType } from '.';
import { AuthButton as Button, SearchItemProp,SearchForm } from '../../components';


export const History: React.FC = () => {
    const t = useTranslation();
    const layoutInfo = useSelector(state => state.layoutInfo);
    const [detailVisible, setDetailVisible] = useState(false);
    const [detailObj, setDetailObj] = useState<DetailType>({
        history: null,
        onClose: () => {}
    });

    const tableProp: TableType = {
        rowKey: "id",
        pagenation: true,
        url: api.history.list,
        scroll: {x: 840},
        columns: [
            {title: t("ID"), key: "id", width:80, sorter: true, type: 'radio', fixed: 'left'},
            {title: t("操作类型"), width:120, key: "type", sorter: true, filter: 'select'},
            {title: t("ip地址"),  width:120, key: "ip", sorter: true, filter: 'select'},
            {title: t("操作人"),  width:150, key: "optUser", sorter: true, filter: 'search'},
            {title: t("操作时间"),  width:150, key: "created", sorter: true, filter: 'search'},
            {title: t("操作内容"),  key: "remark", sorter: true, filter: 'search', tip: true,
                render: (text, row, index) => {
                    return (
                        <Tooltip placement="topLeft" title={text}>
                        {text}  
                        </Tooltip>
                    )
                }
            },
        ] 
    }

    const table = useTable(tableProp);

    const tableHtml = table.TableNode();

    const viewDetail = () => {
        let selecteds = table.getSelectDatas();
        if(selecteds.length===0){
            message.warning(t("请选中一条数据后再进行操作"));
            return;
        }
        let temp = selecteds[0];
        setDetailVisible(true);
        setDetailObj({
            onClose: ()=> {
                setDetailVisible(false);
            },
            history: temp
        });
    }

    const onFinish = (values) => {
        table.remoteSearch(values);
    }

    const searchItems: SearchItemProp[] = [
        {label: "操作人", name: 'optUser',  type: 'input'},
        {label: "操作类型", name: 'optType',  type: 'input'},
        {label: "关键字", name: 'remark',  type: 'input'},
        {label: "日期", name: 'Date',  type: 'datepicker', format:'YYYY-MM-DD'},
    ];

    return (
        <div>
            <SearchForm searchItems={searchItems} onSearch={onFinish}></SearchForm>
            <div className={`content-wrap ${layoutInfo.isMin?'content-wrap-min': ''}`} style={{marginTop: '10px'}}>
                <Row className="ctrl-wrap">
                    <Col span={24}>
                        <Space>
                            <Button onClick={viewDetail} icon={<EyeOutlined />} type="primary">{t('查看详情')}</Button>
                        </Space>
                    </Col>
                </Row>
                {tableHtml}
            </div>
            {detailVisible && <HistDetail {...detailObj} />}
        </div>    
    )
}