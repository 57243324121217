import '../iconfont/iconfont.css';
import './Icon.less';

interface IconType{
    icon?: string | undefined | null;
    size?: string;
    color?: string;
}

export const Icon: React.FC<IconType> = ({icon, size, color}) => {
    
    const style={};
    if(size){
        style['fontSize'] = size;
    }

    if(color){
        style['color'] = color;
    }

    return icon? (
        <i className={`ant-menu-item-icon iconfont ${icon} x-icon`} 
            style={style}>
        </i>
    ):(<span></span>)
}
