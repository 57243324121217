
export interface SettingEditType{
    /**
   * 1: 更新根菜单
   * 2: 更新子菜单
   * 3: 节点变动，父子都得刷新
   */
    onClose?: (updateOk?: 1 | 2 | 3 | undefined)=>void,
    setting?: any;
     /**
      * 1: 新增，
      * 2: 编辑
      * 3: 取消
      */
    type?: 1 | 2 | 3;
  
}

export const ValueTypes  = [
    {label: '文本', value: 0},
    {label: '数字', value: 1},
    {label: '图片', value: 2},
    {label: '日期', value: 3},
]

export const defaultEditObj = {
    id: null,
    label: null,
    parentId: null,
    xkey: null,
    xval: null,
    remark: null,
    deleted: '否',
    type: 0,
}