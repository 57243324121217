import './User.less'
import { Input,  Space, 
    Col, Row, Tag,
    message,Modal } from 'antd';
import { SearchOutlined,
    DeleteOutlined, PlusOutlined, EditOutlined,
    UsergroupAddOutlined, DeploymentUnitOutlined,
    EyeOutlined
} from '@ant-design/icons';
import { useState } from 'react';
import { useSelector, useRequest,useTranslation, 
    TableType, useTable
 } from '../../hooks';
import { api } from '../../config/api';
import { UserEditType, defaultUser, Disables, UserTypes, UserGrantType } from './UserType';
import { UserEdit, GrantUser } from './';
import { GrantUseRole } from './GrantUseRole';
import { GrantCode } from './GrantCode';
import { AuthButton as Button } from '../../components';
import { useHistory } from 'react-router-dom';

export const User: React.FC = () => {

    const t = useTranslation();
    const setting = useSelector(state => state.setting);
    const {json} = useRequest();
    const [name, setName] = useState<string | null>(null);
    const history = useHistory();

    const [editVisible, setEditVisible] = useState(false);
    const [grantObj, setGrantObj] = useState<UserGrantType>({});
    const [grantUserVisible, setGrantUserVisible] = useState(false);
    const [grantRoleVisible, setGrantRoleVisible] = useState(false);
    const [grantCodeVisible, setGrantCodeVisible] = useState(false);
   
    const tableProp: TableType = {
        rowKey: "id",
        pagenation: true,
        url: api.user.list,
        columns: [
            {title: t("ID"), key: "id", width:80, sorter: true, type: 'radio', fixed: 'left'},
            {title: t("账号"), width:100, key: "username", sorter: true, filter: 'search'},
            {title: t("姓名"),  width:100, key: "fullname", sorter: true, filter: 'search'},
            {title: t("角色"),  width:120, key: "roleName", sorter: true, filter: 'select', 
            render: (text, row, index) => {
                return (
                    <>
                    {"("+row.roleId+")"+row.roleName}
                      </>
                   ); 
                }
            },
            {title: t("状态"),  width:80, key: "disabled", sorter: true, filter: 'select',
                render: (text, row, index) => {
                    const disabled = Disables[row.disabled];
                    return (
                            <Tag color={disabled.color}>{t(disabled.label)}</Tag>
                        ); 
                },
                filterOptions: Disables
            },
            {title: t("类型"),  width:80, key: "type", sorter: true, filter: 'select',
                render: (text, row, index) => {
                    const type = UserTypes[row.type];
                    return (
                            <Tag color={type.color}>{t(type.label)}</Tag>
                        ); 
                },
                filterOptions: UserTypes
            },
            {title: t("性别"), width:80,  key: "gender", sorter: true, filter: 'select'},
            {title: t("创建时间"),  width:160, key: "created", sorter: true, filter: 'search'},
            {title: t("黑猫客代号"),  width:150, key: "hmNo", sorter: true, filter: 'search'},
            {title: t("电话"),  width:130, key: "tel", sorter: true, filter: 'search'},
            {title: t("发件人"),  width:110, key: "senderName", sorter: true, filter: 'search'},
        ] 
    }

    const table = useTable(tableProp);

    const tableHtml = table.TableNode();

    //点击搜索回调
    const onSearch = () => {
        table.remoteSearch({name: name});
    }

    //搜索框输入改变
    const onChange =(event) => {
        // console.log(event.target.value);
        setName(event.target.value);
    }

    const [editObj, setEditObj] = useState<UserEditType>({
        type: 1,
        user: {},
        onClose: (type)=>{
            if(type){
                table.remoteSearch({name: name});
            }
            setEditVisible(false);
        }
    });

    //处理新增，
    const handleAdd = () =>{
        setEditObj({...editObj,
            type: 1,
            user: {...defaultUser},
        });
        setEditVisible(true);
    }

    const handleEdit = () => {
        let selecteds = table.getSelectDatas();
        if(selecteds.length===0){
            message.warning(t("请选中一条数据后再进行操作"));
            return;
        }
        let temp = selecteds[0];
        setEditObj({type: 2, onClose: editObj.onClose, user: temp});
        setEditVisible(true);
    }

    //分配单号
    const grantCode = () => {
        let selecteds = table.getSelectDatas();
        if(selecteds.length===0){
            message.warning(t("请选中一条数据后再进行操作"));
            return;
        }
        let temp = selecteds[0];
        setGrantObj({
            id: temp.id as number,
            onClose: ()=>{
                setGrantCodeVisible(false);
            }
        });
        setGrantCodeVisible(true);
    }

    const grantUser =() => {
        let selecteds = table.getSelectDatas();
        if(selecteds.length===0){
            message.warning(t("请选中一条数据后再进行操作"));
            return;
        }
        let temp = selecteds[0];
        setGrantObj({
            id: temp.id as number,
            onClose: ()=>{
                setGrantUserVisible(false);
            }
        });
        setGrantUserVisible(true);
    }

    const grantRole =() => {
        let selecteds = table.getSelectDatas();
        if(selecteds.length===0){
            message.warning(t("请选中一条数据后再进行操作"));
            return;
        }
        let temp = selecteds[0];
        setGrantObj({
            id: temp.id as number,
            onClose: ()=>{
                setGrantRoleVisible(false);
            }
        });
        setGrantRoleVisible(true);
    }

    const handleDelete = () => {
        let selecteds = table.getSelectDatas();
        if(selecteds.length===0){
            message.warning(t("请选中一条数据后再进行操作"));
            return;
        }
        Modal.confirm({
            content: t("确定要删除["+selecteds[0].label+"]吗？"),
            okText: t('确定'),
            okType: 'danger',
            cancelText: t('取消'),
            onOk: ()=>{
                
                const fireDelete = async () => {
                    let result = await json.get(api.user.deleted+"?id="+selecteds[0].id);
                    if(result.status){
                        message.success(t(result.msg));  
                        table.remoteSearch({name: name}); 
                    }else{
                        message.warning(t(result.msg))
                    }
                }
                fireDelete();
            }
        });
    }

    const handleView = () => {
        let selecteds = table.getSelectDatas();
        if(selecteds.length===0){
            message.warning(t("请选中一条数据后再进行操作"));
            return;
        }
        history.push(`/admin/userinfo/${selecteds[0].id}`);
    }

    return (
        <div>
            <div className='search-wrap'>
                <Input.Search style={{maxWidth: '500px'}}
                    allowClear
                    placeholder={t('用户名/姓名')}
                    enterButton={t('搜索')}
                    size={setting.size}
                    onChange={onChange}
                    suffix={<SearchOutlined />}
                    onSearch={onSearch}
                />
            </div>
            <div className="content-wrap">
                <Row className="ctrl-wrap">
                    <Col span={24}>
                        <Space>
                            <Button onClick={handleView} icon={<EyeOutlined />} type="primary">{t('查看')}</Button>
                            <Button permission="user:create" onClick={handleAdd} icon={<PlusOutlined />} type="primary">{t('新增')}</Button>
                            <Button permission="user:update" onClick={handleEdit} icon={<EditOutlined />} type="primary">{t('编辑')}</Button>
                            <Button permission="user:grantUser" onClick={grantUser} icon={<UsergroupAddOutlined />} type="primary">{t('分配账户')}</Button>
                            <Button permission="user:grantRole" onClick={grantRole} icon={<DeploymentUnitOutlined />} type="primary">{t('分配角色')}</Button>
                            <Button permission="user:grantCode" onClick={grantCode} icon={<DeploymentUnitOutlined />} type="primary">{t('分配单号')}</Button>
                            <Button permission="user:delete" onClick={handleDelete} icon={<DeleteOutlined />} danger>{t('删除')}</Button>
                        </Space>
                    </Col>
                </Row>
                {tableHtml}
            </div>
            {editVisible && <UserEdit {...editObj} />}
            {grantUserVisible && <GrantUser {...grantObj} />}
            {grantRoleVisible && <GrantUseRole {...grantObj} />}
            {grantCodeVisible && <GrantCode {...grantObj} />}
        </div>    
    )
}