import './Code.less';
import { Space, Row, Col,Statistic,message, Progress,Tag, Modal,Menu,TablePaginationConfig} from 'antd';
import {  EditOutlined, DeleteOutlined, FileExcelOutlined} from '@ant-design/icons';
import { useSelector, useTranslation, useTable, TableType,useRequest, useExport, CellFormatter} from '../../hooks';
import { SearchForm, SearchItemProp,AuthDropdown as Dropdown,AuthButton as Button} from '../../components';
import { api } from '../../config/api';
import { useState, useEffect } from 'react';
import { codeStatus,OrderEditType,codeSearchStatus } from './CodeType';
import { CodeAdd } from './';

export const Code: React.FC = () => {

    const t = useTranslation();
    const setting = useSelector(state => state.setting);
    const layoutInfo = useSelector(state => state.layoutInfo);
    const {request} = useRequest();
    const exportKit = useExport();
    const {json} = useRequest();

    const [total711, setTotal711] = useState<number>(0);
    const [used711, setUsed711] = useState<number>(0);
    const [percent711, setPercent711] = useState<number>(0);
    const [totalQj, setTotalQj] = useState<number>(0);
    const [usedQj, setUsedQj] = useState<number>(0);
    const [percentQj, setPercentQj] = useState<number>(0);
    const [totalLrf, setTotalLrf] = useState<number>(0);
    const [usedLrf, setUsedLrf] = useState<number>(0);
    const [percentLrf, setPercentLrf] = useState<number>(0);

    const [editVisible, setEditVisible] = useState(false);
    const [editObj, setEditObj] = useState<OrderEditType>({
        onClose: (type)=>{
            if(type){
                table.remoteSearch(query);
            }
            setEditVisible(false);
        }
    });
    //处理新增，
    const handleAdd = () =>{
        setEditObj({...editObj});
        setEditVisible(true);
    }

    const getSummary  = async () => {
        let result = await request.get(api.orderCode.summary);
        if(result.status){
            if(result.data['7-11']){
                let data1 = result.data['7-11'];
                setTotal711(data1.total);
                setUsed711(data1.used);
                setPercent711(data1.usedPercent);
            }
            if(result.data['全家']){
                let data2 = result.data['全家'];
                setTotalQj(data2.total);
                setUsedQj(data2.used);
                setPercentQj(data2.usedPercent);
            }
            if(result.data['莱尔富']){
                let data3 = result.data['莱尔富'];
                setTotalLrf(data3.total);
                setUsedLrf(data3.used);
                setPercentLrf(data3.usedPercent);
            }
        }
    }

    useEffect(()=>{
        getSummary();
    }, 
    // eslint-disable-next-line
    []);
    
    //獲取全家單號
    const getQjCode = () => {
        Modal.confirm({
            content: t("確定要獲取全家單號嗎？"),
            okText: t('确定'),
            okType: 'danger',
            cancelText: t('取消'),
            onOk: ()=>{
                
                const doGetCode = async () => {
                    let result = await json.get(api.orderCode.getQjCode);
                    if(result.status){
                        Modal.success({
                            content: result.msg,
                          });
                    }else{
                        Modal.error({
                            content: result.msg,
                          });
                    }
                }
                doGetCode();
            }
        });
    }

    //釋放單號
    const openCode = () => {

        let selecteds = table.getSelectDatas();
        if(selecteds.length===0){
            message.warning(t("请选中一条数据后再进行操作"));
            return;
        }

        Modal.confirm({
            content: t("只能釋放未使用單號，確定要釋放選中的單號嗎？"),
            okText: t('确定'),
            okType: 'danger',
            cancelText: t('取消'),
            onOk: ()=>{
                doOpenCode(selecteds);
            }
        });
    }

    let doOpenCode = async (selecteds: any[]) => {
        let data = await request.post(api.orderCode.doOpenCode, "ids="+selecteds.map(s => s.id).join(","));
        if(data.status){
            Modal.success({
                content: data.msg,
                onOk() {
                    table.remoteSearch(query);
                },
            });
        }else{
            Modal.error({
                content: data.msg,
              });
        }
    }

     //删除
     let doDelete = async (selecteds: any[]) => {
        let data = await request.post(api.orderCode.deletes, selecteds.map(s => s.id).join(","));
        if(data.status){
            if(data.data == -11){
                Modal.confirm({
                    content: data.msg,
                    okText: t('确定'),
                    okType: 'danger',
                    cancelText: t('取消'),
                    onOk: ()=>{
                        doDeleteAll(selecteds);
                    }
                });
            }else{
                message.success(t(data.msg));
                table.remoteSearch(query);
            }
        }else{
            message.warning(t(data.msg));
        }
    }

    //删除
    let doDeleteAll = async (selecteds: any[]) => {
        let data = await request.post(api.orderCode.deletes+"?type=1", selecteds.map(s => s.id).join(","));
        if(data.status){
            message.success(data.msg);
            table.remoteSearch(query);
        }else{
            message.warning(t(data.msg));
        }
    }

    //删除
    const handleDelete = () => {
        let selecteds = table.getSelectDatas();
        if(selecteds.length===0){
            message.warning(t("请选中一条数据后再进行操作"));
            return;
        }

        Modal.confirm({
            content: t("确定要删除["+selecteds.map(s => s.id).join(",")+"]吗？"),
            okText: t('确定'),
            okType: 'danger',
            cancelText: t('取消'),
            onOk: ()=>{
                doDelete(selecteds);
            }
        });
    }

    const [query, setQuery] = useState<any | null>();

    const searchItems: SearchItemProp[] = [
        {label: '用户名或账号', name: "name", type: "input"},
        {label: "编号", name: 'code', type: 'input'},
        {label: '订单状态', name: 'status', type: {options: codeSearchStatus}},
        {label: "物流厂商", name: 'company', type: "input"},
    ]

    let titles =  [
        "ID", t("编号"), t('状态'),  t("拥有者"), t('厂商')
    ];

    const formats: Map<number, CellFormatter> = new Map();

    //类型处理
    formats.set(2, (row, cell)=>{
        let type = codeStatus.find(s => s.value == cell);
        return type? type.label: cell
    });

    const exportCurrPage = () => {
        
        let pageInfo = table.pageInfo as TablePaginationConfig;
        exportKit.byAjax({
            url: api.orderCode.export,
            query: {
                //@ts-ignore
                start: (pageInfo.current-1)*pageInfo.pageSize,
                length: pageInfo.pageSize,
                ...query
            },
            extra: (result) => result.data,
            dataType: {
                filename: t("单号导出")+new Date().getTime()+".xlsx",
                titles: titles,
                formats: formats
            }
        });
    }

    const exportAll = () => {

        if(query==null || Object.keys(query).length == 0){
            Modal.confirm({
                content: t("没有任何查询条件，可能导致系统卡死，是否继续操作"),
                okText: t('确定'),
                okType: 'danger',
                cancelText: t('取消'),
                onOk: ()=>{
                    doExportAll();
                }
            });
        }else if(table.pageInfo['total'] > 40000){
            Modal.confirm({
                content: t("数据量过大，可能导致浏览器卡死，是否继续操作"),
                okText: t('确定'),
                okType: 'danger',
                cancelText: t('取消'),
                onOk: ()=>{
                    doExportAll();
                }
            });
        }else{
            doExportAll();
        }
    }

    const doExportAll = () => {
        let pageInfo = table.pageInfo as TablePaginationConfig;
        exportKit.byAjax({
            url: api.orderCode.export,
            query: {
                start:  0,
                //@ts-ignore
                length: pageInfo.total,
                ...query
            },
            extra: (result) => result.data,
            dataType: {
                filename: t("单号导出")+new Date().getTime()+".xlsx",
                titles: titles,
                formats: formats
            }
        });
    }

    const dropdownMenu = (
        <Menu>
            <Menu.Item key="1" onClick={exportCurrPage} icon={<FileExcelOutlined />}>
                {t("导出当前页")}
            </Menu.Item>
            <Menu.Item key="2" onClick={exportAll} icon={<FileExcelOutlined />}>
                {t("导出所有")}
            </Menu.Item>
        </Menu>
    );

    let y = layoutInfo.isMin? layoutInfo.content-345 : layoutInfo.content-280;
    if(y<250){
        y = 250;
    }

    const tableProp: TableType = {
        rowKey: "id",
        pagenation: true,
        url: api.orderCode.list,
        scroll: {y:y},
        tableClassName: "xm-table-code",
        columns: [
            {title: t("ID"), key: "id", width:80, sorter: true, type: 'checkbox', fixed: 'left'},
            {title: t("编号"),  width:150, key: "code", sorter: true, filter: 'search'},
            {title: t("状态"), width:120, key: "status", sorter: true, filter: 'select', filterOptions: codeStatus, 
                render: (text, row, index) => {
                    let option = codeStatus.find(s => s.value == text);
                    return option?(<Tag color={option.color}>{option.label}</Tag>):({text});
            }},
            {title: t("拥有者"),  width:150, key: "holderName", sorter: true},
            {title: t("厂商"),  width:150, key: "type", sorter: true},
        ] 
    }

    const table = useTable(tableProp);

    const tableHtml = table.TableNode();

    const onSearch = (values) => {
        setQuery(values);
        table.remoteSearch(values);
    }

    return (
        <div>
            <SearchForm searchItems={searchItems} onSearch={onSearch}></SearchForm>
            <div className="site-statistic-demo-card" style={{margin: '20px 5px',backgroundColor:'white',padding:'10px'}}>
                <Row gutter={24}>
                    <Col span={4}>
                        <Statistic title="7-11总单号（条）" value={total711} valueStyle={{ color: '#cf1322' }} />
                        <Statistic title="7-11已使用（条）" value={used711} valueStyle={{ color: '#cf1322' }}/>
                    </Col>
                    <Col span={8} style={{textAlign:'left'}}>
                        <p >{'7-11使用量'}</p>
                        <Progress type="circle" percent={percent711} />
                    </Col>
                    <Col span={4}>
                        <Statistic title="全家总单号（条）" value={totalQj} valueStyle={{ color: '#cf1322' }}/>
                        <Statistic title="全家已使用（条）" value={usedQj} valueStyle={{ color: '#cf1322' }}/>
                    </Col>
                    <Col span={8} style={{textAlign:'left'}}>
                        <p >{'全家使用量'}</p>
                        <Progress type="circle" percent={percentQj} />
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={4}>
                        <Statistic title="莱尔富总单号（条）" value={totalLrf} valueStyle={{ color: '#cf1322' }} />
                        <Statistic title="莱尔富已使用（条）" value={usedLrf} valueStyle={{ color: '#cf1322' }}/>
                    </Col>
                    <Col span={8} style={{textAlign:'left'}}>
                        <p >{'莱尔富使用量'}</p>
                        <Progress type="circle" percent={percentLrf} />
                    </Col>
                    {/* <Col span={4}>
                        <Statistic title="全家总单号（条）" value={totalQj} valueStyle={{ color: '#cf1322' }}/>
                        <Statistic title="全家已使用（条）" value={usedQj} valueStyle={{ color: '#cf1322' }}/>
                    </Col>
                    <Col span={8} style={{textAlign:'left'}}>
                        <p >{'全家使用量'}</p>
                        <Progress type="circle" percent={percentQj} />
                    </Col> */}
                </Row>
            </div>
            <div className={`content-wrap ${layoutInfo.isMin?'content-wrap-min': ''}`} style={{marginTop: '10px'}}>
                <Row className="ctrl-wrap">
                    <Col span={24}>
                        <Space size={setting.size}>
                            <Button permission="ordercode:create" onClick={handleAdd} icon={<EditOutlined />}  type="primary">{t('批量生成')}</Button>
                            <Button permission="ordercode:getQj" onClick={getQjCode} icon={<EditOutlined />}  type="primary">{t('獲取全家單號')}</Button>
                            <Button permission="ordercode:replace" onClick={openCode} icon={<DeleteOutlined />}  type="primary" danger>{t('釋放單號')}</Button>
                            <Dropdown permission="ordercode:export" btnTxt={t('导出')} btnIcon={<FileExcelOutlined />} overlay={dropdownMenu}></Dropdown>
                            <Button permission="ordercode:delete" onClick={handleDelete} icon={<DeleteOutlined />}  type="dashed" danger>{t('删除')}</Button>
                        </Space>
                    </Col>
                </Row>
                {tableHtml}
            </div>
            {editVisible && <CodeAdd {...editObj}/>}
        </div>
    )
}