/**
 * 检查权限
 */

import { useSelector } from "./useSelector";

export const useAuth = () => {
    
    const user = useSelector(state => state.user);
    const permissions = user.permssions as [];

    const has = (permission : string | string[]): boolean => {
        if(typeof permission == 'string'){
            return hasPermission(permission as string);
        }else{
            return hasPermissions(permission as [], 'or');
        }
    }

    const hasPermission = (pattern) => {

        var isAll = permissions.find(p => p == "*");
        if(isAll){
            return true;
        }else{
            var result = permissions.find(p => p==pattern);
            if(result) {
                return true;
            }else{
                var patterns = pattern.split(":");
                var match = permissions.find(p => p==patterns[0]);
                // console.log(pattern, match, (match?true:false));

                return match?true:false;
            }
        }
    }
    const hasPermissions = (pers, logic)=>{  //表达式列表， logic: or/and

        if(!logic){
            logic = 'or';
        }
        if(!pers || pers.length == 0 ){
            return true;
        }

        if(pers.length == 1){
            return hasPermission(pers[0]);
        }

        for(let i=0; i<pers.length; i++){

            var result = hasPermission(pers[i]);
            
            if(logic == 'or' && result){
                return true;
            }else if(logic == 'and' && !result){
                return false;
            }
        }

        return true;

    }

    return {
        has, 
        hasPermission,
        hasPermissions
    }

} 