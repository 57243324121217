import { useRequest } from "./useRequest";
import * as XLSX from 'xlsx'; 
import {message} from 'antd';
import { useTranslation } from "./useTranslation";

export declare type CellFormatter =  (row: Object, cell: any, rowIndex: number, cellIndex?: number) => any;

export interface ArrayProps {
    filename: string, 
    titles?: string[], 
    formats?: Map<number, CellFormatter>,
    datas?: Object[]
}

export interface ObjectProps {
    filename: string, 
    columns: {
       title: string, 
       key: string, 
       formatter?: CellFormatter 
    }[],
    datas?: Object[],
}

export interface AjaxDataProps {
    url: string, 
    query?: Object,
    extra?: (result: any) => any[],
    dataType: ObjectProps | ArrayProps
}

export const useExport = () => {
    const {json} = useRequest();
    const t = useTranslation();

    /**
     * 通过ajax加载远程数据，进行导出
     * @param props AjaxDataProps
     */
    const ExportAjaxData = async (props: AjaxDataProps) => {

        let {url, query, extra}  = props;
        let result = await json.post(url, query? query: {});
        if(result) {
            if(extra){
                let data = extra(result);
                let columns = props.dataType['columns'];
                if(columns){
                    let objProps = props.dataType as ObjectProps;
                    objProps.datas = data;
                    ExportExcelByObject(objProps);
                }else {
                    let arrProps = props.dataType as ArrayProps;
                    arrProps.datas = data;
                    ExportExcelByArray(arrProps);
                }
            }
        }else{
            message.warning(t("无数据可导出"));
        }
    }

    /**
     * 执行导出操作
     * @param data 
     * @param filename 
     * @param t 
     */
    const doExportByArray = (data: Object[], filename, t: (msg: string)=> string) => {
        const { writeFile, utils } = XLSX;

        let wb = utils.book_new()
        let worksheet = utils.aoa_to_sheet(data as Object[][]);
        utils.book_append_sheet(wb, worksheet, "Sheet1");
        writeFile(wb, filename);
        message.success(t("导出成功"));
    }

    /**
     * 数据类型的数据导出： [[],[]]
     * @param props ArrayProps
     */
    const ExportExcelByArray = (props : ArrayProps) => {

        /**
         * 这里：是将数组对象导出为excel,
         * formats: index，就对应每一个的 索引，
         * title: 根据需要添加
         * [
         *  [1,2,3,4,5]
         *  [a,b,c,d,e]
         * ]
         * @param props : DataProps
         */

        const buildExcelDataForArray = (datas: Object[], filename: string, titles?: string[], formats?: Map<number, CellFormatter>) => {
            if(formats){
                datas.forEach((row, rowIndex) => {
                    let cells: any[] = row as [];
                    
                    cells.forEach((cell, index) => {
                        let formatter = formats.get(index);
                        if(formatter){
                            cells[index] = formatter(row, cell, rowIndex, index);
                        }
                    });
                });
            }

            if(titles){
                datas = [titles, ...datas]
            }

            doExportByArray(datas, filename, t);
        }

        buildExcelDataForArray(props.datas || [], props.filename, props.titles, props.formats);
    }

    /**
     * 对象类型的导出
     * @param props ObjectProps
     */
    const ExportExcelByObject = (props: ObjectProps) => {
        
        let results: any[][] = [];
        
        let datas = props.datas || [];

        datas.forEach((row, rowIndex) => {

            let cells : any[] = [];

            props.columns.forEach(col => {
                
                let value = row[col.key];
                if(value !== null || value !== undefined){
                    if(col.formatter){
                        value = col.formatter(row, value, rowIndex);
                    }
                }else{
                    value = "";
                }
                cells.push(value);
            });

            results.push(cells);
        })
        
        results = [props.columns.map(k => k.title), ...results];
        doExportByArray(results, props.filename, useTranslation());
    }

    /**
     * @param tbl table element, document.querySelector("#table");
     * @param filename 导出的excel名字
     */
    const ExportByHtmlTable = (tbl: Element, filename: string) => {

        const { writeFile, utils } = XLSX;
        let wb = utils.table_to_book(tbl);
        writeFile(wb, filename);
    };

    return {
        byAjax: ExportAjaxData,
        byArray: ExportExcelByArray,
        byTable: ExportByHtmlTable,
        byObject: ExportExcelByObject
    }
}
