//分配单号
import { UserGrantType,CodeType,types } from "./UserType";
import { Modal } from '../../components'
import { useEffect, useState } from "react";
import {Alert, Tag,message, Select
} from 'antd';
import {Form, Input} from 'antd';
import { useTranslation } from "../../hooks/useTranslation";
import { useSelector } from "../../hooks/useSelector";
import { useRequest } from "../../hooks/useRequest";
import { api } from "../../config/api";

export const GrantCode: React.FC<UserGrantType> = ({id, onClose}) => {
    // const [parentId1, setParentId1] = useState(parentId || 0);
    const [enable, setEnable] = useState(true);
    const [perCode, setPerCode] = useState<String>('');
    const [subCode, setSubCode] = useState<String>('');
    const [form] = Form.useForm();
    const t = useTranslation();
    const setting = useSelector(state => state.setting);
    const {json} = useRequest();

    useEffect(()=>{
    }, 
    // eslint-disable-next-line
    []);
    

    const handleCancel = () => {
        setEnable(false);
        if(onClose){
            setTimeout(() => {
                onClose(false);
            }, 500);
        }
    }

    const changeSelect = (value:any) => {
        //选中厂商时，刷新单号区间
        console.log(value);
        let get = async() => {
            let result = await json.post(api.orderCode.getCodeSpace+"?type="+value);
            if(result.status){
                let data = result.data;
                let per = data['per'];
                let sub = data['sub'];
                setPerCode(per);
                setSubCode(sub);
            }
        }

        get();
    };

    const onFinishFailed = () => {
        message.warning({
            content: t('请确认数据正确后在提交'),
        });
    };

    const onFinish = (values: CodeType) => {

        let data: CodeType = {...values,id:id}
        
        let update = async() => {
            let result = await json.post(api.user.grantCode, data);
            message.success(t(result.msg));
            if(result.status){
                setEnable(false);
                handleCancel();
            }
        }

        update();
    };

    const formHtml = (
        <div className="edit-form-wrap">
            <div style={{marginBottom:'20px',fontSize:'16px'}}><span>区间：{perCode} -- {subCode}</span></div>
            <Form<CodeType> form={form} labelCol={{span: 6}} wrapperCol={{span:18}} 
                size={setting.size}
                onFinishFailed={onFinishFailed}
                onFinish={onFinish}
                >   
                <Form.Item
                    label={t('厂商')}
                    name="type"
                    rules={[{ required: true, message:  t("厂商不能为空")}]}
                >
                    <Select allowClear onChange={changeSelect}>
                        {types.map(i => (
                            <Select.Option value={i.value} key={i.value}>
                                {i.label}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    label={t('起始编号')}
                    name="startCode"
                    rules={[{ required: true, message:  t("起始编号不能为空")}]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    label={t('结束编号')}
                    name="endCode"
                    rules={[{ required: true, message:  t("结束编号不能为空")}]}
                >
                    <Input />
                </Form.Item>
            </Form>
        </div>
    );

    let title =t('分配单号')
        
    return (
        <Modal visible={enable}
            title={title}
            width={500}
            onOk={()=>{form.submit()}}
            onCancel={()=>handleCancel()}
            afterClose={()=>handleCancel()}
        >
            {formHtml}
        </Modal>
    )
}