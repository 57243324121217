import React,{useEffect} from 'react';
import { useSelector } from "../../hooks/useSelector";
import { Redirect } from "react-router-dom";
import { Header } from '../../components';
import {Typography } from 'antd';
import { Footer, Logo } from '../../components';
import { LoginForm } from './LoginForm'; 
import './Login.less';
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";

export const Login: React.FC = () => {

    //判断是否已经登录，如果已经登录，则自动跳转到后台主页
    const user = useSelector(state => state.user);
    const { t } = useTranslation();

    const history = useHistory();
    useEffect(()=>{
        if(user.id){
            history.push('/admin');
        }
    }, [history, user]);

    return (!user.id?
        ( 
        <div className="login-bg">
            <Header />
            <div className="login-wrap" style={{margin: '150px 0px'}}>
                <div className="login-wrap1">
                    <div className="login-header">
                        <div className="login-logo-wrap">
                            <span className="login-logo">
                                <Logo />
                            </span>
                            <Typography.Text className="login-title">{t('昶禹國際物流')}</Typography.Text>
                        </div>
                        {/* <Typography.Title level={2} className="login-sub-title">{t('会员登录')}</Typography.Title> */}
                    </div>
                    <LoginForm />
                </div>
            </div>
            <Footer />
        </div>
        ):(<Redirect to="/admin"></Redirect>)
    )
}