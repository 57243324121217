
export interface ExpressDetailType {
    code: number | string;  //id， orderCode, consignCode,
    codeType:  0 | 1 | 2; //0-id， 1-orderCode类型, 2-consignCode
    setVisible:  React.Dispatch<React.SetStateAction<boolean>>;
}

export interface ExpressStatusDetailType {
    expId:  number; 
    setVisible:  React.Dispatch<React.SetStateAction<boolean>>;
}

export const ExpStatuses = [
    {value: 'NEW', label:'新建', color: '#87d068'},
    {value: 'SIN', label:'已上傳', color: '#87d068'},
    {value: 'SRP', label:'出貨回檔', color: '#87d068'},
    {value: 'UPDATED', label:'更新信息', color: '#87d068'},
    {value: 'ETA', label:'預定出貨檔', color: '#87d068'},
    {value: 'EIN', label:'進貨驗收檔', color: '#87d068'},
    {value: 'RS9', label:'驗收異常檔', color: '#87d068'},
    {value: 'UPDATE_SUP', label:'已更新门市信息', color: '#87d068'},
    {value: 'SUP', label:'已上傳門市信息', color: '#87d068'},
    {value: 'SURP', label:'已收到門市信息', color: '#87d068'},
    {value: 'PPS', label:'商品到/离', color: '#87d068'},
    {value: 'RS4', label:'商品到店', color: '#87d068'},
    {value: 'R96', label:'商品已取走', color: '#87d068'},
    {value: 'ESP', label:'門市代收檔', color: '#87d068'},
    {value: 'ERT', label:'預定退貨檔', color: '#87d068'},
    {value: 'EDR', label:'退貨驗收', color: '#87d068'},
    {value: 'EVR', label:'廠退回檔', color: '#87d068'},
    {value: 'ACC', label:'对账信息', color: '#87d068'},
    {value: 'ACTR', label:'遺失判賠', color: '#87d068'},
    {value: 'END', label:'完成', color: '#87d068'},
    {value: 'FAIL', label:'失败', color: '#87d068'},
];

export const PrintStatuses = [
    {label: '未打印', value:0, color: '#f50'},
    {label: '已打印', value:1, color: '#87d068'},
]

export const ErrMsgs  = [
    {label: '有错误消息', value:1, color: '#f50'},
    {label: '无错误消息', value:0, color: '#87d068'},
]

export const UpperStatuses = [
    {label: '无', value:0, color: '#f50'},
    {label: '未上架', value:1, color: '#87d068'},
    {label: '已上架', value:2, color: '#108ee9'},
    {label: '重出', value:3, color: 'orange'},
]

export interface ExpressType {
    id?: number | null;
    company?: string | null; 
    consignCode?: string | null; 
    expressName?: string | null;
    orderAmount?: number | null;
    deliverType?: number | null;
    receiverName?: string | null;
    receiverTel?: string | null;
    receiverShopId?: string | null;
    receiverShopName?: string | null;
    receiverShopAddress?: string | null;
    updateOrderCode?:number | null;
}

export interface ExpressView {
    id?: number | null;
    company?: string | null; 
    consignCode?: string | null; 
    expressName?: string | null;
    orderAmount?: number | null;
    deliverType?: number | null;
    receiverName?: string | null;
    receiverTel?: string | null;
    receiverShopId?: string | null;
    receiverShopName?: string | null;
    receiverShopAddress?: string | null;
}

export const defaultExpress : ExpressType = {
    id: null,
    company: null,
    consignCode: null,
    expressName: null,
    orderAmount: 0,
    deliverType: null,
    receiverName: null,
    receiverTel: null,
    receiverShopId: null,
    receiverShopAddress: null,
}

export interface ExpressEditType{
    /**
   * 1: 是否需要更新
   */
     onClose: (updateOk?: boolean)=>void;
     express: ExpressType;
     /**
      * 1: 新增，
      * 2: 编辑
      */
     type: 1 | 2;
  
}
export interface ShopType{
    onClose: (updateOk?: boolean)=>void;
    onOk: (shop: any) => void;
}


export const companys = [
    {label: "7-11", value: '7-11'},
    {label: '全家', value: '全家'},
    {label: '黑猫', value: '黑猫'},
    {label: 'OK', value: 'OK'},
    {label: '莱尔富', value: '莱尔富'},
]

export interface ExpressPrintType{
     onClose: (updateOk?: boolean)=>void;
     id:null
}

export interface ExpressResendType{
     onClose: (updateOk?: boolean)=>void;
     code:null
}

export interface ExpressUpperType{
    onClose: (updateOk?: boolean)=>void;
    code:null
}


