import './Menu.less';
import { Breadcrumb, Row, Col,Table, message,Tooltip, Modal } from 'antd';
import { HomeOutlined, PlusOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useTranslation } from '../../hooks/useTranslation';
import { useEffect, useState } from 'react';
import { useSelector } from '../../hooks/useSelector';
import { api } from '../../config/api';
import { useRequest } from '../../hooks/useRequest';
import { MenuType, MenuEditType } from './MenuType';
import { MenuEdit } from './MenuEdit';
import { AuthButton as Button } from '../../components';


export const Menu: React.FC = () => {
    
    const t = useTranslation();
    const setting = useSelector(state => state.setting);
    const {json} = useRequest();
    const [parents, setParents] = useState<MenuType[]>([]);

    const [parent, setParent] = useState<MenuType>({
        id: 0,
        url: null,
        seq: 0
    });
    const [children, setChildren] = useState<MenuType[]>([]);
    const [editVisible, setEditVisible] = useState(false);

    //编辑弹窗的关闭回调
    const handleCloseEdit = (updateOk)=>{
        if(updateOk){
            if(updateOk == 1)
                getParents()
            else if(updateOk == 2){ 
                getChildren(parent.id);
            }else{
                getParents()
                getChildren(parent.id);
            }
        }
        setEditVisible(false);
    }

    //编辑弹窗的对象
    const [editObj, setEditObj] = useState<MenuEditType>({
        onClose: handleCloseEdit,
        type: 1,
        menu: {}
    });

    const getParents = async () => {
        let data = await json.post(api.menu.list, {
            start: 0,
            length: 100,
            parentId:0
        });

        if(data.status){
            setParents(data.data.datas);
        }else{
            message.warning(t(data.msg));
        }
    }

    const getChildren =  async (id) => {
        let data = await json.post(api.menu.list, {
            start: 0,
            length: 100,
            parentId:id
        });

        if(data.status){
            setChildren(data.data.datas);
            if(parent.id===0 && parents.length>0){
                setParent(parents[0]);
            }
        }else{
            message.warning(t(data.msg));
        }
    }

    useEffect(()=>{
        getParents();
    },
    // eslint-disable-next-line
    []);

    const handleDelete = (event, record, index) => {
        event.stopPropagation();
        
        Modal.confirm({
            content: t("确定要删除["+record.label+"]吗？"),
            okText: t('确定'),
            okType: 'danger',
            cancelText: t('取消'),
            onOk: ()=>{
                
                const fireDelete = async () => {
                    let result = await json.get(api.menu.deleted+"?id="+record.id);
                    if(result.status){
                        message.success(t(result.msg));    
                        if(record.parentId && record.parentId>0){
                            getChildren(parent.id);
                        }else{
                            getParents();
                        }
                    }else{
                        message.warning(t(result.msg))
                    }
                }
                fireDelete();
            }
        });
    }

    const handleEdit = (event, record, index) => {
        event.stopPropagation();
        setEditObj({type: 2, onClose: handleCloseEdit, menu: record})
        setEditVisible(true);
    }

    const handleAdd = (event) => {
        event.stopPropagation();
        setEditObj({type: 1, onClose: handleCloseEdit, menu: {
            id: null,
            label: '',
            icon: '',
            parentId: parent?.id || 0,
            seq: 0,
        }})
        setEditVisible(true);
    }

    const parentTableCols = [
        {title: t("id"), dataIndex: "id", key: "id"},
        {title: t("菜单名"), dataIndex: "label", key: "label", 
            ellipsis: {
                showTitle: false,
            },
            render: label => (
                <Tooltip placement="topLeft" title={label}>
                    {label}
                </Tooltip>
            ),
        },
        {title: (
            <div style={{textAlign: 'center'}}>
                <Button shape="circle" icon={<PlusOutlined />} onClick={handleAdd} permission={"menu:create"} type="primary"></Button>
            </div>
            ), 
            key: "ctrl", fixed: 'right' as 'right', width: 100,
            render: (text, record, index)=>{
                return (
                    <>
                        <Button type="link" onClick={(event) => handleEdit(event, record, index)} permission={"menu:update"} icon={<EditOutlined />}></Button>
                        <Button type="link" onClick={(event) => handleDelete(event, record, index)} permission={"menu:delete"} danger icon={<DeleteOutlined />}></Button>
                    </>
                )
            }
        },
    ];

    const rowClick = (record) => {
        return {onClick: (event) => {
                getChildren(record.id);
            }
        }
    }

    return (
    <div>
        <Breadcrumb>
            <Breadcrumb.Item>
                <HomeOutlined />
            </Breadcrumb.Item>
            <Breadcrumb.Item>{t('菜单管理')}</Breadcrumb.Item>
        </Breadcrumb>

        <Row >
            <Col sm={24} md={8} >
                <Table<MenuType> className="content-wrap view-wrap-150" rowKey="id"
                    bordered={true} size={setting.size}
                    pagination={{ hideOnSinglePage : true}}
                    dataSource={parents} columns={parentTableCols} 
                    onRow={rowClick}
                />
            </Col>
            <Col sm={24} md={16} >
                <Table className="content-wrap view-wrap-150" rowKey="id"
                    bordered={true} size={setting.size}
                    dataSource={children} columns={parentTableCols} />
            </Col>
        </Row>
        {
            editVisible && <MenuEdit {...editObj}></MenuEdit>
        }
        
    </div>
    )
}