/**
 * 退件处理结果查询
 */
import {Header, Footer} from '../../components';
import {
    Typography, Space, Input, message, Button, Descriptions,Divider
}from 'antd';
import { SearchOutlined
} from '@ant-design/icons';
import { useState } from 'react';
import { useRequest,useTranslation} from '../../hooks';
import { api } from "../../config/api";
import {ExpressBackStatuses1, ExpressBackTypes} from './'


 export const ExpBackSearch: React.FC = () => {

    const [code, setCode] = useState<string | null>("93233565");
    const t = useTranslation();
    const {request} = useRequest();
    const [expBacks, setExpBacks] = useState<any[]>([]);
    const [codeImg, setCodeImg] = useState<string>(api.common.code);
    const [capcha, setCapcha] = useState<string>();

    const onChange = (event) => {
        // console.log(event.target.value);
        setCode(event.target.value);
    }

    const onChangeCapcha = (event) => {
        setCapcha(event.target.value);
    }

    const onSearch = async () => {
        if(!code){
            message.warning(t("请输入单号"));
            return;
        }

        if(!capcha){
            message.warning(t("请输入验证码"));
            return;
        }   

        let result = await request.post(api.index.expBackSearch, "code="+code+"&capcha="+capcha);
        if(result.status){

            let backs = result.data;
            backs.forEach(back => {
                let type = ExpressBackTypes.find(i => i.value == back.type);
                if(type){
                    back['typeStr'] = t(type.label);
                }

                let status = ExpressBackStatuses1.find(u => u.value == back.status);
                if(status){
                    back['statusStr'] = status.label;
                }
                });

            setExpBacks(backs);
        }else{
            message.warning(t(result.msg));
        }
    }

    const changeCode = ()=>{
        setCodeImg(api.common.code+"?_t="+new Date().getTime());
    }

    return (
        <>
            <Header />
            <div className="index-wrap">
                <div className="index-inner"  >
                    <Typography.Title level={3} style={{textAlign: 'center'}}>{t('哈囉! 這是昶禹國際物流第三方客服〈代寄〉')}</Typography.Title>
                    <div style={{textAlign: 'center', margin: '15px 0px'}}>
                        <Space wrap style={{justifyContent:'center'}}>
                            <Input style={{maxWidth: '300px'}}
                                allowClear
                                placeholder={t('處理編號或者配送編號')}
                                onChange={onChange}
                                suffix={<SearchOutlined />}
                            />
                            <div style={{position:'relative'}}>
                                <Input style={{width: "100px"}} onChange={onChangeCapcha} placeholder={t('验证码')}/>
                                <img style={{height: '30px',position: 'relative', top: "-2px"}} alt="code" onClick={()=>{changeCode()}} src={codeImg}></img>
                            </div>
                            <Button icon={<SearchOutlined />} size="middle" onClick={onSearch}>搜索</Button>
                        </Space>
                    </div>
                    <div style={{padding: '15px', textAlign: 'center', width: '300px', margin: '0px auto'}}>
                        {expBacks?.length==0? (<></>) : (
                            <>
                                <Descriptions title={t('查詢處理結果')}>
                                    {expBacks?.map(expBack => (
                                        <>
                                            <Descriptions.Item span={24} label={t('處理編號')}>{expBack.code}</Descriptions.Item>
                                            <Descriptions.Item span={24} label={t('配送編號')}>{expBack.expCode}</Descriptions.Item>
                                            <Descriptions.Item span={24} label={t('申請類型')}>{expBack['typeStr']}</Descriptions.Item>
                                            <Descriptions.Item span={24} label={t('當前狀態')}>{expBack['statusStr']}</Descriptions.Item>
                                            <Descriptions.Item span={24} label={t('提交時間')}>{expBack.created}</Descriptions.Item>
                                            <Descriptions.Item span={24} label={t('處理時間')}>{expBack.finished}</Descriptions.Item>
                                            <Divider></Divider>
                                        </>
                                    ))}
                                </Descriptions>
                            </>
                        )}
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
 }