import './CodeHist.less';
import { useState } from "react";
import {Space, message, Row, Col,Tooltip,  
} from 'antd';
import { EyeOutlined, 
} from '@ant-design/icons';
import { api } from "../../config/api";
import { useSelector,useTranslation, 
    TableType, useTable
 } from '../../hooks';
import { AuthButton as Button, SearchItemProp,SearchForm } from '../../components';

export const CodeHist: React.FC = () => {
    const t = useTranslation();
    const layoutInfo = useSelector(state => state.layoutInfo);
    const [detailVisible, setDetailVisible] = useState(false);

    const tableProp: TableType = {
        rowKey: "id",
        pagenation: true,
        url: api.history.listCode,
        scroll: {x: 840},
        columns: [
            {title: t("ID"), key: "id", width:80, sorter: true, type: 'radio', fixed: 'left'},
            {title: t("物流厂商"), width:120, key: "company", sorter: true, filter: 'search'},
            {title: t("起始单号"),  width:120, key: "starCode", sorter: true, filter: 'search'},
            {title: t("结束单号"),  width:150, key: "endCode", sorter: true, filter: 'search'},
            {title: t("分配用户"),  width:150, key: "userName", sorter: true, filter: 'search'},
            {title: t("操作时间"),  width:150, key: "created", sorter: true, filter: 'search'},
        ] 
    }

    const table = useTable(tableProp);

    const tableHtml = table.TableNode();

    const onFinish = (values) => {
        table.remoteSearch(values);
    }

    const searchItems: SearchItemProp[] = [
        {label: "用户名", name: 'userName',  type: 'input'},
        {label: "物流厂商", name: 'company',  type: 'input'},
        {label: "日期", name: 'Date',  type: 'datepicker', format:'YYYY-MM-DD'},
    ];

    return (
        <div>
            <SearchForm searchItems={searchItems} onSearch={onFinish}></SearchForm>
            <div className={`content-wrap ${layoutInfo.isMin?'content-wrap-min': ''}`} style={{marginTop: '10px'}}>
                <Row className="ctrl-wrap">
                    <Col span={24}>
                    </Col>
                </Row>
                {tableHtml}
            </div>
        </div>    
    )
}