
export const LangDefaults = [
    {value: 0, label: '正常'},
    {value: 1, label: '默认'}
] 

export interface LangType {
    id: number | null,
    label: string | null,
    name: string | null,
    defaulted: 0 | 1,
}

export interface LangEditType{
    onClose?: (updateOk?: boolean | undefined)=>void,
    lang?: any;
    /**
     * 1: 新增，2： 编辑
     */
    type?: 1 | 2;
  
}

export const defaultLang: LangType = {
    id: null,
    label: null,
    name: null,
    defaulted: 0
}

export interface ResType {
    id: number | null,
    languageId: number | null,
    langLabel: string | null,
    langName: string | null,
    name: string | null,
    msg: string | null,
}

export interface ResEditType {
    onClose?: (updateOk?: boolean | undefined) => void,
    resources?: ResType[],
    type?: 1 | 2,
}

export const defaultRes: ResType = {
    id: null,
    languageId: 0,
    langLabel: null,
    langName: null,
    name: null,
    msg: null,
}