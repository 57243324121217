import React,{Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "./config/i18n";
import { ConfigProvider,Spin } from 'antd';

import { Provider } from "react-redux";
import rootStore from "./redux/store";
import { PersistGate } from 'redux-persist/integration/react';
import zhCN from 'antd/lib/locale/zh_CN';
import zh_TW from 'antd/lib/locale/zh_TW';
import en_US from 'antd/lib/locale/en_US';
import moment from 'moment';
import 'moment/locale/zh-cn';
import 'moment/locale/zh-tw';

const loading = (
  <div style={{display:'block', position: 'fixed', top:'0px', left:'0px',  zIndex: 99999, background: 'rgba(0,0,0,0.2)', width: '100vw', height: '100vh'}}>
    <Spin style={{position: 'absolute',top: '45%', left: '45%'}} size="large" />
  </div>
)

let antdLang = zhCN;
let currLang = localStorage.getItem("lng");
console.log(currLang);
if(currLang){
    if(currLang.indexOf("tw")>=0){
        antdLang = zh_TW;
        moment.locale("zh_TW");
    }else if(currLang.indexOf("en")>=0) {
        antdLang = en_US;
        moment.locale("en");
    }else{
        antdLang = zhCN;
        moment.locale("zh_CN");
    }
}else{
    antdLang = zh_TW;
    moment.locale("zh_tw");
}

ReactDOM.render(
    <React.StrictMode>
        <Suspense fallback={loading}>
            <ConfigProvider locale={antdLang}>
                <Provider store={rootStore.store}>
                    <PersistGate loading={null} persistor={rootStore.persistor}>
                        <App />
                    </PersistGate>
                </Provider>
            </ConfigProvider>
        </Suspense>
    </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
