import './User.less'
import { Input, Tag, message} from 'antd';
import { SearchOutlined
} from '@ant-design/icons';
import { useState } from 'react';
import { useSelector,useTranslation, 
    TableType, useTable
 } from '../../hooks';
import { api } from '../../config/api';
import { Disables, UserTypes } from './UserType';
import { Modal } from '../../components';

export interface UserSelectType {
    Ok: (records: any[]) => void;
    setVisible: React.Dispatch<React.SetStateAction<boolean>>,
    type: 'radio' | 'checkbox' | undefined
}

export const UserSelect: React.FC<UserSelectType> = ({Ok, setVisible, type}) => {

    const t = useTranslation();
    const setting = useSelector(state => state.setting);
    const [name, setName] = useState<string | null>(null);
    const [enable, setEnable] = useState(true);
    
    const tableProp: TableType = {
        rowKey: "id",
        pagenation: true,
        url: api.user.list,
        scroll: {y: 600},
        columns: [
            {title: t("ID"), key: "id", width:80, sorter: true, type: type, fixed: 'left'},
            {title: t("账号"), width:100, key: "username", sorter: true, filter: 'search'},
            {title: t("姓名"),  width:100, key: "fullname", sorter: true, filter: 'search'},
            {title: t("角色"),  width:120, key: "roleName", sorter: true, filter: 'select', 
            render: (text, row, index) => {
                return (
                    <>
                    {"("+row.roleId+")"+row.roleName}
                      </>
                   ); 
                }
            },
            {title: t("状态"),  width:80, key: "disabled", sorter: true, filter: 'select',
                render: (text, row, index) => {
                    const disabled = Disables[row.disabled];
                    return (
                            <Tag color={disabled.color}>{t(disabled.label)}</Tag>
                        ); 
                },
                filterOptions: Disables
            },
            {title: t("类型"),  width:80, key: "type", sorter: true, filter: 'select',
                render: (text, row, index) => {
                    const type = UserTypes[row.type];
                    return (
                            <Tag color={type.color}>{t(type.label)}</Tag>
                        ); 
                },
                filterOptions: UserTypes
            },
            {title: t("性别"), width:80,  key: "gender", sorter: true, filter: 'select'},
            {title: t("创建时间"),  width:160, key: "created", sorter: true, filter: 'search'},
        ] 
    }

    const table = useTable(tableProp);

    const tableHtml = table.TableNode();

    const handleOk = ()=>{
        let selecteds = table.getSelectDatas();
        if(selecteds.length===0){
            message.warning(t("请选中一条数据后再进行操作"));
            return;
        }

        Ok(selecteds);
        handleClose();
    }

    const handleClose = () => {
        setEnable(false);
        setTimeout(() => {
            setVisible(false);
        }, 500);
    }

    //点击搜索回调
    const onSearch = () => {
        table.remoteSearch({name: name});
    }

    //搜索框输入改变
    const onChange =(event) => {
        // console.log(event.target.value);
        setName(event.target.value);
    }

    return (
        <Modal visible={enable}
            title={t("选择用户")}
            width={900}
            onOk={handleOk}
            onCancel={()=>handleClose()}
            afterClose={()=>handleClose()}
        >
             <div>
                <div className='search-wrap' >
                    <Input.Search style={{maxWidth: '500px'}}
                        allowClear
                        placeholder={t('用户名/姓名')}
                        enterButton={t('搜索')}
                        size={setting.size}
                        onChange={onChange}
                        suffix={<SearchOutlined />}
                        onSearch={onSearch}
                    />
                </div>
                <div className="content-wrap" style={{height: 'auto'}}>
                    {tableHtml}
                </div>
            </div>
        </Modal>
       
    )
}