import { Modal,AuthButton } from '../../components'
import { ExpressPrintType } from "./ExpressType";
import { useState,useEffect } from "react";
import ReactToPrint from 'react-to-print';
import { useTranslation } from "../../hooks/useTranslation";
import { Image,Select } from 'antd';
import { useRequest } from "../../hooks/useRequest";
import { api } from "../../config/api";

export const ExpressPrint: React.FC<ExpressPrintType> = ({id,onClose}) =>{

    const [enable, setEnable] = useState(true);
    const {json} = useRequest();
    const { Option } = Select;
    const t = useTranslation();

    const [componentRef, setComponentRef] = useState<any>();
    const [pageUrl, setPageUrl] = useState<any>();

    const handleClose = () => {
        setEnable(false);
        setTimeout(() => {
            onClose(false);
        }, 500);
    }

    useEffect(()=>{
        setPageUrl(null);
        getPage(0);
    }, 
    // eslint-disable-next-line
    []);

    const handleChange = (value) =>{
        getPage(value);
    }

    const getPage = async (value) => {
        let result = await json.get(api.express.printPage+"?code="+id+"&type=0&outType=2&quality="+value);
        setPageUrl(result.data);
    }

    return (
        <div>
            <Modal title="打印" visible={enable}
                width={500}
                onCancel={()=>handleClose()}
                afterClose={()=>handleClose()}
                footer={
                    <ReactToPrint
                        trigger={() => <AuthButton type="primary">打印</AuthButton>}
                        content={() => componentRef}
                    />
                }
            >
                <div>
                    <Select defaultValue="0" style={{ width: 120 }} onChange={handleChange}>
                        <Option value="0">{t('低质量')}</Option>
                        <Option value="1">{t('高质量')}</Option>
                    </Select>
                </div>
                <div ref={el => setComponentRef(el)} style={{ textAlign: 'center'}}>
                    <Image style={{width: "375px"}}
                        src={'data:image/png;base64,'+pageUrl}
                    />
                </div>
            </Modal>
        </div>
        
    )
    
}