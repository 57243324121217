import {Modal as AntdModal} from "antd";
import { ModalType } from "./ModalType";
import { useTranslation } from "../../hooks/useTranslation";
import "./Modal.less"
import { useSelector } from "../../hooks/useSelector";
import classNames from "classnames";


//自定义封装antd的modal, 用于适应小屏幕显示，和自定义的一些设置
export const Modal: React.FC<ModalType> = (props) => {

    const children = props.children;
    const t = useTranslation();
    const setting = useSelector(state => state.setting);
    const layoutInfo = useSelector(state => state.layoutInfo);

    let cns = props.className;
    if(!cns){
        if(layoutInfo.isMin){
            cns = "x-modal-min";
        }else{
            cns = "";
        }
    }else{
        if(layoutInfo.isMin){
            cns = cns+" x-modal-min";
        }
    }

    cns = classNames(cns, setting.theme);

    let okText = props.okText;
    if(!okText){
        okText = t("确定");
    }

    let cancelText = props.cancelText;
    if(!cancelText) {
        cancelText = t("取消");
    }

    let mprops = {...props, 
        destroyOnClose: (props.destroyOnClose==undefined? true: props.destroyOnClose),
        maskClosable: (props.maskClosable==undefined? false: props.maskClosable||false),
        width: (props.width?(layoutInfo.isMin? '100%': props.width):(layoutInfo.isMin?'100%': '550px')),
        className:  cns,
        okText: okText,
        cancelText: cancelText,
        okButtonProps: props.okButtonProps?{size: setting.size, ...props.okButtonProps}: {size: setting.size},
        cancelButtonProps: props.cancelButtonProps?
            {size: setting.size, ghost: setting.theme=='dark', ...props.cancelButtonProps}
            : {ghost: setting.theme=='dark', size: setting.size},
    }


    return (
        <AntdModal {...mprops}>
            {children}
        </AntdModal>
    );

}