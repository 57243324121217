import { ExpBackEditType } from "./ExpressBackType";
import { Modal } from '../../components';
import { api } from "../../config/api";
import { useTranslation,useSelector,useRequest } from "../../hooks";
import {Form,InputNumber,message} from 'antd';
import { useState } from "react";

export const UpdateAmount: React.FC<ExpBackEditType> = ({setVisible, expBack}) => {

    // const [parentId1, setParentId1] = useState(parentId || 0);
    const [enable, setEnable] = useState(true);
    const t = useTranslation();
    const [form] = Form.useForm();
    const setting = useSelector(state => state.setting);
    const {json} = useRequest();

    const handleClose = () => {
        setEnable(false);
        setTimeout(() => {
            setVisible(false);
        }, 500);
    }

    const onFinishFailed = () => {
        message.warning({
            content: t('请确认数据正确后在提交'),
        });
    };

    const onFinish = (values) => {
        let url = api.expBack.updateAmount;
        
        let update = async() => {
            let result = await json.post(url, {id: expBack.id, amount: values.amount});
            if(result.status){
                message.success(t(result.msg));
                Object.keys(values).forEach(key => {
                    expBack[key] = values[key];
                });
                handleClose();
            }else{
                message.warning(t(result.msg));
            }
        }

        update();
    };
    
    const formHtml = (
        <div className="edit-form-wrap">
            <Form form={form} labelCol={{span: 6}} wrapperCol={{span:18}} 
                size={setting.size}
                onFinishFailed={onFinishFailed}
                onFinish={onFinish}
            >   
                <Form.Item
                    label={t('金额')}
                    name="amount"
                    initialValue={expBack.amount}
                    rules={[{ required: true, message:  t("金额不能为空")}]}
                >
                    <InputNumber></InputNumber>
                </Form.Item>
            </Form>
        </div>
    );

    let title =t('修改商品金额')
        
    return (
        <Modal visible={enable}
            title={title}
            onOk={()=>{form.submit()}}
            onCancel={()=>handleClose()}
            afterClose={()=>handleClose()}
        >
            {formHtml}
        </Modal>
    )

}