//全屏加载中的显示

export const startLoading = () => {
    let div = document.getElementById("x-loading");
    if(!div){
        let div = document.createElement("div");
        div.id = "x-loading";
        div.style.cssText = "display:block; position: fixed; top:0px; left:0px; z-index: 999999; background: rgba(0,0,0,0.2); width:100vw; height: 100vh;";
        div.innerHTML = '<div style="position: absolute; top: 45%; left: 45%;" class="ant-spin ant-spin-spinning ant-spin-show-text"><span class="ant-spin-dot ant-spin-dot-spin"><i class="ant-spin-dot-item"></i><i class="ant-spin-dot-item"></i><i class="ant-spin-dot-item"></i><i class="ant-spin-dot-item"></i></span><div class="ant-spin-text">Loading...</div></div>';
        document.body.appendChild(div);
    }else{
        div.style.display = "block";
    }
}

export const endLoading = () => {
    let div = document.getElementById("x-loading");
    if(div){
        div.style.display = "none";
    }
}
