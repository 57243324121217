import { GrantType } from "./RoleType";
import { Modal } from '../../components'
import { useEffect, useState } from "react";
import {Table,message,TableColumnType, Modal as AntdModal} from 'antd';
import { useTranslation } from "../../hooks/useTranslation";
import { useSelector } from "../../hooks/useSelector";
import { useRequest } from "../../hooks/useRequest";
import { api } from "../../config/api";
import {AuthType} from "../"

export const GrantAuth : React.FC<GrantType> = ({roleId, roleLabel, onClose}) => {

    // const [parentId1, setParentId1] = useState(parentId || 0);
    const [enable, setEnable] = useState(true);
    const t = useTranslation();
    const setting = useSelector(state => state.setting);
    const {json, request} = useRequest();

    //获取当前窗体高度，不做动态监听，主要针对手机
    let height = document.documentElement.clientHeight;
    let width = document.documentElement.clientWidth;
    if(width < 500){
        height = height - 250;
    }else{
        height = height - 350;
    }
    if(height<300){
        height = 300;
    }

    const handleClose = () => {
        setEnable(false);
        setTimeout(() => {
            onClose();
        }, 500);
    }

    const okHandle = () => {
        if(selectKeys.length == 0){
            AntdModal.confirm({
                content: t("确定要清空角色["+roleLabel+"]权限吗？"),
                okText: t('确定'),
                okType: 'danger',
                cancelText: t('取消'),
                onOk: ()=>{
                    fireGrant();
                }
            });
        }else{
            fireGrant();
        }
    }

    const fireGrant = async () => {
        let result = await request.post(api.role.grantPermission,
                "id="+roleId+"&pids="+selectKeys.join(",")
            );
        if(result.status){
            message.success(t(result.msg));
            onClose();
        }else{
            message.warning(t(result.msg));
        }
    }

    //选中的数据
    const [selectKeys, setSelectKeys] = useState<any[]>([]);
    const [selectRecords, setSelectRecords] = useState<any[]>([]);

    //权限数据
    const [auths, setAuths] = useState<AuthType[]>([]);
    
    //获取权限
    const getAuths = async (id: number | null) => {
        
        let result = await json.get(api.permission.toggle+(id?("?id="+id):""));   
        if(result.status){
            if(id){
                setSelectKeys(result.data.map(d => d.id));
                // console.log(selectKeys);
                // setDefSelectKeys(result.data.map(d => d.id));
            }else{
                setAuths(result.data);
            }

        } else{
            message.warning({conetent: t(result.msg)})
        }

    }

    //初始化菜单数据
    useEffect(()=>{
        getAuths(null);
        getAuths(roleId)
    }, 
    // eslint-disable-next-line 
    []);

    //单选框选中回调
    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            //console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            setSelectKeys(selectedRowKeys)
            setSelectRecords(selectedRows)   
        },
        selectedRowKeys: selectKeys,
        // defaultSelectedRowKeys: defSelectKeys
    }

    //表格行点击
    const rowClick = (record) => {
        return {onClick: (event) => {
            let key = record.id;
            let idx = selectKeys.indexOf(key);
            if(idx > -1){
                //移除
                setSelectKeys(selectKeys.filter(id => id != key));
                setSelectRecords(selectRecords.filter(r => r.id != key));
            }else{
                //添加
                setSelectKeys([...selectKeys, key]);
                setSelectRecords([...selectRecords, record]);
            }
        }}
    }

    //表格字段定义
    const cols: TableColumnType<AuthType>[] = [
        {title: t("权限名"), dataIndex: "label", key: "label",
            sortDirections: ['ascend', 'descend']
        },
        {title: t("权限"), dataIndex: "pattern", key: "pattern",
            sortDirections: ['ascend', 'descend']    
        }
    ]; 

    const bodyHtml = (
        <div className="grant-wrap">
            <Table<AuthType> rowKey="id"
                rowSelection={rowSelection}
                scroll={{x: 400, y:height}}
                bordered={true} size={setting.size}
                pagination={false}
                dataSource={auths}
                onRow={rowClick}
                columns={cols} />
        </div>
    );

    return (
        <Modal visible={enable}
            title={t('分配权限')}
            onOk={okHandle}
            onCancel={()=>handleClose()}
            afterClose={()=>handleClose()}
        >
            {bodyHtml}
        </Modal>
    )

}