import i18n from "i18next";
//https://www.i18next.com/overview/configuration-options
import { initReactI18next } from "react-i18next";
//https://github.com/i18next/i18next-browser-languageDetector
import LanguageDetector from 'i18next-browser-languagedetector';
//https://github.com/i18next/i18next-http-backend
import HttpApi from 'i18next-http-backend';
import {api} from "./api";
import axios  from "axios";

let keys = {};

let currLang = localStorage.getItem("lng") || "zh-cn";

i18n.use(LanguageDetector)
    .use(HttpApi)
    .use(initReactI18next)
    .init({
        backend:{
            loadPath: api.i18n.resources+"?lang={{lng}}&ns={{ns}}",
            // addPath: api.i18n.add+"?lang={{lng}}"
        },
        lng: currLang,
        keySeparator: false, // we do not use keys in form messages.welcome
        // header.slogan
        interpolation: {
            escapeValue: false, // react already safes from xss
        },
        fallbackLng: false,
        saveMissing: true,
        missingKeyHandler: (lng, ns, key, fallbackValue)=>{
            // console.log(lng, ns, key, fallbackValue);
            //这个lng不是当前的lang, 需要获取当前的lang
            setTimeout(() => {
                try{
                    let lang = localStorage.getItem("lng") || 'zh-cn';
                    let existLang = keys[lang];
                    if(!existLang || existLang.indexOf(key)==-1){
                        axios.get(api.i18n.addAsKey+"?lang="+lang+"&key="+encodeURIComponent(key));
                    }
                    if(!existLang){
                        existLang = keys[lang] = [];
                    }
                    existLang.push(key);
                }catch(err){
                    console.error(err);
                }
            }, 1000);
            return true;
        }
    });


export default i18n;