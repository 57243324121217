import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import {api} from "../config/api";
import logo from '../assets/img/logo1.png';
import request, {XReqponse} from '../config/request';
import { message } from "antd";
import { SizeType } from "antd/lib/config-provider/SizeContext";

interface UserState {
    id?: number | undefined | null,
    username?: String | undefined | null;
    fullname?: String | undefined | null;
    type?: number | undefined | null;
    permssions: string[] | undefined | null;
    roleId: number | undefined | null;
    roleName: string | undefined | null;
    roleLable: string | undefined | null;
}

const initialState: UserState = {
    id:  null,
    username: null,
    fullname: null,
    type: null,
    permssions: null,
    roleId: null,
    roleName: null,
    roleLable: null,
};

export const signIn = createAsyncThunk(
    "user/signIn",
    async (paramaters: {
        username: string,
        password: string,
        code: string
    }/*, thunkAPI*/) => {
        const data = await request.json.post(
            api.auth.signin,{
                username: paramaters.username,
                password: paramaters.password,
                code: paramaters.code
            }
        ) ;
        
        return data;
    }
);

export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        logOut: (state) => {
            state.id = null;
            state.username = null;
            state.permssions = [];
            state.roleId = null;
        },
    },
    extraReducers: {
        [signIn.fulfilled.type]: (state, action: PayloadAction<XReqponse<string>>) => {
            let res = action.payload;
            // debugger;
            if(res.status){
                Object.assign(state,res.data)   
                // state = {...res.data};
            }else{
                message.warning({content: res.msg})
            }
        },
    },
});

interface SettingState {
    collapsed: boolean, //侧边菜单是否，折叠
    theme: string ,  //主题 // 'dark' | 'light';
    size: 'large' | 'middle' | 'small' | undefined, //组件尺寸 //'large' | 'middle' | 'small';
    menuMode: string,  //菜单布局， horizontal=水平，inline=垂直
    hidenMenu: boolean, //是否隐藏菜单，当菜单为侧边的时候，有效
}

const initialSettingState: SettingState = {
    collapsed: false,
    theme: 'light',
    size: 'middle',
    menuMode: 'inline',
    hidenMenu: false,
  };

export const settingSlice = createSlice({
    name: "setting",
    initialState: initialSettingState,
    reducers: {
        switchCollapsed: (state) => {
            // console.log(state.collapsed,action);
            state.collapsed = !state.collapsed;
        }, 
        switchTheme: (state, action: PayloadAction<string>) => {
            let type = action.payload;
            if(type === 'dark' || type=='light'){
                state.theme = type;
            }else if(type == 'horizontal' || type=='inline') {
                state.menuMode = type;
            }else if(type=="hidden" && state.menuMode=='inline'){
                state.hidenMenu = !state.hidenMenu;
            }else {
                state.size = type as SizeType;
            }
        },
    }
});

interface companyType{
    logo?: string | undefined;
    name?: string | undefined;
}
const companyInitState : companyType = {
    logo: logo,
    name: "海思科技"
};

export const getCompany = createAsyncThunk(
    "web/company",
    async () => {
        const data  = await request.request.post(api.common.api);

        let result:companyType = companyInitState;
        if(data.status && data.data!=null){
            let datas = data.data;
            datas.forEach(d => {
                let key = d.xkey;
                let val = d.xval;
                if(key=='logo' && val){
                    result.logo = api.common.file+"?id="+val;
                }else if(key == 'company' && val){
                    result.name = val;
                }
            });
        }
        return data;
    }
);


export const companySlice = createSlice({
    name: "company",
    initialState: companyInitState,
    reducers:{},
    extraReducers: {
        [getCompany.fulfilled.type]: (state, action: PayloadAction<companyType>) => {
            state.logo = action.payload.logo;
            state.name = action.payload.name;
        },
    },

});


export const activeMenuSlice = createSlice({
    name: "activeMenu",
    initialState: {},
    reducers: {
        change: (state, action) => {
            let menu = action.payload;
            Object.assign(state, menu) 
        },
    },
});

export const menusSlice = createSlice({
    name: "menus",
    initialState: [],
    reducers: {
        setMenus: (state, action) => {
            return action.payload;
        }
    }
});


export interface LayoutInfoType{
    width: number;
    height: number;
    sideWidth: number;
    topHeight: number;
    footerHeight: number;
    content: number,
    isMin: boolean,
}

const defLayoutInfo : LayoutInfoType = {
    // @ts-ignore
    width: document.body.clientWidth,
    height: document.body.clientHeight,
    sideWidth: 0,
    topHeight: 0,
    footerHeight: 0,
    content: 0,
    isMin: false,
}

export const layoutInfoSlice = createSlice({
    name: "layoutInfo",
    initialState: defLayoutInfo,
    reducers: {
        setInfo: (state, action: PayloadAction<LayoutInfoType>) => {
            Object.assign(state, action.payload) 
        },
    },
});