import React from "react";
import { Modal } from '../../components'
import { useState } from "react";
import {Form, Input,message,Select} from 'antd';
import { CodeType,types,OrderEditType } from "./CodeType";
import { useSelector } from "../../hooks/useSelector";
import { useRequest } from "../../hooks/useRequest";
import { useTranslation } from "../../hooks/useTranslation";
import { api } from "../../config/api";


export const CodeAdd : React.FC<OrderEditType> = ({onClose}) => {

    const [enable, setEnable] = useState(true);
    const [form] = Form.useForm();
    const setting = useSelector(state => state.setting);
    const {json} = useRequest();
    const t = useTranslation();

    const handleClose = () => {
        setEnable(false);
        setTimeout(() => {
            onClose(true);
        }, 500);
    }

    const onFinishFailed = () => {
        message.warning({
            content: t('请确认数据正确后在提交'),
        });
    };
    const onFinish = (values: CodeType) => {

        let data: CodeType = {...values}
        
        let update = async() => {
            let result = await json.post(api.orderCode.create, data);
            message.success(t(result.msg));
            if(result.status){
                setEnable(false);
                handleClose();
            }
        }

        update();
    };

    const formHtml = (
        <div className="edit-form-wrap">
            <Form<CodeType> form={form} labelCol={{span: 6}} wrapperCol={{span:18}} 
                size={setting.size}
                onFinishFailed={onFinishFailed}
                onFinish={onFinish}
            >   
                <Form.Item
                    label={t('厂商')}
                    name="type"
                    rules={[{ required: true, message:  t("厂商不能为空")}]}
                >
                    <Select allowClear>
                        {types.map(i => (
                            <Select.Option value={i.value} key={i.value}>
                                {i.label}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    label={t('起始编号')}
                    name="startCode"
                    rules={[{ required: true, message:  t("起始编号不能为空")}]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label={t('结束编号')}
                    name="endCode"
                    rules={[{ required: true, message:  t("结束编号不能为空")}]}
                >
                    <Input />
                </Form.Item>
            </Form>
        </div>
    );

    let title =t('批量生成单号')

    return (
        <Modal visible={enable}
            title={title}
            onOk={()=>{form.submit()}}
            onCancel={()=>handleClose()}
            afterClose={()=>handleClose()}
        >
            {formHtml}
        </Modal>
    )
}