
export const useTableContentFilter = () => {
    
    let tableContentFilter = (datas, key) => {
        if(!datas || datas.length == 0){
            return {};
        }
    
        const values = Array.from(new Set(datas.map(v => v[key])));
        
        return {
            filters: values.map(v => {return {text: v, value: v, key: v}}),
            onFilter: (value, record) => record[key].includes(value),
        }

    }

    return tableContentFilter;
}