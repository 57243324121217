import { createSlice, PayloadAction,createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {api} from "../config/api";

export interface LangType{
    id: number | undefined;
    name: string | undefined;
    label: string | undefined;
}

export interface LanguageState {
    loaded: boolean,
    language: LangType | null,
    languageList: LangType[];
}


const defaultState: LanguageState = {
    loaded: false,
    language: null, //{ id: 1, label: "简体中文", name: "zh-cn" }
    languageList: [
        // { id: 1, label: "简体中文", name: "zh-cn" },
        // { id: 2, label: "繁体中文", name: "zh-tw" },
    ],
};


export const getLangs = createAsyncThunk(
    "lang/langs",
    async (p, thunkAPI) => {
        const {data} = await axios.get(api.i18n.langs);
        return data.data;
    }
);

export const langSlice = createSlice({
    name: 'lang',
    initialState: defaultState,
    reducers: {
        changeLang: (state, action: PayloadAction<LangType>)=>{
            state.language = action.payload;
        }
    }, 
    extraReducers: {
        [getLangs.fulfilled.type]: (state, action: PayloadAction<LangType[]>) => {
            let languageList = action.payload;
            let langCode = localStorage.getItem("i18nextLng") || window.navigator.language || 'zh-cn';
            langCode = langCode.toLocaleLowerCase();
            
            let lang = languageList.find( lng => lng.name == langCode);
            if(!lang){
                lang = languageList[0];
            }

            state.language = lang;
            state.languageList = languageList;

            // console.log(state.languageList);
        },
    },
});

