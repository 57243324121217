/**
 * 提交换货
 */
import {Header,AuthButton} from '../../components';
import {Typography, Form, Input, message, Col, Row,Image,Modal}from 'antd';
import { useRequest,useTranslation} from '../../hooks';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { api } from "../../config/api";
import { useSelector } from "../../hooks/useSelector";
import example from '../../assets/img/example-code.jpg';

export const Exchange: React.FC = () => {

   const t = useTranslation();
   const {request} = useRequest();
   const [form] = Form.useForm();
   const setting = useSelector(state => state.setting);

   const onFinishFailed = () => {
      message.warning({
          content: t('请确认数据正确后在提交'),
      });
   };

  const onFinish = (values : any) => {

      let data = { 
          ...values,type:1
       }

      let url = api.index.expBackRefund;
      let update = async() => {
         let result = await request.post(url, data);
         // message.success(t(result.msg));
         let text;
         if(result.status){
            form.resetFields(); 
            text = "提交申請成功， 您的申請編號為："+result.data+", 您可以使用該編號，前往“查詢申請”頁面，查詢處理結果";
         }else{
            text = result.msg;
         }
         //弹窗提醒
         Modal.confirm({
            title: t('提示：'),
            icon: <ExclamationCircleOutlined />,
            content: text,
            okText: '确认',
            cancelText: '取消',
          });

      }
      update();
  };

   return (
      <div>
         <Header />
         <div className="index-wrap">
            <div className="index-inner" style={{backgroundImage: 'linear-gradient(to bottom, #7FC0F1, #F1F1FE)',width: '100%'}}>
               <Typography.Title level={3} style={{textAlign: 'center'}}>{t('哈囉! 這是昶禹國際物流第三方客服〈代寄〉')}</Typography.Title>
               <Typography.Title level={5} style={{textAlign: 'center',marginTop: '-10px'}}>{t('温馨提示：陞航不是賣家，是代寄')}</Typography.Title>
            </div>
            <div className="tableForm">
               <Typography.Title level={5} style={{textAlign: 'center'}}>{t('包裹客訴  換貨  申請表')}</Typography.Title>
               <div className="edit-form-wrap">
                  <Form<any> form={form} labelCol={{span: 8}} wrapperCol={{span:16}} 
                     size={setting.size}
                     onFinishFailed={onFinishFailed}
                     onFinish={onFinish}
                     labelAlign="right"
                  >   
                     <Row>
                        <Col sm={24} md={12}  lg={20}>
                              <Form.Item
                                 label={t('電子郵件')}
                                 name="email"
                                 rules={[{ required: true, message:  t("電子郵件不能為空")}]}
                              >
                              <Input allowClear/>
                              </Form.Item>
                        </Col>
                     </Row>
                     <Row style={{marginTop:'-10px'}}>
                        <Col sm={24} md={12}  lg={20}>
                              <Form.Item
                                 label={t('溫馨提示')}
                                 name="email"
                              >
                              <Typography.Text mark style={{textAlign: 'center'}}>{t('後續有任何問題會以郵件通知，請填寫正確郵件避免沒有收到信件影響您的處理進度!!  謝謝')}</Typography.Text>
                              </Form.Item>
                        </Col>
                     </Row>
                     <Row>
                        <Col sm={24} md={12}  lg={20}>
                              <Form.Item
                                 label={t('配送編號')}
                                 name="expCode"
                                 rules={[{ required: true, message:  t("配送編號不能為空")}]}
                              >
                                 <Input allowClear/>
                              </Form.Item>
                        </Col>
                     </Row>
                     <Row>
                        <Col sm={24} md={12}  lg={20}>
                              <Form.Item
                                 label={t('示例')}
                              >
                                 <Image src={example} />
                              </Form.Item>
                        </Col>
                     </Row>
                     <Row>
                        <Col sm={24} md={12}  lg={20}>
                              <Form.Item
                                 label={t('訂購商品')}
                                 name="productName"
                                 rules={[{ required: true, message:  t("訂購商品不能為空")}]}
                              >
                                 <Input allowClear/>
                              </Form.Item>
                        </Col>
                     </Row>
                     <Row>
                        <Col sm={24} md={12}  lg={20}>
                              <Form.Item
                                 label={t('司機上門取件地址')}
                                 name="getProductAddr"
                                 rules={[{ required: true, message:  t("取件地址不能為空")}]}
                              >
                              <Input placeholder={'司機取件的公司或住家地址'} allowClear/>
                              </Form.Item>
                        </Col>
                     </Row>
                     <Row style={{marginTop:'-10px'}}>
                        <Col sm={24} md={12}  lg={20}>
                              <Form.Item
                                 label={t('溫馨提示')}
                                 name="email"
                              >
                              <Typography.Text mark style={{textAlign: 'center'}}>{t('請務必提供住家或公司地址,地址錯誤無法取件將影響退換貨權益')}</Typography.Text>
                              </Form.Item>
                        </Col>
                     </Row>
                     <Row>
                        <Col sm={24} md={12}  lg={20}>
                              <Form.Item
                                 label={t('換貨原因')}
                                 name="reason"
                                 rules={[{ required: true, message:  t("退貨原因不能為空")}]}
                              >
                                 <Input allowClear/>
                              </Form.Item>
                        </Col>
                     </Row>
                     <Row style={{marginTop:'-10px'}}>
                        <Col sm={24} md={12}  lg={20}>
                              <Form.Item
                                 label={t('請注意')}
                                 name="email"
                              >
                              <Typography.Text mark style={{textAlign: 'center'}}>{t('換貨申請我們不會幫您追蹤處理狀態，若七天內未得到處理請自行申請退款處理。謝謝')}</Typography.Text><br></br>
                              </Form.Item>
                        </Col>
                     </Row>
                     <Row>
                        <Col sm={24} md={12}  lg={20}>
                           <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                              <AuthButton type="primary" onClick={()=>{form.submit()}}>
                                 {t('提交')}
                              </AuthButton>
                           </Form.Item>
                        </Col>
                     </Row>
                  </Form>
               </div>
            </div>
         </div>
      </div>
   );

}