//编辑语言
import { ResEditType } from "./";
import { Modal } from '../../components'
import { useState } from "react";
import {Form, Input,message, Typography } from 'antd';
import { useTranslation } from "../../hooks/useTranslation";
import { useSelector } from "../../hooks/useSelector";
import { useRequest } from "../../hooks/useRequest";
import { api } from "../../config/api";

export const ResourceEdit : React.FC<ResEditType> = ({type, resources, onClose}) =>{

    // const [parentId1, setParentId1] = useState(parentId || 0);
    const [enable, setEnable] = useState(true);
    const t = useTranslation();
    const [form] = Form.useForm();
    const setting = useSelector(state => state.setting);
    const {json} = useRequest();
    const [res] = useState({resources: resources});

    const handleClose = () => {
        setEnable(false);
        setTimeout(() => {
           onClose && onClose(false);
        }, 500);
    }

    const onFinishFailed = () => {
        message.warning({
            content: t('请确认数据正确后在提交'),
        });
    };

    const onFinish = (values) => {
        // console.log(values);
        let datas = [...values["resources"]];
        
        datas.forEach(d => {
            d.name = values.name;
        });

        // console.log(data);
        let url = api.langRes.updateResource;
        
        let update = async() => {
            let result = await json.post(url, datas);
            message.success(t(result.msg));
            if(result.status){
                setEnable(false);
                onClose && onClose(true);
            }
        }

        update();
    };
    
    const formHtml = (
        <div className="edit-form-wrap">
            <Form form={form} labelCol={{span: 6}} wrapperCol={{span:18}} 
                size={setting.size} initialValues={res}
                onFinishFailed={onFinishFailed}
                onFinish={onFinish}
            >   
                 <Form.Item
                    label={t('关键字')}
                    name="name"
                    initialValue={resources && resources[0]['name']}
                    rules={[{ required: true, message:  t("关键字名不能为空")}]}
                >
                    <Input />
                </Form.Item>
                <Form.List name="resources">
                    {(fields) => (
                        <>
                            {fields.map(({ key, name, fieldKey, ...restField }) => (
                                <>
                                <Typography.Title key={key} style={{textAlign: 'center'}} level={5}>
                                    {resources && resources[key]['langLabel']}
                                </Typography.Title>
                                <Form.Item
                                    label= {t('内容')}
                                    {...restField}
                                    name={[name, 'msg']}
                                    fieldKey={[fieldKey, 'msg']}
                                    rules={[{ required: true, message: t('内容不能为空') }]}
                                >
                                    <Input  />
                                </Form.Item>
                                </>
                            ))}
                        </>
                    )}
                </Form.List>
            </Form>
        </div>
    );

    let title = type==1?t('新增资源') : t('编辑资源')
        
    return (
        <Modal visible={enable}
            title={title}
            onOk={()=>{form.submit()}}
            onCancel={()=>handleClose()}
            afterClose={()=>handleClose()}
        >
            {formHtml}
        </Modal>
    )
} 