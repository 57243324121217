import './ExpressBack.less';

import {Typography, Space, Row, Col,  Switch,
    Tag,message, Modal,Menu,TablePaginationConfig
} from 'antd';
import {  EditOutlined, MailOutlined, DeleteOutlined, FileExcelOutlined, EyeOutlined,ExclamationCircleOutlined
} from '@ant-design/icons';
import { useSelector, useTranslation, useTable, TableType,
    useAuth, useRequest, useExport, CellFormatter
} from '../../hooks';
import { SearchForm, SearchItemProp, DeliverTypes,ChBools,
    AuthDropdown as Dropdown,AuthButton as Button
} from '../../components';
import { ExpressBackStatuses,ExpressBackTypes, ExpressBackStatuses1,SendMailStatus,
    ExpBackStatusUpdateType, ExpBackEditType,ExpBackStatusesUpdateType,ExpressSubmit,DeliverType
} from './ExpressBackType';
import { api } from '../../config/api';
import { useState } from 'react';
import { UpdateStatus, UpdateAmount, UpdateApplicant, UpdateRemark, ExpressDetail,UpdateStatuses } from '../';
import { UserSelect, UserSelectType } from '../user/UserSelect';

export const ExpressBack: React.FC = () => {
    const t = useTranslation();
    const setting = useSelector(state => state.setting);
    const layoutInfo = useSelector(state => state.layoutInfo);
    const access = useAuth();
    const {request} = useRequest();
    const exportKit = useExport();

    const [statusVisible, setStatusVisible] = useState(false);
    const [updateStatusObj, setUpdateStatusObj] = useState<ExpBackStatusUpdateType>({setVisible: setStatusVisible, expBacks: []});

    const [applicantInfoVisible, setApplicantInfoVisible] = useState(false);
    const [amountVisible, setAmountVisible] = useState(false);
    const [remarkVisible, setRemarkVisible] = useState(false);
    const [editObj, setEditObj] = useState<ExpBackEditType>(
        {setVisible: setApplicantInfoVisible, expBack: {}}
    );
    const [expDetailVisible, setExpDetailVisible] = useState(false);
    const [expCode, setExpCode] = useState<string>('');
    
    const [selectUserVisible, setSelectUserVisible] = useState(false);
    const [useSelectObj, setUserSelectObj] = useState<UserSelectType>();
    const [query, setQuery] = useState<any | null>();
    const [editCell, setEditCell] = useState(false);

    const searchItems: SearchItemProp[] = [
        {label: '订单状态', name: 'status', type: {options: ExpressBackStatuses}},
        {label: "物流厂商", name: 'seller', type: "input"},
        {label: "配送编码", name: 'expCode', type: 'input'},
        {label: "配送编码", name: 'expCodes', type: 'textArea'},
        {label: '申请时间', name: "Date", type: "datepicker", format:"YYYY-MM-DD"},
        {label: '完成时间', name: "finished", type: "datepicker", format:"YYYY-MM-DD"},
    ]

    const findCell = (tag: HTMLElement | null ) : (HTMLElement | null)=> {
        if(tag == null) {
            return tag;
        }else if(tag.tagName.toLocaleLowerCase() == 'td'){
            return tag;
        }else{
            return findCell(tag.parentElement);
        }
    }

    const tableRowClick = (event, row, rowIndex) => {

        let tdTag = findCell(event.target);
        if(tdTag){
            let td = tdTag as HTMLElement;
            let dataIndex = td.getAttribute("data-index");
            if(editCell){
                if("status" === dataIndex && access.has("expBack:status")){
                    updateStatus( [row]);
                }else if("applicantInfo" === dataIndex && access.has("expBack:updateApplicant")){
                    handleApplicantInfo(row, rowIndex);
                }else if("amount" === dataIndex && access.has("expBack:updateAmount")){
                    handleAmount(row, rowIndex);
                }else if("remark" === dataIndex && access.has("expBack:updateRemark")){
                    handleRemark(row, rowIndex);
                }else if("seller" === dataIndex && access.has("expBack:sellers")){
                    openSelecteSeller( [row]);
                }
            }
        }
    }
    //批量更新物流状态
    const batchUpdateStatus = () => {
        let selecteds = table.getSelectDatas();
        if(selecteds.length===0){
            message.warning(t("请选中一条数据后再进行操作"));
            return;
        }
        updateStatus(selecteds);
    }

    const [statusesVisible, setStatusesVisible] = useState(false);
    const [updateStatusesObj, setUpdateStatusesObj] = useState<ExpBackStatusesUpdateType>({
        onClose: (type)=>{
            setStatusesVisible(false);
        }
    });
    const batchUpdateStatuses = () => {
        setUpdateStatusesObj({onClose: updateStatusesObj.onClose});
        setStatusesVisible(true);
    }

    //更新物流对象
    const updateSellers = async (expBacks: any[], seller: any)=>{
        let data = await request.post(api.expBack.updateSellers, "ids="+expBacks.map(s => s.id).join(",")+"&id="+seller.id+"&name="+seller.fullname);
        if(data.status){
            message.success(t(data.msg));

            expBacks.forEach(e => {
                e.seller = seller.fullname;
                e.sellerId = seller.id;
            });

        }else{
            message.warning(t(data.msg));
        }
    }

    const batchUpdateSeller = () => {
        let selecteds = table.getSelectDatas();
        if(selecteds.length===0){
            message.warning(t("请选中一条数据后再进行操作"));
            return;
        }
        openSelecteSeller(selecteds);
    }

    //打开选择用户窗口
    const openSelecteSeller = (rows: any[]) =>{
        // console.log( row , rowIndex);
        // row.status = 4;
        setUserSelectObj({
            setVisible: setSelectUserVisible,
            type: 'radio',
            Ok: (users)=>{
                updateSellers(rows, users[0]);
            }
        });
        setSelectUserVisible(true);
    }

    //修改状态
    const updateStatus = (rows: any[]) =>{
        // console.log( row , rowIndex);
        // row.status = 4;
        setUpdateStatusObj({
            setVisible: setStatusVisible,
            expBacks: rows
        });
        setStatusVisible(true);
    }

    //修改申请人信息
    const handleApplicantInfo = (row, rowIndex) => {
        // console.log( row , rowIndex);
        setEditObj({setVisible: setApplicantInfoVisible, expBack: row});
        setApplicantInfoVisible(true);
    }
    
    //修改金额
    const handleAmount = (row, rowIndex) => {
        setEditObj({setVisible: setAmountVisible, expBack: row});
        setAmountVisible(true);
    }
    
    //修改备注
    const handleRemark = (row, rowIndex) => {
        // console.log( row , rowIndex); 
        setEditObj({setVisible: setRemarkVisible, expBack: row});
        setRemarkVisible(true);
    }

    //发送邮件
    const sendEmail = () =>{
        let selecteds = table.getSelectDatas();
        if(selecteds.length===0){
            message.warning(t("请选中一条数据后再进行操作"));
            return;
        }

        let doSend = async () => {
            let data = await request.post(api.expBack.sendMail, "ids="+selecteds.map(s => s.id).join(","));
            if(data.status){
                message.success(t(data.msg));
                table.remoteSearch(query);
            }else{
                message.warning(t(data.msg));
            }
        }
        doSend();
    }
    
    //删除
    let doDelete = async (selecteds: any[]) => {
        let data = await request.post(api.expBack.deletes, "ids="+selecteds.map(s => s.id).join(","));
        if(data.status){
            message.success(t(data.msg));
            table.remoteSearch(query);
        }else{
            message.warning(t(data.msg));
        }
    }

    //删除
    const handleDelete = () => {
        let selecteds = table.getSelectDatas();
        if(selecteds.length===0){
            message.warning(t("请选中一条数据后再进行操作"));
            return;
        }

        Modal.confirm({
            content: t("确定要删除["+selecteds.map(s => s.id).join(",")+"]吗？"),
            okText: t('确定'),
            okType: 'danger',
            cancelText: t('取消'),
            onOk: ()=>{
                doDelete(selecteds);
            }
        });
    }

    const formats: Map<number, CellFormatter> = new Map();
    
    //类型处理
    formats.set(16, (row, cell)=>{
        let type = ExpressBackTypes.find(s => s.value == cell);
        return type? type.label: cell
    });
    formats.set(17, (row, cell)=>{
        let type = ExpressBackStatuses.find(s => s.value == cell);
        return type? type.label: cell
    });
    formats.set(18, (row, cell)=>{
        let type = ExpressSubmit.find(s => s.value == cell);
        return type? type.label: cell
    });
    formats.set(22, (row, cell)=>{
        let type = SendMailStatus.find(s => s.value == cell);
        return type? type.label: cell
    });
    formats.set(23, (row, cell)=>{
        let type = ExpressBackStatuses1.find(s => s.value == cell);
        return type? type.label: cell
    });
    formats.set(28, (row, cell)=>{
        let type = DeliverType.find(s => s.value == cell);
        return type? type.label: cell
    });

    let titles =  [
        "ID", t("电子邮箱"), t('申请人姓名'),  t("电话"),
        t('配送编号'),t('商品名'),t('退货原因'),
        t('司机上门收货地址'),t('退款银行'),t('退款银行编号'),
        t('银行账号'),t('申请时间'),t('完成时间'),
        t('退款完成时间'),t('处理中邮件发送时间'),t('处理完成发送邮件'),
        t('类型'),t('状态'),t('是否已提交过'),
        t('申请编号'),t('物流对象'),t('物流對象id'),
        t('是否已发送处理中邮件'),t('是否已发送处理完成邮件'),t('备注'),
        t('处理人ID'),t('金额'),t('处理人姓名'),t('配送类型')
    ];

    const exportCurrPage = () => {
        
        // exportKit
        // let table = document.querySelector(".xm-table-exp-back");
        // if(table){
        //     exportKit.byTable(table, t("退件导出")+new Date().getTime()+".xlsx");
        // }
      
        let pageInfo = table.pageInfo as TablePaginationConfig;
        exportKit.byAjax({
            url: api.expBack.export,
            query: {
                //@ts-ignore
                start: (pageInfo.current-1)*pageInfo.pageSize,
                length: pageInfo.pageSize,
                ...query
            },
            extra: (result) => result.data,
            dataType: {
                filename: t("退件导出")+new Date().getTime()+".xlsx",
                titles: titles,
                formats: formats
            }
        });
    }

    const exportAll = () => {

        if(query==null || Object.keys(query).length == 0){
            Modal.confirm({
                content: t("没有任何查询条件，可能导致系统卡死，是否继续操作"),
                okText: t('确定'),
                okType: 'danger',
                cancelText: t('取消'),
                onOk: ()=>{
                    doExportAll();
                }
            });
        }else if(table.pageInfo['total'] > 40000){
            Modal.confirm({
                content: t("数据量过大，可能导致浏览器卡死，是否继续操作"),
                okText: t('确定'),
                okType: 'danger',
                cancelText: t('取消'),
                onOk: ()=>{
                    doExportAll();
                }
            });
        }else{
            doExportAll();
        }
    }

    const doExportAll = () => {
        let pageInfo = table.pageInfo as TablePaginationConfig;
        exportKit.byAjax({
            url: api.expBack.export,
            query: {
                start:  0,
                //@ts-ignore
                length: pageInfo.total,
                ...query
            },
            extra: (result) => result.data,
            dataType: {
                filename: t("退件导出")+new Date().getTime()+".xlsx",
                titles: titles,
                formats: formats
            }
        });
    }

    //查看物流
    const viewExpress = () => {
        let selecteds = table.getSelectDatas();
        if(selecteds.length===0){
            message.warning(t("请选中一条数据后再进行操作"));
            return;
        }

        if(!selecteds[0].expCode){
            message.warning(t("该退件无物流信息"));
            return;
        }
        setExpCode(selecteds[0].expCode);
        setExpDetailVisible(true);

    }

    const showTotal = () => {
        let selecteds = table.getSelectDatas();
        let text = "您当前选中："+selecteds.length+" 条";
        Modal.confirm({
            title: t('提示：'),
            icon: <ExclamationCircleOutlined />,
            content: text,
            okText: '确认',
            cancelText: '取消',
          });
    }

    const dropdownMenu = (
        <Menu>
            <Menu.Item key="1" onClick={exportCurrPage} icon={<FileExcelOutlined />}>
                {t("导出当前页")}
            </Menu.Item>
            <Menu.Item key="2" onClick={exportAll} icon={<FileExcelOutlined />}>
                {t("导出所有")}
            </Menu.Item>
        </Menu>
    );

    let y = layoutInfo.isMin? layoutInfo.content-345 : layoutInfo.content-280;
    if(y<250){
        y = 250;
    }

    const tableProp: TableType = {
        rowKey: "id",
        pagenation: true,
        url: api.expBack.list,
        scroll: {y:y},
        clickRow: tableRowClick,
        tableClassName: "xm-table-exp-back",
        columns: [
            {title: t("ID"), key: "id", width:80, sorter: true, type: 'checkbox', fixed: 'left'},
            {width:150, key: "status", sorter: true, filter: 'select', filterOptions: ExpressBackStatuses, 
                title: ()=>{return (
                    <>
                        {t("订单状态")}
                        {(access.has("expBack:status") && editCell)? <Typography.Link><EditOutlined /></Typography.Link> : (<></>)}
                    </>)},
                onCell: () => {
                    return {
                        "data-index": "status"
                    }
                }, 
                render: (text, row, index) => {
                    let option = ExpressBackStatuses.find(s => s.value == text);
                    let html =  option?(<Tag color={option.color}>{option.label}</Tag>):({text});
                    return <div style={{cursor: 'pointer', height:"100%", width:'100%'}}>
                        {html}
                        {row.handlerName && <Typography.Link type="success" style={{display: 'block'}}>{row.handlerName}</Typography.Link>}
                        {row.finished && <Typography.Text type="success" style={{display: 'block'}}>{row.finished}</Typography.Text>}
                    </div>
            }},
            {width:130, key: "code", sorter: true, filter: 'search',
                title: ()=>{return (
                    <>
                        {t("退件单号/配送单号")}
                        {(access.has("expBack:sellers") && editCell)? <Typography.Link><EditOutlined /></Typography.Link> : (<></>)}
                    </>)},
                onCell: () => {
                    return {
                        "data-index": "seller"
                    }
                }, 
                render: (text,row, index)=>{
                    return (<>
                        <Typography.Link href="#" style={{display: 'block'}}>{row.code}</Typography.Link>
                        <Typography.Text style={{display: 'block'}}>{row.seller}</Typography.Text>
                        <Typography.Text type="success" style={{display: 'block'}}>{row.expCode}</Typography.Text>
                    </>)
                }
            },
            {title: t("订单类型"), width:120, key: "type", sorter: true, filter: 'select', filterOptions: ExpressBackTypes, 
                render: (text, row, index) => {
                    let option = ExpressBackTypes.find(s => s.value == text);
                    return option?(<Tag color={option.color}>{option.label}</Tag>):({text});
            }},
            {width:200, key: "email", sorter: true,  filter: 'search',
                title: ()=>{return (
                <>
                    {t("申请信息")}
                    {(access.has("expBack:updateApplicant") && editCell)? <Typography.Link><EditOutlined /></Typography.Link> : (<></>)}
                </>)},
                onCell: () => {
                    return {
                        "data-index": "applicantInfo"
                    }
                },
                render: (text, row, index) => {
                    return (<div style={{cursor: 'pointer', height:"100%", width:'100%'}}>
                        <Typography.Text style={{display: 'block'}} strong>{row.fullname} / {row.tel}</Typography.Text>
                        <Typography.Link href="#" style={{display: 'block'}}>{row.email}</Typography.Link>
                        <Typography.Text type="success" style={{display: 'block'}}>{row.created}</Typography.Text>
                    </div>)
                }
            },
            {width:140, key: "productName", sorter: true, 
                title: ()=>{return (
                    <>
                        {t("货物名/金额")}
                        {(access.has("expBack:updateAmount") && editCell)? <Typography.Link><EditOutlined /></Typography.Link> : (<></>)}
                    </>)
                },
                onCell: () => {
                    return {
                        "data-index": "amount"
                    }
                },
                render: (text,row, index)=>{
                    let deliverType = row.deliverType;
                    let option = DeliverTypes.find(s => s.value == deliverType);
                    return (<div style={{cursor: 'pointer', height:"100%", width:'100%'}}>
                        {option && <Tag color={option?.color}>{option?.label}</Tag>}
                        <Typography.Text style={{display: 'block'}} strong>{row.productName}</Typography.Text>
                        <Typography.Link href="#" style={{display: 'block'}}>{t("￥")}{row.amount}</Typography.Link>
                    </div>)
                }
            },
            {title: t("退换原因"),  width:150, key: "reason", sorter: true, filter: 'search'},
            {title: t("邮件发送状态"),  width:200, key: "hasFinishSendMail", sorter: true, filter: "select", filterOptions: ExpressBackStatuses1,
                render: (text, row, index) => {
                    let hasSendMail = row['hasSendMail'] || 0;
                    let option = ChBools.find(s => s.value == hasSendMail);
                    let html = option?(<Tag color={option.color}>{option.label}</Tag>):({text});

                    let status = row['status'];
                    let statusOption = ExpressBackStatuses1.find(s => s.value == status);

                    let html1 = statusOption?(<Tag color={statusOption.color}>{statusOption.label}</Tag>):("")

                    return (<>{html}
                        <Typography.Text type="success" style={{display: 'block'}}>{row.mailSendDate}</Typography.Text>
                        {html1}
                    </>);
            }},
            {title: t("上门取件地址"),  width:150, key: "getProductAddr", sorter: true},
            {title: t("退款银行"),  width:150, key: "bankName", sorter: true, 
                render: (text,row, index)=>{
                    return (<>
                        <Typography.Text style={{display: 'block'}} strong>{row.bankName} {row.bankCode && " / "+row.bankCode}</Typography.Text>
                        <Typography.Link href="#" style={{display: 'block'}}>{row.bankAcount}</Typography.Link>
                        <Typography.Text type="success" style={{display: 'block'}}>{row.backAmountDate}</Typography.Text>
                    </>)
                }
            },
            {width:150, key: "remark", sorter: true, 
            title: ()=>{return (
                <>
                    {t("备注")}
                    {(access.has("expBack:updateRemark") && editCell)? <Typography.Link><EditOutlined /></Typography.Link> : (<></>)}
                </>)
            },
            onCell: () => {
                return {
                    "data-index": "remark"
                }
            }},
        ] 
    }

    const table = useTable(tableProp);

    const tableHtml = table.TableNode();

    const onSearch = (values) => {
        if(values && values['expCodes']){
            let cs = values['expCodes'].split(/\s+/);
            let css:any[]  = [];
            for(let i=0; i<cs.length; i++){
                if(cs[i]) css.push(cs[i]);
            }
            values['expCodes'] = css.join(",");
        }
        setQuery(values);
        table.remoteSearch(values);
    }

    return (
        <div>
            <SearchForm searchItems={searchItems} onSearch={onSearch}></SearchForm>
            <div className={`content-wrap ${layoutInfo.isMin?'content-wrap-min': ''}`} style={{marginTop: '10px'}}>
                <Row className="ctrl-wrap">
                    <Col span={24} >
                        <Space size={setting.size} wrap>
                            <Button icon={<EyeOutlined />} onClick={showTotal} type="primary">{t('总数')}</Button>
                            <Button permission="expBack:status" icon={<EditOutlined />} onClick={batchUpdateStatus} type="primary">{t('更新状态')}</Button>
                            <Button permission="expBack:status" icon={<EditOutlined />} onClick={batchUpdateStatuses} type="primary">{t('批量更新状态')}</Button>
                            <Button permission="expBack:sellers" icon={<EditOutlined />} onClick={batchUpdateSeller} type="primary">{t('更新物流对象')}</Button>
                            <Button permission="expBack:sendMail" icon={<MailOutlined />} onClick={sendEmail} type="primary">{t('发送邮件')}</Button>
                            <Button permission="express:detail" icon={<EyeOutlined />} onClick={viewExpress} type="primary">{t('查看物流')}</Button>
                            <Dropdown permission="expBack:export" btnTxt={t('导出')} btnIcon={<FileExcelOutlined />} overlay={dropdownMenu}></Dropdown>
                            <Switch checkedChildren={t("启用编辑")} size={setting.size == 'small'?'small':'default'} unCheckedChildren={t('关闭编辑')} onChange={(value) => setEditCell(value)}/>
                            <Button permission="expBack:delete" icon={<DeleteOutlined />} onClick={handleDelete} type="dashed" danger>{t('删除')}</Button>
                        </Space>
                    </Col>
                </Row>
                {tableHtml}
            </div>
            {statusesVisible && <UpdateStatuses {...updateStatusesObj}/>}
            {statusVisible && <UpdateStatus {...updateStatusObj}/>}
            {applicantInfoVisible && <UpdateApplicant {...editObj}/>}
            {amountVisible && <UpdateAmount {...editObj}/>}
            {remarkVisible && <UpdateRemark {...editObj}/>}
            {(selectUserVisible&&useSelectObj) ? <UserSelect {...useSelectObj}/> : <></>}
            {expDetailVisible && <ExpressDetail setVisible={setExpDetailVisible} codeType={2} code={expCode}/>}
        </div>
    )
}