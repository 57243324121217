/**
 * 文档说明
 * @returns 
 */
import {Row, Col,Typography} from 'antd';
import {useTranslation} from '../../hooks'
import {api} from '../../config/api'

export const Doc: React.FC = () => {

    const t = useTranslation();

    return (
        <>
            <Typography.Title style={{textAlign: 'center'}}>{t('系统文档')}</Typography.Title>
            <Row style={{padding: "20px"}}>
                {/* <Col span={24}>
                    <Typography.Link href={api.doc.printerInstaller} target="_blank" style={{margin: '15px', display: 'inline-block'}}>{t('打印插件下载（安装版）')}</Typography.Link>
                </Col>
                <Col span={24}>
                    <Typography.Link href={api.doc.printer} target="_blank" style={{margin: '15px', display: 'inline-block'}}>{t('打印插件下载（免安装版）')}</Typography.Link>
                </Col> */}
                <Col span={24}>
                    <Typography.Link href={api.doc.doNet} target="_blank" style={{margin: '15px', display: 'inline-block'}}>{t('.Net Framwork 4.5 下载')}</Typography.Link>
                </Col>
                <Col span={24}>
                    <Typography.Link href={api.doc.apiDoc} target="_blank" style={{margin: '15px', display: 'inline-block'}}>{t("api 文档下载")}</Typography.Link>
                </Col>
            </Row>
        </>
    );
}