import { Modal } from '../../components'
import { useEffect, useState } from "react";
import { useTranslation } from "../../hooks/useTranslation";
import { useRequest } from "../../hooks/useRequest";
import { api } from "../../config/api";
import {ExpressStatusDetailType} from "."
import {message} from 'antd';

export const ExpressStatusDetail: React.FC<ExpressStatusDetailType> = ({expId, setVisible}) => {
    // const [parentId1, setParentId1] = useState(parentId || 0);
    const [enable, setEnable] = useState(true);
    const t = useTranslation();
    const {json} = useRequest();

    const [express, setExpress] = useState<any[]>([]);

    const getExpress = async () => {
        let result = await json.get(api.express.statusDetail+"?expId="+expId);
        if(result.status){
            setExpress(result.data);
        }else{
            message.warning(t("无数据"));
        }
    }

    useEffect(()=>{
        getExpress();
    }, 
    // eslint-disable-next-line
    []);
    

    const handleCancel = () => {
        setEnable(false);
        setTimeout(() => {
            setVisible(false);
        }, 500);
    }

    const formHtml = (
        <div className="edit-form-wrap">
            {
                /* {JSON.stringify(express)} */
                <table> {express.map(item=>{
                return <tr>
                            {/* <td >{"<"+item.status+">"}</td> */}
                            <td >{"<"+item.remark+">"}</td>
                            <td >{"------"+item.created}</td>
                        </tr>
                })}</table>
            }
        </div>
    );

    let title =t('查看状态详情')
        
    return (
        <Modal visible={enable}
            title={title}
            width={800}
            onOk={handleCancel}
            onCancel={handleCancel}
            afterClose={handleCancel}
        >
            {formHtml}
        </Modal>
    )
}