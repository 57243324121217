import { Button, Result } from 'antd';
import React from 'react';
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const NoFoundPage: React.FC = () => {
  const history = useHistory();
  const {t} = useTranslation();
  return (
    <Result
      status="404"
      title="404"
      subTitle={t("页面不存在")}
      extra={
        <Button type="primary" onClick={() => history.push('/')}>
          {t("返回主页")}
        </Button>
      }
    />
  )
};

export default NoFoundPage;
