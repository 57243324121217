//附件管理
import './Attachment.less'
import { useState } from "react";
import {Space, Input,message, Row, Col,Modal, Form,Upload,
    Image
} from 'antd';
import { SearchOutlined,UploadOutlined, ReloadOutlined,DeleteOutlined,
    DoubleLeftOutlined
} from '@ant-design/icons';
import { api } from "../../config/api";
import { useSelector,useTranslation, 
    TableType, useTable,useRequest
 } from '../../hooks';
import { AuthButton as Button } from '../../components';

export const Attachment: React.FC = () => {

    const t = useTranslation();
    const setting = useSelector(state => state.setting);
    const [moreQuery, setMoreQuery] = useState(false);
    const [form] = Form.useForm();
    const {json} = useRequest();
    const layoutInfo = useSelector(state => state.layoutInfo);
    const [query, setQuery] = useState<any>();

    const tableProp: TableType = {
        rowKey: "id",
        pagenation: true,
        url: api.file.list,
        columns: [
            {title: t("ID"), key: "id", width:80, sorter: true, type: 'checkbox', fixed: 'left'},
            {title: t("缩略图"),  width:120, key:"owner", render: (text, row, index) => {
                let filename = (row['filename'] as string).toLocaleLowerCase();
                let ext = filename.substring(filename.lastIndexOf(".")+1);
                if("jpg|jpeg|png|gif".indexOf(ext)>-1){
                    return (
                        <Image  width={48}
                            src={api.file.view+"?id="+row['id']}
                        />
                    )
                }else{
                    return <a href={api.file.view+"?id="+row['id']}>{filename}</a>
                }
                
            }},
            {title: t("上传人"),  width:120, key: "ownerName", sorter: true, filter: 'select'},
            {title: t("创建时间"),  width:150,  key: "created", sorter: true, filter: 'search'},
            {title: t("文件名"), key: "filename", sorter: true, filter: 'search'},
        ] 
    }

    const table = useTable(tableProp);

    const tableHtml = table.TableNode();

    const onFinish = (values) => {
        let q: any = null;
        let keys = Object.keys(values).filter(k => values[k]?true: false);
        if(keys && keys.length>0){
            q = {};
            keys.forEach(k => q[k] = values[k]);
        }

        setQuery(q);
        table.remoteSearch(q);
    }

    const handleDelete = () => {
        let selecteds = table.getSelectDatas();
        if(selecteds.length===0){
            message.warning(t("请选中一条数据后再进行操作"));
            return;
        }
        Modal.confirm({
            content: t("确定要删除["+selecteds.map(s => s.filename).join(",")+"]吗？"),
            okText: t('确定'),
            okType: 'danger',
            cancelText: t('取消'),
            onOk: ()=>{
                
                const fireDelete = async () => {
                    let result = await json.get(api.file.deleted+"?ids="+selecteds.map(s => s.id).join(","));
                    if(result.status){
                        message.success(t(result.msg));  
                        table.remoteSearch(query); 
                    }else{
                        message.warning(t(result.msg))
                    }
                }
                fireDelete();
            }
        });
    }

    const props = {
        name: 'file',
        action: api.file.upload+"?type=common",
        withCredentials: true,
        showUploadList:false,
        onChange(info) {
            try{
                let res = info.fileList[info.fileList.length-1].response

                if(res.status){
                    message.success(t(res.msg));
                    table.remoteSearch(query); 
                }else{
                    message.warning(t(res.msg));
                }
            }catch(err){
                console.error(err);
            }
           
        }
    };
    
    const reset = ()=> {
        form.resetFields(); 
        setQuery({})
        table.remoteSearch({}); 
    }

    return (
        <div>
            <div className='search-wrap' >
                <Form form={form} labelCol={{lg: 8, md: 10}} wrapperCol={{lg: 16, md: 14}} 
                    size={setting.size}
                    onFinish={onFinish}
                    labelAlign="right"
                >   
                    <Row>
                        <Col className="query-col" sm={24} md={8} lg={6} >
                            <Form.Item 
                                label={t('创建人')}
                                name="name"
                            >
                                <Input allowClear/>
                            </Form.Item>
                        </Col>
                        {(moreQuery || !layoutInfo.isMin) && (
                        <Col className="query-col" sm={24} md={8} lg={6}  >
                            <Form.Item 
                                label={t('文件名')}
                                name="key"
                            >
                                <Input allowClear/>
                            </Form.Item>
                        </Col>
                        )}
                        <Col className="query-col" sm={24} md={8} lg={6}  >
                            <Space>
                                <Button htmlType="submit" icon={<SearchOutlined />} type="primary">{t('搜索')}</Button>
                                <Button onClick={reset} icon={<ReloadOutlined />} type="primary">{t('重置')}</Button>
                                {
                                    layoutInfo.isMin &&
                                    <Button icon={<DoubleLeftOutlined type="primary" 
                                        style={{transform: moreQuery? 'rotate(90deg)':'rotate(-90deg)'}}/>} 
                                        onClick={()=> setMoreQuery(!moreQuery)}
                                        >
                                            {t('高级')}
                                    </Button>
                                }
                            </Space>
                        </Col>
                    </Row>
                </Form>
            </div>
            <div className={`content-wrap ${layoutInfo.isMin?'content-wrap-min': ''}`} style={{marginTop: '10px'}}>
                <Row className="ctrl-wrap">
                    <Col span={24}>
                        <Space>
                            <Upload {...props}>
                                <Button permission="attachment:upload" type="primary" icon={<UploadOutlined />}>{t('上传文件')}</Button>
                            </Upload>
                            <Button permission="attachment:delete" onClick={handleDelete} icon={<DeleteOutlined />} danger>{t('删除')}</Button>
                       </Space>
                    </Col>
                </Row>
                {tableHtml}
            </div>
        </div>    
    )
}