import { GrantType } from "./RoleType";
import { Icon, Modal } from '../../components'
import { useEffect, useState } from "react";
import {Tree,message, Modal as AntdModal} from 'antd';
import { useTranslation } from "../../hooks/useTranslation";
import { useSelector } from "../../hooks/useSelector";
import { useRequest } from "../../hooks/useRequest";
import { api } from "../../config/api";

interface TreeType {
    title: string;
    key: string;
    icon: string;
}


export const GrantMenu : React.FC<GrantType> = ({roleId, roleLabel, onClose}) => {

    // const [parentId1, setParentId1] = useState(parentId || 0);
    const [enable, setEnable] = useState(true);
    const t = useTranslation();
    const user = useSelector(state => state.user);
    const {json} = useRequest();

    //选中的数据
    const [checkedKeys, setCheckedKeys] = useState<{checked: any[], halfChecked: any[]}>({
        checked: [],
        halfChecked: []
    });

    //获取当前窗体高度，不做动态监听，主要针对手机
    let height = document.documentElement.clientHeight;
    let width = document.documentElement.clientWidth;
    if(width < 500){
        height = height - 250;
    }else{
        height = height - 350;
    }
    if(height<300){
        height = 300;
    }

    const handleClose = () => {
        setEnable(false);
        setTimeout(() => {
            onClose();
        }, 500);
    }

    const okHandle = () => {
        if(checkedKeys.checked.length == 0){
            AntdModal.confirm({
                content: t("确定要清空角色["+roleLabel+"]菜单吗？"),
                okText: t('确定'),
                okType: 'danger',
                cancelText: t('取消'),
                onOk: ()=>{
                    fireGrant();
                }
            });
        }else{
            fireGrant();
        }
    }

    const fireGrant = async () => {
        let roleMenus: any[] = [];

        checkedKeys.halfChecked.forEach(id => {
            roleMenus.push({
                id: id,
                type: 0,
            });
        });

        checkedKeys.checked.forEach(id => {
            roleMenus.push({
                id: id,
                type: 1,
            });
        });

        let result = await json.post(api.role.grantMenu, {
            roleId: roleId,
            menus:roleMenus
        });

        if(result.status){
            message.success(t(result.msg));
            onClose();
        }else{
            message.warning(t(result.msg));
        }
    }

    const onCheck = (checkedKeysValue, event) => {
        // console.log('onCheck', checkedKeysValue, event.halfCheckedKeys);
        setCheckedKeys({
            checked: checkedKeysValue,
            halfChecked: event.halfCheckedKeys
        });
    };

    //菜单数据
    const [menus, setMenus] = useState<TreeType[]>([]);
    
    //获取菜单
    const getAll = async () => {
        
        let result = await json.get(api.menu.toggle+"?resultType=1&id="+user.roleId);   
        if(result.status){

            let rebuild = (menus) => {
                let arr: any[] = [];

                menus.forEach(m => {

                    let nm:any = {
                        id: m.id,
                        key: m.id,
                        title: m.label,
                        icon: <Icon icon={m.icon} />,
                        children: []
                    }

                    arr.push(nm);
                    
                    if(m['children'] && m.children.length>0){
                        nm.children=rebuild(m.children);
                    }
                })
                return arr;
            }
            let newmenus = rebuild(result.data);
            setMenus(newmenus);

        } else{
            message.warning(t(result.msg))
        }
    }

    const getSelects = async () => {
        let result = await json.get(api.menu.toggle+"?type=1&id="+roleId);
        if(result.status){
            let keys = result.data.map(d => d.id);
            // console.log(keys)
            setCheckedKeys({...checkedKeys, checked: keys});
        }else{
            message.warning(t(result.msg))
        }
    }

    //初始化菜单数据
    useEffect(()=>{
        getAll();
        getSelects()
    }, 
    // eslint-disable-next-line 
    []);

    const bodyHtml = (
        <div className="grant-wrap">
            <Tree
                showIcon={true}
                checkable
                autoExpandParent={true}
                onCheck={onCheck}
                checkedKeys={checkedKeys}
                treeData={menus}
            />
        </div>
    );

    return (
        <Modal visible={enable}
            title={t('分配菜单')}
            onOk={okHandle}
            onCancel={()=>handleClose()}
            afterClose={()=>handleClose()}
        >
            {bodyHtml}
        </Modal>
    )

}