import { CopyrightOutlined } from '@ant-design/icons';
import {Typography} from 'antd';
import { useTranslation } from 'react-i18next';
import {Logo} from '../Logo';
import "./Footer.less"

interface PropType{
    inline?: string
}

export const Footer: React.FC<PropType> = ({inline}) => {
    const {t} = useTranslation();

    return (
        <div className={`h-footer${inline||''}`}>
                <Typography.Paragraph>
                    {/* <Typography.Text>
                        <CopyrightOutlined /> {t("星萌工作室")} 
                    </Typography.Text> */}
                    <Typography.Text className="f-logo">
                        <Logo /> {t('昶禹國際物流')}
                    </Typography.Text>
                </Typography.Paragraph>
                {/* <Typography.Paragraph className="h-footer-no">
                    {t('备案号')}
                </Typography.Paragraph> */}
        </div>
    )
}
