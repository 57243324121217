
export const compareObj = (objA: object, objB: object, key: string) : number =>{

    if(!objA) {
        if(!objB)
            return 0;
    }else{
        if(!objB){
            return -1;
        }
    }

    let s1 = objA[key];
    let s2 = objB[key];

    if(typeof(s1) === 'string'){
        return  s1.localeCompare(s2); 
    }else{
        return s1 - s2;
    }
}

export const ChBools = [
    {label: '是', value:1, color: '#87d068'},
    {label: '是', value:true, color: '#87d068'},
    {label: '否', value:0, color: '#f50'},
    {label: '否', value:false, color: '#f50'},
]

export const DeliverTypes = [
    {label: '货到付款', value:1, color: '#f50'},
    {label: '纯配送', value:3, color: '#87d068'},
]

export const companies = [
    {label: '7-11', value:'7-11', color: '#f50'},
    {label: '全家', value:'全家', color: '#f50'},
    {label: '黑猫', value:'黑猫', color: '#f50'},
    {label: 'OK', value:'OK', color: '#f50'},
    {label: '莱尔富', value:'莱尔富', color: '#f50'},
]