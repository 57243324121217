
import { useSelector } from "../hooks/useSelector"

interface PropType{
    style?: Object
}

export const Logo: React.FC<PropType> = ({style}) => {
    const logo = useSelector((state) => state.company.logo);


    return (
        <img alt='logo' style={style?style: {}} src={logo || ''}></img>
    )
}