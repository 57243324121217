import './Role.less';
import { Input,Table, Space, 
    TableColumnType, Row, Col, Select, message,Modal 
} from 'antd';
import { SearchOutlined,
    DeleteOutlined, PlusOutlined, EditOutlined,
    SecurityScanOutlined, MenuOutlined
} from '@ant-design/icons';
import { useTranslation } from '../../hooks/useTranslation';
import { useEffect, useState } from 'react';
import { useSelector } from '../../hooks/useSelector';
import { useRequest } from '../../hooks/useRequest';
import { api } from '../../config/api';
import { RoleEditType, RoleType, GrantType, 
    RoleEdit, GrantAuth, GrantMenu
} from './';
import { compareObj, AuthButton as Button } from '../../components';


export const Role: React.FC = () => {

    const t = useTranslation();
    const setting = useSelector(state => state.setting);
    const [datas, setDatas] = useState([]);
    const {json} = useRequest();
    const [pagenation, setPagenation] = useState({
        current: 1,
        pageSize: 20,
        total: 0,
    });
    const [name, setName] = useState<string | null>(null);

    //点击搜索回调
    const onSearch = () => {
        getDatas({current: 1, pageSize: 20});
    }

    //搜索框输入改变
    const onChange =(event) => {
        // console.log(event.target.value);
        setName(event.target.value);
    }

    //获取当前窗体高度，不做动态监听，主要针对手机
    let height = document.documentElement.clientHeight - 410;
    if(height<300){
        height = 300;
    }

    //选中的数据
    const [selectKeys, setSelectKeys] = useState<any[]>([]);
    const [selectRecords, setSelectRecords] = useState<any[]>([]);

    //从后台获取信息
    const getDatas = async (pageInfo) => {
        if(!pageInfo){
            pageInfo = pagenation;
        } 

        let query = {
            start: (pageInfo.current-1)*pageInfo.pageSize,
            length: pageInfo.pageSize
        }

        if(name){
            query['name'] = name;
        }

        let data = await json.post(api.role.list, query);
        if(data.status){
            let result = data.data;
            setDatas(result.datas);
            let pinfo = {
                total: result.total, 
                pageSize: result.length, 
                current: (result.start/result.length)+1
            };
            setPagenation(pinfo)
        }
    }  

    /**
     * 分页信息改变， 回调，这里只处理页面变化，
     */
    const handleTableChange = (p, filters, sorter) => {
        if(pagenation.current !==  p.current)
            getDatas(p);
    }

    //初始页面是加载数据
    useEffect(()=>{
        getDatas(null);
    },
    // eslint-disable-next-line
    []);

    //表格字段定义
    const cols: TableColumnType<RoleType>[] = [
        {title: t("id"), dataIndex: "id", key: "id", width:"100px"},
        {title: t("角色显示名"), dataIndex: "label", key: "label",
            sorter: (a, b) => compareObj(a, b, 'label'),
        },
        {title: t("角色名"), dataIndex: "name", key: "name",
            sorter: (a, b) => compareObj(a, b, 'name')
        },
        {title: t("创建者"), dataIndex: "createrName", key: "createrName",
            sorter: (a, b) => compareObj(a, b, 'createrName')
        },
    ];

    //表格行点击
    const rowClick = (record) => {
        return {onClick: (event) => {
            setSelectKeys([record.id])
            setSelectRecords([record])
        }}
    }

    //单选框选中回调
    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            //console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            setSelectKeys(selectedRowKeys)
            setSelectRecords(selectedRows)   
        },
    }

    //分页显示数量选择
    const pageSizes = [20, 50, 100, 200, 1000, 2000]
    const changePageSize = (val) => {
        getDatas({current: 1, pageSize: val});
    }

    //编辑表单 变量定义
    const [editVisible, setEditVisible] = useState(false);
    const [editObj, setEditObj] = useState<RoleEditType>({
        type: 1,
        role: {},
        onClose: (type)=>{
            if(type){
                getDatas(null);
            }
            setEditVisible(false);
        }
    });

    //处理新增，
    const handleAdd = () =>{
        setEditObj({...editObj,
            type: 1,
            role: {
                id: null,
                label: '',
                name: ''
            },
        });
        setEditVisible(true);
    }

    //打开编辑弹窗
    const handleEdit = () => {
        if(selectRecords.length===0){
            message.warning(t("请选中一条数据后再进行操作"));
            return;
        }
        let temp = selectRecords[0];
        setEditObj({type: 2, onClose: editObj.onClose, role: temp});
        setEditVisible(true);
    }

    //删除
    const handleDelete = () => {
        if(selectRecords.length===0){
            message.warning(t("请选中一条数据后再进行操作"));
            return;
        }
        Modal.confirm({
            content: t("确定要删除["+selectRecords[0].label+"]吗？"),
            okText: t('确定'),
            okType: 'danger',
            cancelText: t('取消'),
            onOk: ()=>{
                
                const fireDelete = async () => {
                    let result = await json.get(api.role.deleted+"?id="+selectRecords[0].id);
                    if(result.status){
                        message.success(t(result.msg));  
                        getDatas(null);  
                    }else{
                        message.warning(t(result.msg))
                    }
                }
                fireDelete();
            }
        });
    }

    //分配菜单是否显示
    const [grantMenuVisible, setGrantMenuVisible] = useState(false);
    //分配权限是否显示
    const [grantAuthVisible, setGrantAuthVisible] = useState(false);

    //定义分配菜单变量 | 定义分配权限变量
    const [grantProp, setGrantProp] = useState<GrantType>({
        roleId: 0,
        roleLabel: '',
        onClose: ()=> {
            setGrantMenuVisible(false);
            setGrantAuthVisible(false);
        }
    });

    //打开分配菜单
    const handleGrantMenu = () => {
        if(selectRecords.length===0){
            message.warning(t("请选中一条数据后再进行操作"));
            return;
        }
        let temp = selectRecords[0];
        setGrantProp({roleId:temp.id, roleLabel: temp.label, onClose: grantProp.onClose});
        setGrantMenuVisible(true);
    }

    //打开分配权限    
    const handleGrantAuth = () => {
        if(selectRecords.length===0){
            message.warning(t("请选中一条数据后再进行操作"));
            return;
        }
        let temp = selectRecords[0];
        setGrantProp({roleId:temp.id, roleLabel: temp.label, onClose: grantProp.onClose});
        setGrantAuthVisible(true);
    }

    return (
        <div>
            <div className='search-wrap'>
                <Input.Search style={{maxWidth: '500px'}}
                    allowClear
                    placeholder={t('角色名')}
                    enterButton={t('搜索')}
                    size={setting.size}
                    onChange={onChange}
                    suffix={<SearchOutlined />}
                    onSearch={onSearch}
                />
            </div>
            <div className="content-wrap">
                <Row>
                    <Col sm={24} md={12}>
                        <Space className="ctrl-box">
                            <Button permission="role:create" onClick={handleAdd} icon={<PlusOutlined />} type="primary">{t('新增')}</Button>
                            <Button permission="role:update" onClick={handleEdit} icon={<EditOutlined />} type="primary">{t('编辑')}</Button>
                            <Button permission="role:grantPermission" onClick={handleGrantMenu} icon={<MenuOutlined />} type="primary">{t('分配菜单')}</Button>
                            <Button permission="role:grantMenu" onClick={handleGrantAuth} icon={<SecurityScanOutlined />} type="primary">{t('分配权限')}</Button>
                            <Button permission="role:delete" onClick={handleDelete} icon={<DeleteOutlined />} danger>{t('删除')}</Button>
                        </Space>
                    </Col>
                    <Col sm={24} md={12} style={{textAlign: 'right'}} className="ctrl-box">
                            <label>{t('每页显示数量')}：</label>
                            <Select defaultValue={20} style={{ width: 120 }} size={setting.size} onChange={changePageSize}>
                                {pageSizes.map(pz => (
                                    <Select.Option value={pz} key={pz}>{pz}</Select.Option>
                                ))}
                            </Select>
                    </Col>
                </Row>
                <div className="table-box">
                    <Table<RoleType> rowKey="id"
                        rowSelection={{...rowSelection, type: 'radio', selectedRowKeys: selectKeys}}
                        scroll={{y: height, x: 400}}
                        bordered={true} size={setting.size}
                        pagination={pagenation}
                        dataSource={datas}
                        onChange={handleTableChange} 
                        onRow={rowClick}
                        columns={cols} />
                </div>
            </div>
            {editVisible && <RoleEdit {...editObj} />}
            {grantAuthVisible && <GrantAuth {...grantProp} />}
            {grantMenuVisible && <GrantMenu {...grantProp} />}
        </div>    
    )
}